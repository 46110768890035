import { Routes, Route, useNavigate } from 'react-router-dom';
import EndYourSeason from './EndYourSeason';
import SeasonEnded from './SeasonEnded';

export default function EndSeason() {
  const navigate = useNavigate();

  const completePath = `complete`;

  return (
    <Routes>
      <Route path={completePath} element={<SeasonEnded />} />
      <Route path="/" element={<EndYourSeason onComplete={() => navigate(completePath, { replace: true })} />} />
    </Routes>
  );
}
import { useMemo } from 'react';
import * as Confirmation from '../../../components/Confirmation';

export default function Cancel({ subscription, onSubmit, onClose, loading }) {
  const cancelDescription = useMemo(() => {
    if (subscription.paused) {
      return 'Your subscription is currently paused. If you cancel your subscription, your carryover days will not be redeemable in the future and cannot be reimbursed in any way. We cannot guarantee your current subscription price will be available in the future if you decide to return.';
    } else {
      return 'You will have access to play until the end of your current billing period.';
    }
  }, [subscription.paused]);

  return (
    <Confirmation.Container>
      <Confirmation.Header>Are you sure you want to cancel your subscription?</Confirmation.Header>
      <div>
        {cancelDescription}
      </div>
      {!loading.show && (
        <>
          <button
            className="btn-contained-primary"
            onClick={onClose}
          >
            {subscription.paused ? 'Keep for Next Season' : 'Keep Subscription'}
          </button>
          <button
            className="btn-outlined-primary"
            onClick={onSubmit}
          >
            {`Cancel${subscription.cancels_immediately ? ' Immediately' : ' Subscription'}`}
          </button>
        </>
      )}
      <Confirmation.Loading loading={loading} />
    </Confirmation.Container>
  );
}
import React, { useCallback, useRef, useEffect, useMemo } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useElements, useStripe, CardElement, Elements } from '@stripe/react-stripe-js';

const STRIPE_CARD_OPTIONS = {
  style: {
    base: {
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
    }
  }
};


const WrappedForm = React.forwardRef(function WrappedForm({ label, current, onChange, onReady, disabled, hidePostalCode }, ref) {
  const inputRef = useRef();
  const stripe = useStripe();
  const elements = useElements();

  const getData = useCallback(() => {
    const element = elements.getElement(CardElement);
    const sourceData = { type: 'card' };
    return { stripe, element, sourceData };
  }, [elements, stripe]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.clear();
    }
  }, [current]);

  const cardElementOptions = useMemo(() => {
    return Object.assign({
      disabled,
      hidePostalCode
    }, STRIPE_CARD_OPTIONS);
  }, [disabled, hidePostalCode]);

  return (
    <CardElement
      options={cardElementOptions}
      onReady={el => { inputRef.current = el; onReady && onReady(el); }}
      onChange={c => onChange && onChange({
        ...c,
        data: c.complete ? getData() : undefined,
        getSource: !c.complete ? undefined : async () => {
          return await stripe.createSource(elements.getElement(CardElement), { type: 'card' });
        }
      })}
    />
  );
});

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export default React.forwardRef(function PaymentInput(props, ref) {
  return (
    <Elements stripe={stripePromise}>
      <WrappedForm {...props} ref={ref} />
    </Elements>
  );
});
import React, { useState, useCallback } from 'react';
import { OptionGroupMatrix, OptionGroupItem } from '../../../../components/OptionGroup';
import ActivityTypeOptionItem from '../../ActivityTypeOptionItem';

const InitialOptionsState = {
  participants: null,
  hours: null,
};

export default function Configurator({ state, previewSWR, onSetOptions }) {
  const [options, setOptions] = useState(InitialOptionsState);

  const onSelectOption = useCallback((option) => {
    const nextOptions = { ...options, ...option };
    setOptions(nextOptions);
    if (nextOptions.hours != null && nextOptions.participants != null) {
      onSetOptions(nextOptions);
    }
  }, [options, onSetOptions]);

  return (
    <div className="grid gap-4">
      <div>
        <div className="text-xl font-medium">
          How many people?
        </div>
        <OptionGroupMatrix rows={2} columns={4} className="shadow-md bg-white">
          {[1, 2, 3, 4, 5, 6, 7, 8].map(option => (
            <OptionGroupItem
              key={option}
              selected={options.participants === option}
              onClick={() => onSelectOption({ participants: option, hours: undefined })}
            >
              <ActivityTypeOptionItem
                title={option}
              />
            </OptionGroupItem>
          ))}
        </OptionGroupMatrix>
      </div>
      {options.participants != null && (
        <div>
          <div className="text-xl font-medium">
            How many hours?
          </div>
          <OptionGroupMatrix rows={2} columns={4} className="shadow-md bg-white">
            {[1, 2, 3, 4, 5, 6, 7, 8].map(option => (
              <OptionGroupItem
                key={option}
                selected={options.hours === option}
                onClick={() => onSelectOption({ hours: option })}
                disabled={!options.participants || option > 2 * options.participants}
              >
                <ActivityTypeOptionItem
                  title={option}
                />
              </OptionGroupItem>
            ))}
          </OptionGroupMatrix>
        </div>
      )}
    </div>
  );
}
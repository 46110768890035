import Visa from 'payment-icons/min/flat/visa.svg';
import Mastercard from 'payment-icons/min/flat/mastercard.svg';
import Discover from 'payment-icons/min/flat/discover.svg';
import Amex from 'payment-icons/min/flat/amex.svg';
import clsx from 'clsx';


function padMonth(n) {
  return (n < 10) ? ('0' + n) : n;
}


function getIconSrc(brand) {
  switch ((brand || '').toLowerCase()) {
    case 'visa':
      return Visa;
    case 'mastercard':
      return Mastercard;
    case 'discover':
      return Discover;
    case 'amex':
      return Amex;
    default:
      return null;
  }
}


export default function PaymentMethod({ payment, className, ...props }) {
  if (payment && payment.card) {
    const { brand, last4, exp_month, exp_year } = payment.card;
    if (brand && last4 && exp_month && exp_year) {
      const iconSrc = getIconSrc(brand);
      return (
        <div className={clsx("flex text-sm text-gray-600 select-none", className)} {...props}>
          <div className="text-black mr-1.5">Current:</div>
          {iconSrc && (
            <img className="h-5 mr-2" src={iconSrc} alt="payment_icon" />
          )}
          <div>{`${iconSrc ? '' : ` ${brand}`} •••• ${last4}, exp ${padMonth(exp_month)} / ${exp_year}`}</div>
        </div>
      );
    }
  }
  return null;
}
import FullPageIFrame from '../components/FullPageIFrame';

export default function ManageMyPlan() {
  return (
    <FullPageIFrame
      title="Manage My Plan"
      src="https://www.chippergolf.com/manage-my-plan"
      showGoBackButton={true}
    />
  );
}
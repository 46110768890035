import {
  call,
  fork,
  takeEvery,
  select
} from 'redux-saga/effects';

import * as AccountActions from '../actions/account';
import history from '../history';
import * as Selectors from '../reducers/selectors';
import { APP_URLS } from '../constants';


function* handleOnboarding() {
  // const navigate = yield call(getNavigate);
  const user = yield select(Selectors.getUserInfo);
  switch (user.onboarding_stage) {
    case 'subscribe':
      yield call(history.replace, `${APP_URLS.SUBSCRIBE}${window.location.search}${window.location.hash}`);
      // yield call(navigate, `${APP_URLS.SUBSCRIBE}${window.location.search}${window.location.hash}`, { replace: true });
      break;
    case 'one_last_thing':
      yield call(history.replace, `${APP_URLS.ONE_LAST_THING}${window.location.search}${window.location.hash}`);
      // yield call(navigate, `${APP_URLS.ONE_LAST_THING}${window.location.search}${window.location.hash}`, { replace: true });
      break;
    default:
      break;
  }
}

function* watchUserInfoResult() {
  yield takeEvery(AccountActions.USER_INFO_RESULT, handleOnboarding);
}

const sagas = [
  fork(watchUserInfoResult)
];

export default sagas;
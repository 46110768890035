import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-hot-toast';
import Page from '../../components/Page';
// import ManageSubscription from '../Subscriptions/Manage';
import LogoutButton from '../../components/LogoutButton';
import ProfilePicture from '../ProfilePicture';
import InstallAppButton from '../../components/InstallAppButton';
import useRequests from '../../hooks/useRequests';
import useMySubscriptionsSWR from 'hooks/useMySubscriptionsSWR';
import useMeSWR from 'hooks/useMeSWR';
import * as API from '../../lib/api';
import Modal from '../../components/Modal';
import useConfirmation from '../../hooks/useConfirmation';
import GolfingWithChipper from './GolfingWithChipper';
import CreditBalance from './CreditBalance';
import Subscriptions from './Subscriptions';
import { ConfirmAddonCreate } from '../Confirmations';
import { APP_URLS } from '../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faCircleQuestion } from '@fortawesome/free-solid-svg-icons';


const ContentContainer = styled.div`
  padding: 16px 20px 0px 20px;

  @media (min-width:768px) {
    padding: 40px 100px 0px 100px;
  }
`;


function MyAccount() {
  const meSWR = useMeSWR();
  const subscriptionsSWR = useMySubscriptionsSWR();

  const subscriptions = useMemo(() => {
    return subscriptionsSWR.data || [];
  }, [subscriptionsSWR.data]);

  const userFullName = useMemo(() => {
    return [meSWR.data?.first_name, meSWR.data?.last_name].filter((name) => !!name).join(' ');
  }, [meSWR.data]);

  const showAddUnlimitedCartButton = useMemo(() => {
    return subscriptions.length === 1 && subscriptions[0].available_addons?.length === 1;
  }, [subscriptions]);

  const confirmation = useConfirmation();

  const { useRequest, loading } = useRequests({
    onError: ({ error }) => {
      toast.error(error.message);
    },
    onSuccess: ({ context }) => {
      meSWR.mutate();
      confirmation.hide();
      if (context?.successMessage) {
        toast.success(context.successMessage);
      }
    }
  });

  const onCreateAddonRequest = useRequest({
    loadingTitle: 'Adding to your subscription',
    context: { successMessage: 'Your addon has be created' },
    fn: ({ subscription, addon, fee_total }) => {
      return API.createSubscriptionAddon({
        subscription: subscription.uid,
        addon: addon.uid,
        preview: false,
        fee_total
      });
    }
  });

  const showEndSeason = useMemo(() => {
    for (const subscription of subscriptions) {
      if (subscription.Access.Activity.pause_enabled === true && subscription.active && !subscription.paused) {
        return true;
      }
    }
    return false;
  }, [subscriptions]);

  const showSeasonEnded = useMemo(() => {
    if (subscriptions.length === 0) return false;
    for (const subscription of subscriptions) {
      if (subscription.active && !subscription.paused) {
        return false;
      }
    }
    return true;
  }, [subscriptions]);

  if (!meSWR.data) {
    return null;
  }

  return (
    <Page>
      <ContentContainer>
        <div className="mb-8">
          {/* <div className="grid gap-4 justify-items-center"> */}
            <ProfilePicture />
            <div className="text-center text-2xl font-bold">{userFullName}</div>
          {/* </div> */}
        </div>

        <div className="mb-8">
          <div className="text-xl text-gray-500 font-medium">
            Chipper Golf
          </div>
          <div className="h-0.5 bg-gray-200 my-2"></div>
          <div className="mb-2">
            Welcome to Chipper Golf. We are excited to have you for our 4th season. We hope you enjoy a full season and please let us know if we can do anything to improve your experience.
          </div>
          <div className="flex flex-wrap -m-2">
            <Link
              className="btn btn-contained-primary m-2 inline-flex items-center"
              to={APP_URLS.ACCOUNT_SETTINGS}
            >
              {/* <SettingsIcon className="mr-2" /> */}
              <FontAwesomeIcon icon={faGear} />
              <span className="ml-2">Account Info</span>
            </Link>
            <Link
              className="btn btn-contained-primary m-2 inline-flex items-center"
              to={APP_URLS.SUPPORT}
            >
              <FontAwesomeIcon icon={faCircleQuestion} />
              <span className="ml-2">Support</span>
            </Link>
            
            {showAddUnlimitedCartButton && (
              <Link
                className="btn btn-contained-primary m-2 inline-flex items-center"
                to={APP_URLS.ADD_UNLIMITED_CART}
              >
                Add Unlimited Cart
              </Link>
            )}
            <div className="m-2"><InstallAppButton /></div>
          </div>
        </div>

        <div className="mb-8">
          <GolfingWithChipper />
        </div>

        {showEndSeason && (
          <div className="flex flex-col mb-8">
            <div className="text-xl text-gray-500 font-medium">End Your Season</div>
            <div className="h-0.5 bg-gray-200 my-2"></div>
            <div>
              <div>Pausing for the offseason is a risk free way to lock in your spot and make sure you get the best deal next season.</div>
              <ul className="list-disc list-inside mt-2">
                <li>End your monthly dues</li>
                <li>Carry over remaining days to next season</li>
                <li>Lock in your spot</li>
                <li>Cancel if you change your mind</li>
              </ul>
              <div className="mt-2">
                <Link
                  className="btn-contained-primary inline-block"
                  to={APP_URLS.END_SEASON}
                >
                  End My Season
                </Link>
              </div>
            </div>
          </div>
        )}

        {showSeasonEnded && (
          <div className="flex flex-col mb-8">
            <div className="text-xl text-gray-500 font-medium">Membership: Paused</div>
            <div className="h-0.5 bg-gray-200 my-2"></div>
            <div>
              <div>Your membership is paused for the offseason. You will not be charged your monthly dues again until you are reactivated next season. If you golf again this season you’ll be charged a single round fee.</div>
              <div className="flex mt-2">
                <Link
                  className="btn-contained-primary inline-block"
                  to={APP_URLS.PAUSE_SUBSCRIPTION_LEARNING}
                >
                  More Details
                </Link>
              </div>
            </div>
          </div>
        )}

        {meSWR.data.balance < 0 && (
          <div className="mb-8">
            <CreditBalance amount={meSWR.data.balance} />
          </div>
        )}
        <div className="mb-8">
          <Subscriptions
            user={meSWR.data}
            subscriptions={subscriptionsSWR.data}
          />
        </div>
        <div className="mb-8">
          <div>
            <LogoutButton />
          </div>
        </div>
        <Modal
          isOpen={confirmation.active}
          onRequestClose={confirmation.hide}
        >
          <>
            {confirmation.type === 'create_addon' && (
              <ConfirmAddonCreate
                loading={loading}
                subscription={confirmation.data.subscription}
                addon={confirmation.data.addon}
                onSubmit={() => onCreateAddonRequest(confirmation.data)}
              />
            )}
          </>
        </Modal>
      </ContentContainer>
    </Page>
  );
}


export default function Account() {
  return (
    <MyAccount />
  );
  // return (
  //   <Routes>
  //     {/* <Route path={`/subscriptions/:uid`} element={<ManageSubscription />} /> */}
  //     <Route path="/" element={<MyAccount />} />
  //   </Routes>
  // );
}
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { APP_URLS } from '../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

export default function InstallAppButton() {
  const isInStandaloneMode = useMemo(() => {
    return (
      (window.matchMedia('(display-mode: standalone)').matches)
      || (window.navigator.standalone)
      || document.referrer.includes('android-app://')
    );
  }, []);

  if (isInStandaloneMode) {
    return null;
  }

  return (
    <Link
      className="btn btn-outlined-primary inline-flex items-center"
      to={APP_URLS.INSTALL_APP}
    >
      <FontAwesomeIcon icon={faDownload} />
      <span className="ml-2">Install App</span>
    </Link>
  );
}
import MainLayout from '../components/MainLayout';
import Branding from '../components/Branding';
import LogoutButton from '../components/LogoutButton';


export default function ErrorPage({ error }) {
  console.log(error.code);
  return (
    <MainLayout
      Header={{ className: "justify-between" }}
      card
      header={(
        <>
          <Branding logo={true} />
          <LogoutButton />
        </>
      )}
      title={(
        <>
          <div className="text-2xl font-extrabold">Error</div>
          <div className="text-lg">{error && error.message ? error.message : error || 'Something went wrong'}</div>
        </>
      )}
      content={(
        <>
          <div className="flex justify-center items-center">
            <button
              className="btn-contained-primary"
              onClick={() => window.location.reload()}
            >
              Refresh
            </button>
          </div>
        </>
      )}
    />
  );
}
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export default function useQueryParams() {
  const { search } = useLocation();

  const params = useMemo(() => {
    const params = new URLSearchParams(search);
    return Object.fromEntries(params);
  }, [search]);

  return params;
}
import React, { useMemo, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';
import { useSelector } from 'react-redux';
import Branding from '../components/Branding';
import HeaderButton, { BackHeaderButton } from '../components/HeaderButton';
import { MOBILE_BREAKPOINT } from '../constants';
import { getHeader } from '../reducers/selectors';


function ActionButton({ action }) {
  const actionButton = useMemo(() => {
    if (!action) return null;

    if (action.isBack) {
      return (
        <BackHeaderButton
          url={action.url}
          onClick={action.fn}
          title={action.title}
        />
      );
    } else {
      return (
        <HeaderButton
          onClick={action.fn}
        >
          {action.title}
        </HeaderButton>
      );
    }
  }, [action]);

  return actionButton;
}


export default function Header({ tabs, routes }) {
  const isMobile = useMedia(MOBILE_BREAKPOINT);
  const headerState = useSelector(getHeader);
  const location = useLocation();

  const activeRoute = useMemo(() => {
    const href = location.pathname;
    return routes.find(t => t.href === href);
  }, [routes, location]);

  const title = headerState?.title || (activeRoute ? isMobile && activeRoute.mobileTitle ? activeRoute.mobileTitle : activeRoute.title : '');

  const hasLogoOnClickRef = useRef(false);

  hasLogoOnClickRef.current = !!(activeRoute && activeRoute.logoHref);

  const headerAction = headerState?.action;

  return (
    <nav className="z-50 flex flex-col flex-shrink-0 m-0 p-0 relative">
      <div className={"flex flex-none " + (headerAction ? "h-24" : "h-14")}>
        <header className="flex flex-col flex-wrap font-semibold fixed left-0 right-0 top-0 shadow bg-white">
          <div className={"flex items-center h-14 " + (isMobile ? "justify-center" : "justify-between px-8")}>
            {!isMobile && (
              <>
                <Branding logo={true} name={true} />
                <div className="overflow-hidden whitespace-nowrap">
                  <div className="flex items-center mx-3">
                    {tabs.map((tab) => (
                      <NavLink
                        key={tab.href}
                        to={tab.href}
                        className={({ isActive }) => {
                          const classes = "flex-0 text-lg font-semibold whitespace-nowrap mx-3";
                          return classes + (isActive ? " text-green-500" : "");
                        }}
                      >
                        {tab.menuTitle || tab.title}
                      </NavLink>
                    ))}
                  </div>
                </div>
              </>
            )}
            {isMobile && (
              <div className="text-xl font-semibold select-none">{title}</div>
            )}
          </div>
          <ActionButton action={headerAction} />
        </header>
      </div>
    </nav>
  );
}
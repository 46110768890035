import { useCallback, useMemo } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Checkbox from '../../components/Checkbox';
import useHeader from '../../hooks/useHeader';
import Page from '../../components/Page';
import { APP_URLS } from '../../constants';
import clsx from 'clsx';
import * as API from '../../lib/api';
import useTransaction from '../../hooks/useTransaction';
import useMySubscriptionsSWR from 'hooks/useMySubscriptionsSWR';
import useMeSWR from 'hooks/useMeSWR';
import { mixpanelTrack } from '../../integrations/mixpanel';

function SubmitButton({ control, loading }) {
  const confirm = useWatch({ name: 'confirm', control });

  const disabled = !confirm || loading;

  return (
    <button
      disabled={disabled}
      className={clsx('btn', disabled ? 'btn-disabled' : 'btn-contained-primary')}
      type='submit'
    >
      {loading ? 'Ending Season' : 'End My Season Now' }
    </button>
  );
}

export default function EndYourSeason({ onComplete }) {
  const meSWR = useMeSWR();
  const subscriptionsSWR = useMySubscriptionsSWR();

  const subscriptions = useMemo(() => {
    return subscriptionsSWR.data || [];
  }, [subscriptionsSWR.data]);

  const trx = useTransaction();

  const onSubmit = useCallback(async () => {
    trx.begin();

    for (const subscription of subscriptions) {
      if (!subscription.paused) {
        if (subscription.Access.Activity.pause_enabled) {
          try {
            const pausedSubscription = await API.pauseSubscription(subscription.uid);
            mixpanelTrack('subscription.pause', {
              subscription: pausedSubscription,
            });
          } catch (err) {
            trx.end(err);
            return;
          }
        }
      }
    }

    try {
      await meSWR.mutate();
    } catch (err) {
      console.log(err);
    }
    onComplete();
  }, [trx, onComplete, subscriptions, meSWR]);

  useHeader(APP_URLS.END_SEASON + '/confirm', {
    title: 'End Your Season',
    action: {
      isBack: true,
      url: APP_URLS.ACCOUNT
    }
  });

  const { formState, control, setValue, getValues, handleSubmit } = useForm({
    defaultValues: {
      confirm: false
    }
  });

  return (
    <Page>
      <div className="px-4 flex justify-center">
        <div className="max-w-md">
          <div className="grid gap-4">
            <img src="https://ik.imagekit.io/chippergolf/core/PauseHeader2021.png?updatedAt=1629903151451" alt="pause header" />

            <div className="font-medium text-2xl">Ending Your Season</div>

            <div>
              <div className="font-bold">Paused Immediately</div>
              <div>Your monthly dues and access to golf are paused immediately. Only pause when you’re done golfing for the season.</div>
            </div>

            <div>
              <div className="font-bold">Carry Over Days - FREE</div>
              <div>The remaining days in your final billing period are applied to your 1st month next season.</div>

            </div>

            <div>
              <div className="font-bold">Lock In Your Spot</div>
              <div>Your spot is locked in for next season - otherwise you may be put on a waitlist if we’re at capacity.</div>
            </div>

            <div>
              <div className="font-bold">$1 Month - Applied To Next Season</div>
              <div>You’ll be charged $1/mo starting Jan 1st to hold your spot. Every dollar will be applied to your first month back next season. Non-refundable if membership canceled.</div>
            </div>

            <div>
              <div className="font-bold">2022 Reactivation</div>
              <div>Your membership will be reactivated on May 1st unless you’ve reactivated it sooner. You’ll be notified of any plan or pricing changes before reactivation and can cancel if you change your mind.</div>
            </div>

            <form className="grid gap-4" onSubmit={handleSubmit(onSubmit)}>
              <div
                className="mt-4 bg-white rounded border-2 p-2"
                onClick={() => setValue("confirm", !getValues("confirm"))}
              >
                <div className="flex justify-between">
                  <div className="font-bold text-lg">Pause for Offseason</div>
                  <Controller
                    name="confirm"
                    defaultValue={false}
                    control={control}
                    render={({ field }) => (
                      <label onClick={ev => ev.stopPropagation()}>
                        <Checkbox
                          disabled={formState.isSubmitting}
                          checked={field.value}
                          {...field}
                        />
                      </label>
                    )}
                  />
                </div>
                <div>
                  I’ve read and agree to the terms above and would like to pause my membership for the offseason.
                </div>
              </div>

              <SubmitButton control={control} loading={trx.loading} />
              {/* <button
                className='btn btn-contained-primary'
                type='submit'
              >
                End My Season Now
              </button> */}
            </form>
            {trx.error && (
              <div className="text-red-500 text-sm font-bold">{trx.error.message}</div>
            )}
          </div>

          <div className="">
            <div className="bg-gray-200 h-0.5 mt-10 mb-4"></div>

            <div>
              <div className="font-bold">End Your Membership Instead</div>
              <div>You’re free to cancel your membership and rejoin at anytime. However, we cannot guarantee access in the future if our plans have reached capacity. New plan pricing and policies may apply.</div>

              {subscriptions.map((subscription) => (
                <div
                  key={subscription.uid}
                  className="mt-2"
                >
                  <Link
                    className="btn inline-flex items-center underline whitespace-nowrap font-semibold select-none rounded-md text-green-500 outline-none hover:text-green-700 focus:outline-none"
                    // to={`${APP_URLS.ACCOUNT}/subscriptions/${subscription.uid}`}
                    to={APP_URLS.MANAGE_SUBSCRIPTION(subscription.uid)}
                  >
                    {`End ${subscriptions.length > 1 ? subscription.network.name : ' '}Membership`}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
        
      </div>
    </Page>
  );
}
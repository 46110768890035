import {
  put,
  call,
  fork,
  takeLatest,
  take,
  // actionChannel
} from 'redux-saga/effects';

// import { buffers } from 'redux-saga';

import * as AppActions from '../actions/app';
import * as AccountActions from '../actions/account';
import * as SubscribeActions from '../actions/subscribe';
import * as Auth from '../lib/auth';
import { parseQueryString } from '../lib/qs';
import { APP_URLS } from '../constants';


function* handleInitialize() {
  const authenticated = yield call(Auth.isAuthenticated);
  if (authenticated) {
    const userInfoAction = yield take([AccountActions.USER_INFO_RESULT]);
    if (userInfoAction.type === AccountActions.USER_INFO_RESULT) {
      const { pathname, search } = window.location;
      switch (pathname) {
        case APP_URLS.SUBSCRIBE:
          const params = yield call(parseQueryString, search);
          yield put(SubscribeActions.preload(params));
          break;
        default:
          break;
      }
    }
  }
}


function* watchInitialize() {
  yield takeLatest(AppActions.INITIALIZE, handleInitialize);
}


// function* handleGeolocationUpdate(action) {
//   // const { latitude, longitude } = action.payload;
//   // const request = yield call(API.listSites, { limit: 1, location: `${latitude},${longitude}` });
//   // console.log(request);
// }


// function* watchGeolocationUpdate() {
//   const throttleChannel = yield actionChannel(AppActions.GEOLOCATION_UPDATE, buffers.sliding(1));

//   while (true) {
//     const action = yield take(throttleChannel);
//     const { loading, latitude, longitude } = action.payload;

//     if (!loading && latitude != null && longitude != null) {
//       yield fork(handleGeolocationUpdate, action);
//       yield delay(20000);
//     }
//   }
// }


const sagas = [
  fork(watchInitialize),
];

export default sagas;
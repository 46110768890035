import { useGlobalState, logout, login } from '../lib/auth';

export default function useAuth0() {
  const [state] = useGlobalState('auth0');

  return {
    ...state,
    logout,
    login,
  };
}
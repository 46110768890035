import React, { useCallback, useState, useMemo } from 'react';
import * as API from '../lib/api';
import Dropzone from '../components/Dropzone';
import UserImagePreview from '../components/UserImagePreview';
import UserImage from '../components/UserImage';
import useTransaction from '../hooks/useTransaction';
import useMeSWR from 'hooks/useMeSWR';


export default function ProfilePicture({ openRef, onUpdated }) {
  const [file, setFile] = useState();

  const meSWR = useMeSWR();

  const onCancelPreview = useCallback(() => {
    setFile(null);
  }, []);

  const trx = useTransaction();

  const onSubmit = useCallback(async () => {
    trx.begin();
    try {
      const updatedUser = await API.uploadUserProfilePicture(file);
      meSWR.mutate(updatedUser);
      setFile(null);
      if (onUpdated) {
        onUpdated();
      }
      trx.end();
    } catch (err) {
      trx.end(err);
    }
  }, [file, meSWR, onUpdated, trx]);

  const onFilesAdded = useCallback(files => {
    if (Array.isArray(files) && files.length > 0) {
      setFile(files[0]);
    }
  }, []);

  const title = useMemo(() => {
    return `${meSWR.data?.profile_image ? 'Update' : 'Add'} Membership Picture`;
  }, [meSWR.data?.profile_image]);

  return (
    <div>
      <UserImagePreview
        file={file}
        open={!!file}
        title={title}
        onCancel={onCancelPreview}
        onSubmit={onSubmit}
        loading={trx.loading}
        error={trx.error}
      />
      <div className="flex justify-center">
        <Dropzone
          title={title}
          accept={{ 'image/*': [] }}
          disabled={!!file}
          onFilesAdded={onFilesAdded}
          imageURL={meSWR.data?.profile_image}
          openRef={openRef}
        >
          <UserImage src={meSWR.data?.profile_image} size={148} />
        </Dropzone>
      </div>
      
    </div>
  );
}
import useHeader from '../hooks/useHeader';


export default function FullPageIFrame({ title, src, showGoBackButton, iframeProps, ...props }) {
  useHeader(showGoBackButton && src, {
    title,
    action: {
      isBack: true
    }
  });

  return (
    <div className="flex flex-col flex-1 overflow-auto" {...props}>
      <iframe
        title={title}
        src={src}
        width="100%"
        height="100%"
        frameBorder={0}
        allowFullScreen={true}
        className="h-full flex-1"
        {...iframeProps}
      >
        {title}
      </iframe>
    </div>
  );
}
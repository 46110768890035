import FullPageIFrame from '../components/FullPageIFrame';

export default function Installation() {
  return (
    <FullPageIFrame
      title="Install Web App"
      src="https://www.chippergolf.com/install-chipper-golf-on-your-phone"
      showGoBackButton={true}
    />
  );
}
import React, { useCallback, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Page from '../components/Page';
import ProfilePicture from './ProfilePicture';
import useHeader from '../hooks/useHeader';
import { APP_URLS } from '../constants';
import useMeSWR from 'hooks/useMeSWR';


export default function MembershipPictureRequirement({ onDismiss, forceAllowSkip }) {
  useHeader('MembershipPictureRequirement', {
    title: 'Membership Picture'
  });

  const openRef = useRef();
  const openFilePicker = useCallback(() => {
    if (openRef.current) {
      openRef.current();
    }
  }, []);

  const navigate = useNavigate();
  const onDismissFn = useCallback(() => {
    if (onDismiss) {
      onDismiss();
    } else {
      navigate(APP_URLS.ACCOUNT, { replace: true });
    }
  }, [navigate, onDismiss]);

  const meSWR = useMeSWR();

  const skipAllowed = useMemo(() => {
    return forceAllowSkip || meSWR.data?.membership_picture_deadline == null || Date.now() < new Date(meSWR.data?.membership_picture_deadline).getTime();
  }, [forceAllowSkip, meSWR.data?.membership_picture_deadline]);

  if (!meSWR.data) {
    return null;
  }

  return (
    <Page>
      <div className="p-4">
        <div className="text-center mb-8 grid gap-2 justify-items-center">
          <ProfilePicture
            openRef={openRef}
            onUpdated={onDismissFn}
          />
          <div className="max-w-md">
            {meSWR.data.membership_picture_approved === -1 && (
              <>
                <div className="font-bold">Change Membership Picture</div>
                <div>Membership picture didn’t meet requirements. Please add a new picture within 7 days.</div>
              </>
            )}
            {meSWR.data.membership_picture_approved !== -1 && (
              <>
                <div className="font-bold">Add Membership Picture</div>
                <div>Required 7 days after joining</div>
              </>
            )}
          </div>
          
        </div>
        <div className="flex justify-center">
          <div className="flex-1 max-w-md">
            <div className="font-bold">Membership Picture</div>
            <ul className="list-none">
              <li>Must be a picture of you</li>
              <li>Can't be a group picture</li>
              <li>Your face can't be covered (e.g. sunglasses)</li>
              <li>Picture can only be changed once a month</li>
            </ul>
            <div className="my-8 grid gap-4">
              <button
                className="btn btn-contained-primary"
                onClick={openFilePicker}
              >
                Add Picture Now
              </button>
              {skipAllowed && (
                <button
                  className="btn btn-outlined-primary"
                  onClick={onDismissFn}
                >
                  Skip for Now
                </button>
              )}
            </div>
            <div className="text-center">
              All golfers are required to have a membership picture to verify their identity at the courses. You won't be able to check in and golf if you haven't added a membership picture within 7 days of joining.
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}
import {
  action
} from './common';

const BASE = 'SUBSCRIBE/';

export const PRELOAD = `${BASE}PRELOAD`;
export const preload = (value) => action(PRELOAD, value);

export const PRELOAD_FINISHED = `${BASE}PRELOAD_FINISHED`;
export const preloadFinished = () => action(PRELOAD_FINISHED);

export const RESET = `${BASE}RESET`;
export const reset = () => action(RESET);

export const SET_NETWORK = `${BASE}SET_NETWORK`;
export const setNetwork = (value) => action(SET_NETWORK, value);

export const SET_ACTIVITY = `${BASE}SET_ACTIVITY`;
export const setActivity = (value) => action(SET_ACTIVITY, value);

export const SET_ACCESS = `${BASE}SET_ACCESS`;
export const setAccess = (value) => action(SET_ACCESS, value);

export const SET_PROMOTION_CODE = `${BASE}SET_PROMOTION_CODE`;
export const setPromotionCode = (value) => action(SET_PROMOTION_CODE, value);

export const SET_ADDONS = `${BASE}SET_ADDONS`;
export const setAddons = (addons) => action(SET_ADDONS, addons);

export const SET_CONFIRMED = `${BASE}SET_CONFIRMED`;
export const setConfirmed = (value) => action(SET_CONFIRMED, value);

export const SET_SUBSCRIBED = `${BASE}SET_SUBSCRIBED`;
export const setSubscribed = (value) => action(SET_SUBSCRIBED, value);
import * as AccountActions from '../actions/account';

const initialState = {
  data: {},
  showCancelationSurvey: false
};

export default function account(state = initialState, action) {
  switch (action.type) {
    case AccountActions.SHOW_CANCELATION_SURVEY:
      return {
        ...state,
        showCancelationSurvey: action.payload
      };
    case AccountActions.USER_INFO_RESULT:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
}

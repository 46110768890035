import { Link } from 'react-router-dom';
import { APP_URLS } from '../../constants';

export default function UpdatePaymentMethod() {
  return (
    <div className="flex justify-center mt-4">
      <div className="shadow rounded p-3">
        <Link to={APP_URLS.ACCOUNT_SETTINGS}>
          Please update payment method
        </Link>
      </div>
    </div>
  );
}
import styled from 'styled-components';
import LoadingBar from './LoadingBar';

export const Container = styled.div`
  display: grid;
  grid-gap: 24px;
`;

export const Header = styled.div`
  font-size: 24px;
  font-weight: 700;
  text-align: center;
`;

export function Loading({ loading }) {
  if (!loading.show) return null;

  return (
    <div className="flex flex-col items-center">
      {loading.title && (
        <div className="text-sm font-bold mb-2">{loading.title}</div>
      )}
      <LoadingBar />
    </div>
  )
}
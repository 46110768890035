import React, { useMemo } from 'react';
// import AspectRatio from './AspectRatio';
import * as AspectRatio from '@radix-ui/react-aspect-ratio';
import { ShortMonthNames } from '../lib/helpers';
import clsx from 'clsx';


export default function CheckInCard({ checkIn }) {
  const primaryInfo = useMemo(() => {
    if (!checkIn) return '';

    const date = new Date(checkIn.date);

    return [
      `${date.getDate()} ${ShortMonthNames[date.getMonth()]} ${date.getFullYear()}`,
      `${checkIn?.options?.holes} holes`,
      checkIn?.options?.golf_cart ? 'Golf Cart' : 'Walking'
    ].join(' • ');
  }, [checkIn]);


  return (
    <div className="shadow rounded overflow-hidden">
      <AspectRatio.Root ratio={16 / 7} className={clsx(!checkIn && 'animate-pulse bg-gray-200')}>
        {checkIn && (
          <img
            className={clsx('object-cover w-full h-full', !checkIn.Service.SiteActivity?.profile_image && 'object-bottom')}
            src={checkIn.Service.SiteActivity?.profile_image || 'https://ik.imagekit.io/chippergolf/core/chippergolf2018.jpg'}
            alt={checkIn.Service.SiteActivity?.name}
          />
        )}
      </AspectRatio.Root>
      {/* <AspectRatio
        className={"bg-cover bg-no-repeat bg-center" + (checkIn ? "" : " animate-pulse bg-gray-200") }
        ratio={42}
        style={{
          backgroundImage: checkIn
            ? `url(${checkIn.Service.SiteActivity?.profile_image || 'https://ik.imagekit.io/chippergolf/core/chippergolf2018.jpg'})`
            : undefined,
          backgroundPosition: !checkIn?.Service.SiteActivity?.profile_image
            ? 'center bottom'
            : null
        }}
      >
      </AspectRatio> */}
      <div className="py-2 px-4 bg-white">
        {checkIn && (
          <>
            <div className="font-bold text-xl">{checkIn?.Site?.name}</div>
            <div className="text-gray-700 text-sm">{primaryInfo}</div>
          </>
        )}
        {!checkIn && (
          <>
            <div className='animate-pulse whitespace-pre'>
              <div className='font-bold text-xl'>
                <span className='text-sm align-middle w-1/4 inline-block bg-gray-200 rounded-full'>{'\u0020'}</span>
                <span className='text-sm inline-block'>{'\u0020'}</span>
                <span className='text-sm w-1/3 inline-block bg-gray-200 rounded-full'>{'\u0020'}</span>
              </div>
              <div className='text-sm'>
                <span className='text-xs whitespace-pre bg-gray-200 rounded-full w-1/2 inline-block'>{'\u0020'}</span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
import { createGlobalState } from 'react-hooks-global-state';


const initialState = { auth0: { isAuthenticated: false, isLoading: true } };
const { useGlobalState, setGlobalState, getGlobalState } = createGlobalState(initialState);

export { useGlobalState };

const REALM = 'Username-Password-Authentication';


export async function resetPassword({ email }) {
  const req = await fetch(`https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      connection: REALM,
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
    }),
  });
  return await req.text();
}


export async function login({ username, email, password }) {
  if ((!username && !email) || !password) {
    throw new Error('Email and password are required to login');
  }
  const req = await fetch(`https://${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username: username || email,
      password,
      realm: REALM,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: 'openid offline_access',
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      grant_type: 'http://auth0.com/oauth/grant-type/password-realm',
    }),
  });

  const result = await req.json();

  handleOauthTokenResponse(result);

  // if (history.location.search) {
  //   const search = parseQueryString(history.location.search);
  //   if (search.return_to) {
  //     history.replace(search.return_to);
  //   }
  // }
}


function handleOauthTokenResponse(result) {
  if (result.error_description || result.error) {
    throw new Error(result.error_description || result.error);
  } else {
    setSession(result);
  }
}


function setSession({ access_token, refresh_token, expires_in }) {
  localStorage.setItem('access_token', access_token);
  localStorage.setItem('refresh_token', refresh_token);
  localStorage.setItem('expires_at', (new Date(Date.now() + (expires_in * 1000))).toISOString());

  const { isAuthenticated } = getGlobalState('auth0');
  if (!isAuthenticated) {
    setGlobalState('auth0', (previousState) => ({
      ...previousState,
      isLoading: false,
      isAuthenticated: true
    }));
  }
}


export function logout() {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('expires_at');

  const { isAuthenticated } = getGlobalState('auth0');
  if (isAuthenticated) {
    setGlobalState('auth0', (previousState) => ({
      ...previousState,
      isLoading: false,
      isAuthenticated: false,
    }));
  }
}


export async function isAuthenticated() {
  if (internalIsAuthenticated() || await attemptRefresh()) {
    // setGlobalState('auth0', { isAuthenticated: true, isLoading: false });
    return true;
  } else {
    logout();
    // setGlobalState('auth0', { isAuthenticated: false, isLoading: false });
    return false;
  }
}

function internalIsAuthenticated() {
  const expiresAt = localStorage.getItem('expires_at');
  const isAuthenticated = expiresAt != null && new Date(expiresAt) > new Date();

  const auth0State = getGlobalState('auth0');
  if (auth0State.isAuthenticated !== isAuthenticated) {
    setGlobalState('auth0', (previousState) => ({
      ...previousState,
      isLoading: false,
      isAuthenticated,
    }));
  }

  return isAuthenticated;
}

async function attemptRefresh() {
  const refreshToken = localStorage.getItem('refresh_token');
  if (!refreshToken) {
    return false;
  }

  const req = await fetch(`https://${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    // credentials: 'omit',
    // mode: 'cors',
    // referrerPolicy: 'strict-origin-when-cross-origin',
    body: JSON.stringify({
      refresh_token: refreshToken,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: 'openid offline_access',
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      grant_type: 'refresh_token',
    }),
  });

  const result = await req.json();

  handleOauthTokenResponse(result);

  return internalIsAuthenticated();
}

export function accessToken() {
  return localStorage.getItem('access_token');
}

isAuthenticated();

import 'core-js/features/object/assign';
import 'core-js/features/object/entries';
import 'core-js/features/object/keys';
import 'core-js/features/object/values';
import 'core-js/features/object/from-entries';
import 'core-js/features/promise';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.css';
import configureStore from './store/configureStore';
import rootSaga from './sagas';
import Routes from './routes';
import ErrorPage from './pages/ErrorPage';
import { Toaster } from 'react-hot-toast';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import ReactModal from 'react-modal';
import { Sentry } from './integrations/sentry';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

const store = configureStore();
store.runSaga(rootSaga);

const container = document.getElementById('root');
ReactModal.setAppElement(container);
const root = createRoot(container);
root.render((
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Toaster
        toastOptions={{
          // duration: 6000,
          success: {
            iconTheme: {
              primary: '#10B981'
            }
          }
        }}
      />
      <Sentry.ErrorBoundary fallback={ErrorPage}>
        <Routes />
      </Sentry.ErrorBoundary>
    </ThemeProvider>
  </Provider>
));

function handleRegistration(registration) {
  try {
    if (registration) {
      const waitingServiceWorker = registration.waiting;
      if (waitingServiceWorker) {
        waitingServiceWorker.addEventListener("statechange", event => {
          if (event.target.state === "activated") {
            window.location.reload();
          }
        });
        waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
      }
    }
  } catch (err) {
    Sentry.captureException(err);
    console.log(err);
  }
}

serviceWorkerRegistration.register({
  onSuccess: registration => {
    function checkForUpdate() {
      registration.update();
    }

    setInterval(checkForUpdate, 20 * 60 * 1000);
    checkForUpdate();

    handleRegistration(registration);
  },
  onUpdate: registration => {
    handleRegistration(registration);
  }
});

reportWebVitals();

if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
  window.addEventListener('load', async () => {
    try {
      const registration = await navigator.serviceWorker.ready;
      handleRegistration(registration);
    } catch (err) {
      Sentry.captureException(err);
      console.log(err);
    }
  });
}
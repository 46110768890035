import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useEffectOnce } from 'react-use';
import { useForm } from 'react-hook-form';
import useSWR from 'swr';
import ItemList from '../../components/ItemList';
import InfoListItem from '../../components/InfoListItem';
import * as SubscribeActions from '../../actions/subscribe';
import BaseContainer from './BaseContainer';
import TextInput from '../../components/Inputs/Text';
import { validatePromoCode } from '../../lib/validation';
import * as API from '../../lib/api';
import { STORAGE_KEY_SHARE_PROMOTION } from '../../constants';

import {
  formatCurrency,
  formatCoupon,
  formatPriceDescription,
} from '../../lib/formatting';

import {
  getPriceAmount,
} from '../../lib/helpers';


function PromotionLookupInput() {
  const [{ loading, error }, setState] = useState({
    loading: false,
    error: null
  });

  const savedCode = useMemo(() => {
    return localStorage.getItem(STORAGE_KEY_SHARE_PROMOTION) || '';
  }, []);

  const dispatch = useDispatch();

  const { handleSubmit, reset, watch, register } = useForm({
    defaultValues: {
      code: savedCode
    }
  });

  const onSubmit = useCallback(async (values) => {
    setState({ loading: true, error: null });

    const nextState = { loading: false };
  
    try {
      const promotionCode = await API.getPromotionCode(values.code.toUpperCase());
      dispatch(SubscribeActions.setPromotionCode(promotionCode));
    } catch (error) {
      if (error.code === 404) {
        nextState.error = new Error('Invalid code');
      } else {
        nextState.error = error;
      }
    }

    reset();
    setState(nextState);
  }, [dispatch, reset]);

  const code = watch('code');

  useEffect(() => {
    if (error && code && code.length > 0) {
      setState(state => ({ ...state, error: null }));
    }
  }, [error, code]);

  const promoLookupDisabled = loading || !validatePromoCode(code) // || !!error;

  useEffectOnce(() => {
    if (!promoLookupDisabled) {
      onSubmit({ code });
    }
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <label>Promo or Referral Code</label>
        <div className="flex">
          <TextInput
            {...register("code")}
            placeholder="Not Required"
            disabled={loading}
            className="uppercase placeholder-text-transform-none"
          />
          <div className="flex justify-center items-center ml-2">
            <button
              className={promoLookupDisabled ? "btn btn-link-disabled" : "btn btn-link-primary"}
              disabled={promoLookupDisabled}
              type="submit"
            >
              Apply
            </button>
          </div>
        </div>
      </div>

      {error && (
        <div className="text-red-500 text-sm font-bold mt-1">{error.message || error}</div>
      )}
    </form>
  );
}


function PromotionOptionItem({ promotion, onRemove }) {
  const {
    title,
    description
  } = useMemo(() => {
    const promo = promotion.promotion;

    switch (promotion.type) {
      case 'discount':
        return {
          title: promo.title,
          description: promo.description
        };
      case 'user_referral':
      case 'business_referral':
        return {
          title: 'Referral Discount',
          description: formatCoupon(promo.Coupon)
        };
      default:
        return {
          title: 'Promotion',
          description: ''
        };
    }
  }, [promotion]);

  return (
    <InfoListItem
      title={title}
      option={{
        type: 'button',
        value: 'Remove',
        onClick: onRemove
      }}
    >
      <div>
        {description}
      </div>
    </InfoListItem>
  );
}

export default function PlanConfigurator({ state, onReset }) {
  const {
    access,
    addons,
    // activity,
    // network,
    promotion
  } = state.configuration;

  const addonsSWR = useSWR(`/addons?access=${access.uid}`, async () => {
    return await API.listAddons({ access: access.uid, active: true });
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (addonsSWR.data && !addons) {
      dispatch(SubscribeActions.setAddons((addonsSWR.data || []).reduce((accum, addon) => {
        accum[addon.uid] = true;
        return accum;
      }, {})));
    }
  }, [addonsSWR.data, addons, dispatch]);

  const invoice = useMemo(() => {
    const accessPriceAmount = getPriceAmount(access);
    let subtotal = accessPriceAmount;

    const invoiceAddons = [];
    if (addonsSWR.data) {
      for (const addon of addonsSWR.data) {
        const priceAmount = getPriceAmount(addon);
        if (addons && addons[addon.uid]) {
          subtotal += priceAmount;
        }
        invoiceAddons.push({
          uid: addon.uid,
          title: addon.title,
          priceDescription: formatPriceDescription(addon),
        });
      }
    }

    return {
      subtotal: formatCurrency(subtotal / 100),
      access: {
        priceDescription: formatPriceDescription(access),
      },
      addons: invoiceAddons
    };
  }, [access, addons, addonsSWR.data]);

  const onRemovePromoCode = useCallback(() => {
    if (localStorage) {
      localStorage.removeItem(STORAGE_KEY_SHARE_PROMOTION);
    }
    dispatch(SubscribeActions.setPromotionCode(null));
  }, [dispatch]);

  return (
    <BaseContainer title="My Plan">
      <ItemList>
        <InfoListItem
          title={access.name}
          option={{
            type: 'button',
            value: 'Change Plan',
            onClick: onReset
          }}
        >
          {invoice.access.priceDescription}
        </InfoListItem>
        {invoice.addons.map((addon) => (
          <InfoListItem
            key={addon.uid}
            title={addon.title}
            option={{
              type: 'checkbox',
              onChange: (ev) => dispatch(SubscribeActions.setAddons(({ ...addons, [addon.uid]: ev.target.checked }))),
              value: !!(addons || {})[addon.uid]
            }}
          >
            {addon.priceDescription}
          </InfoListItem>
        ))}
        <InfoListItem
          title="Total"
          option={{
            type: 'text',
            value: 'Plus Taxes'
          }}
        >
          {invoice.subtotal}
        </InfoListItem>
        {promotion && (
          <PromotionOptionItem
            promotion={promotion}
            onRemove={onRemovePromoCode}
          />
        )}
      </ItemList>

      {!promotion && (
        <PromotionLookupInput />
      )}
      
      <button
        className="btn btn-contained-primary"
        onClick={() => dispatch(SubscribeActions.setConfirmed(true))}
      >
        Continue
      </button>
    </BaseContainer>
  );
}
import { useCallback } from 'react';
import clsx from 'clsx';
import useTransaction from '../../hooks/useTransaction';
import * as API from '../../lib/api';
import { delay } from '../../lib/helpers';
import * as Confirmation from '../../components/Confirmation';
import useMySubscriptionsSWR from 'hooks/useMySubscriptionsSWR';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { mixpanelTrack } from '../../integrations/mixpanel';

export default function ConfirmAddonCancel({ subscription, subscriptionAddon, onDismiss }) {
  const trx = useTransaction();
  const mySubscriptionsSWR = useMySubscriptionsSWR();

  const onSubmit = useCallback(async () => {
    try {
      trx.begin();
      const [canceledSubscriptionAddon] = await Promise.all([
        API.cancelSubscriptionAddon(subscriptionAddon.uid),
        delay(400),
      ]);

      mixpanelTrack('subscription_addon_cancel', {
        subscription,
        subscription_addon: canceledSubscriptionAddon,
      });

      trx.end();
    } catch (err) {
      console.log(err);
      trx.end(err);
      return;
    }

    try {
      await Promise.all([
        mySubscriptionsSWR.mutate(),
        delay(1000),
      ]);
    } catch (err) {
      //
    }
    onDismiss();
  }, [onDismiss, trx, subscriptionAddon.uid, subscription, mySubscriptionsSWR]);
  
  const addonTitle = subscriptionAddon.Addon.title;
  

  return (
    <Confirmation.Container>
      <Confirmation.Header>{`Remove ${addonTitle}`}</Confirmation.Header>
      {/* Unlimited cart access is no longer available with some of our plans. If removed it may not be available to add in the future. */}
      {subscriptionAddon.Addon.active === false && (
        <div>{`${addonTitle} access is no longer available with some of our plans. If removed it may not be available to add in the future.`}</div>
      )}
      {!trx.complete && (
        <>
          {!subscription.paused && (
            <div>
              {`You’ll still have ${addonTitle.toLowerCase()} access through the end of your current billing period. If removed, you can add a cart per round when you check-in at a course.`}
            </div>
          )}
          {subscription.paused && (
            <div>
              {`Are you sure you want to remove ${addonTitle.toLowerCase()}?`}
            </div>
          )}
          <button
            className={clsx("btn", trx.loading ? "btn-disabled" : "btn-contained-primary")}
            onClick={onSubmit}
            disabled={trx.loading}
          >
            {trx.loading ? 'Removing' : 'Remove'}
          </button>
          <button
            className={clsx("btn", trx.loading ? "btn-disabled" : "btn-outlined-primary")}
            onClick={onDismiss}
            disabled={trx.loading}
          >
            {`Keep ${addonTitle}`}
          </button>
        </>
      )}
      
      {trx.complete && (
        <>
          <div className='flex justify-center'>
            <div>
              Successfully canceled addon
            </div>
          </div>
          <div className='flex justify-center'>
            <FontAwesomeIcon icon={faCheckCircle} size="xl" color="var(--color-golf-green)" />
          </div>
        </>
      )}
      {trx.error && (
        <div className="text-red-500 text-sm font-bold">{trx.error.message || 'Something went wrong.'}</div>
      )}
    </Confirmation.Container>
  );
}
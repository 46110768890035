import BarLoader from 'react-spinners/BarLoader';
import { animated, useSpring } from 'react-spring';

const LoadingBarCssOverride = {
  backgroundColor: 'var(--color-golf-green-light)',
  borderRadius: '2px',
};

export default function LoadingBar({ show = true, delay, ...props }) {
  const springStyle = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: show ? 1 : 0,
    },
    delay: show ? delay : 0,
  });

  return (
    <animated.div style={springStyle}>
      <div className="flex flex-col items-center w-full" {...props}>
        <BarLoader cssOverride={LoadingBarCssOverride} color="var(--color-golf-green)" />
      </div>
    </animated.div>
  );
}

import React, { useMemo } from 'react';
import useSWR from 'swr';
import ItemList from '../../components/ItemList';
import InfoListItem from '../../components/InfoListItem';
import LoadingBar from '../../components/LoadingBar';
import { formatCurrency } from '../../lib/formatting';
import * as API from '../../lib/api';


export default function CheckoutDueToday({ access, addons, promotion, /* invoice, loading, error, */ disabled, cancelTitle, onCancel }) {
  const { data, error } = useSWR(
    [`CREATE /subscriptions?preview=true&access=${access.uid}&addons=${addons.map(item => item.uid || item).join(',')}&promotion_code=${promotion?.uid}`],
    () => API.createSubscription({
      access: access.uid,
      addons: addons.map(item => item.uid || item),
      promotion_code: promotion?.uid,
      preview: true
    }),
  );

  const description = useMemo(() => {
    if (error) {
      return (
        <div className="text-red-500 text-sm font-bold">{error?.message || error || 'Something went wrong'}</div>
      );
    }

    if (!data) {
      return (
        <div className="flex">
          <LoadingBar />
        </div>
      );
    }

    const { invoice } = data;

    if (invoice.discounts.length > 0) {
      return (
        <>
          <span className="line-through">{formatCurrency(invoice.subtotal / 100)}</span>
          <span>{` ${formatCurrency((invoice.total - (invoice.tax || 0)) / 100)} + Taxes`}</span>
        </>
      );
    } else {
      return (
        <>
          <span>{`${formatCurrency(invoice.subtotal / 100)} + Taxes`}</span>
        </>
      );
    }
  }, [data, error]);

  return (
    <ItemList>
      <InfoListItem
        disabled={disabled}
        title="Due Today"
        option={{
          type: 'button',
          value: cancelTitle,
          onClick: onCancel
        }}
      >
        <div>
          {description}
        </div>
      </InfoListItem>
    </ItemList>
  );
}
import ky from 'ky';
import * as AuthServices from './auth';

const BASE_URL = process.env.REACT_APP_API_BASE_URL || '/api/account';

const KY_HOOKS = {
  beforeError: [
    async (error) => {
      let message = await error.response.text();
      try {
        message = JSON.parse(message).error.message;
      } catch {
        //
      }
      error.message = message;
      error.code = error.response.status;
      return error;
    }
  ]
};

function opts(inputOpts) {
  return {
    ...inputOpts,
    hooks: KY_HOOKS
  };
}

function cleanSearchParams(params) {
  return JSON.parse(JSON.stringify(params));
}

export async function authorizedHeaders() {
  const token = await AuthServices.accessToken();
  if (!token) {
    throw new Error('Not logged in');
  }
  return {
    Authorization: `Bearer ${token}`,
  };
}

export async function createUser(userInfo) {
  return ky.post(BASE_URL + '/v1/users', opts({ json: userInfo })).json();
}

export async function readUser(uid = 'me') {
  return ky.get(BASE_URL + `/v1/users/${uid}`, opts({
    headers: await authorizedHeaders(),
  })).json();
}

export async function userGetMySeasonStats() {
  return ky.get(BASE_URL + '/v1/users/me/myseasonstats', opts({
    headers: await authorizedHeaders(),
  })).json();
}

export async function updateUser(data) {
  return ky.patch(BASE_URL + '/v1/users/me', opts({
    headers: await authorizedHeaders(),
    json: data,
  })).json();
}

export async function uploadUserProfilePicture(file) {
  const formData = new FormData();
  formData.append('picture', file, file.name);

  return ky.post(BASE_URL + '/v1/users/me/profile-picture', opts({
    headers: await authorizedHeaders(),
    body: formData,
    timeout: false,
  })).json();
}

export async function listSubscriptions({ user = 'me' } = {}) {
  // const queryString = toQueryString({
  //   user,
  // });
  // console.log(queryString);
  // const req = await fetch(`/v1/subscriptions${queryString}`, {
  //   headers: await authorizedHeaders(),
  // });
  // return await req.json();
  return ky.get(BASE_URL + '/v1/subscriptions', opts({
    headers: await authorizedHeaders(),
    searchParams: cleanSearchParams({
      user,
    }),
  })).json();
}

export async function retrieveSubscription(uid) {
  return ky.get(BASE_URL + `/v1/subscriptions/${uid}`, opts({
    headers: await authorizedHeaders(),
  })).json();
}


export async function updateSubscription(uid, data) {
  return ky.patch(BASE_URL + `/v1/subscriptions/${uid}`, opts({
    headers: await authorizedHeaders(),
    json: data,
  })).json();
}


export async function createSubscription({ access, addons, promotion_code, preview }) {
  return ky.post(BASE_URL + `/v1/subscriptions`, opts({
    headers: await authorizedHeaders(),
    json: {
      access,
      addons,
      promotion_code,
      preview,
    },
    timeout: false,
  })).json();
}


export async function cancelSubscription(uid, options = {}) {
  return ky.post(BASE_URL + `/v1/subscriptions/${uid}/cancel`, opts({
    headers: await authorizedHeaders(),
    json: options,
  })).json();
}

export async function uncancelSubscription(uid, options = {}) {
  return ky.post(BASE_URL + `/v1/subscriptions/${uid}/uncancel`, opts({
    headers: await authorizedHeaders(),
    json: options,
  })).json();
}

export async function pauseSubscription(uid, options = {}) {
  return ky.post(BASE_URL + `/v1/subscriptions/${uid}/pause`, opts({
    headers: await authorizedHeaders(),
    json: options,
  })).json();
}

export async function unpauseSubscription(uid, options = {}) {
  return ky.post(BASE_URL + `/v1/subscriptions/${uid}/unpause`, opts({
    headers: await authorizedHeaders(),
    json: options,
  })).json();
}

export async function createSubscriptionAddon({ subscription, addon, preview, fee_total }) {
  return await ky.post(BASE_URL + '/v1/subscriptionaddons', opts({
    headers: await authorizedHeaders(),
    json: {
      subscription,
      addon,
      preview,
      fee_total,
    },
  })).json();
}

export async function cancelSubscriptionAddon(uid, options = {}) {
  return await ky.post(BASE_URL + `/v1/subscriptionaddons/${uid}/cancel`, opts({
    headers: await authorizedHeaders(),
    json: options,
  }));
}

export async function uncancelSubscriptionAddon(uid, options = {}) {
  return await ky.post(BASE_URL + `/v1/subscriptionaddons/${uid}/uncancel`, opts({
    headers: await authorizedHeaders(),
    json: options,
  }));
}


export async function listActivityTypes({
  active, site, user,
  limit, offset, search,
}) {
  return await ky.get(BASE_URL + '/v1/activitytypes', opts({
    headers: await authorizedHeaders(),
    searchParams: cleanSearchParams({
      active, site, user,
      limit, offset, search,
    }),
  })).json();
}

export async function listActivities({
  active, access, network,
  limit, offset, search,
}) {
  return await ky.get(BASE_URL + '/v1/activities', opts({
    headers: await authorizedHeaders(),
    searchParams: cleanSearchParams({
      access, network, active,
      limit, offset, search,
    }),
  })).json();
}

export async function listAccesses({
  active, activity, network,
  limit, offset, search,
}) {
  return await ky.get(BASE_URL + '/v1/accesses', opts({
    headers: await authorizedHeaders(),
    searchParams: cleanSearchParams({
      active, activity, network,
      limit, offset, search,
    }),
  })).json();
}

export async function getAccess(access) {
  return await ky.get(BASE_URL + `/v1/accesses/${access}`, opts({
    headers: await authorizedHeaders(),
  })).json();
}

export async function createCheckIn({ site_activity, preview, credentials, options }) {
  return await ky.post(BASE_URL + '/v1/checkins', opts({
    headers: await authorizedHeaders(),
    json: {
      credentials,
      options,
      preview,
      site_activity,
    },
  })).json();
}

export async function listCheckIns({
  user, success,
  limit, offset, search,
}) {
  return await ky.get(BASE_URL + '/v1/checkins', opts({
    headers: await authorizedHeaders(),
    searchParams: cleanSearchParams({
      user, success,
      limit, offset, search,
    }),
  })).json();
}

export async function listSites({
  activity_type, user, location,
  limit, offset, search,
} = {}) {
  return await ky.get(BASE_URL + '/v1/sites', opts({
    headers: await authorizedHeaders(),
    searchParams: cleanSearchParams({
      activity_type, user, location,
      limit, offset, search,
    }),
  })).json();
}

export async function listSiteActivities({
  subscription, site, activity_type, user, location,
  limit, offset, search,
} = {}) {
  return await ky.get(BASE_URL + '/v1/siteactivities', opts({
    headers: await authorizedHeaders(),
    searchParams: cleanSearchParams({
      subscription, site, activity_type, user, location,
      limit, offset, search,
    }),
  })).json();
}


export async function listNetworks({
  active,
  limit, offset, search,
} = {}) {
  return await ky.get(BASE_URL + '/v1/networks', opts({
    headers: await authorizedHeaders(),
    searchParams: cleanSearchParams({
      active, limit, offset, search,
    }),
  })).json();
}

export async function getPromotionCode(uid) {
  return await ky.get(BASE_URL + `/v1/promotioncodes/${encodeURIComponent(uid)}`, opts({
    headers: await authorizedHeaders(),
  })).json();
}

export async function checkInAddGolfCart(checkInUID, input) {
  return await ky.post(BASE_URL + `/v1/checkins/${checkInUID}/add_golf_cart`, opts({
    headers: await authorizedHeaders(),
    json: input,
  })).json();
}

export async function listUserReferrals({ user = 'me' } = {}) {
  return await ky.get(BASE_URL + '/v1/userreferrals', opts({
    headers: await authorizedHeaders(),
    searchParams: cleanSearchParams({
      user,
    }),
  })).json();
}

export async function listAddons({
  access, active,
  limit, offset,
}) {
  return await ky.get(BASE_URL + '/v1/addons', opts({
    headers: await authorizedHeaders(),
    searchParams: cleanSearchParams({
      access, active,
      limit, offset,
    }),
  })).json();
}
import React, { useEffect } from 'react';
import { Route, Routes, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { parseQueryString } from './lib/qs';
import { STORAGE_KEY_SHARE_PROMOTION } from './constants';

import history from './history';
import App from './pages/App';

function Share() {
  useEffect(() => {
    const search = parseQueryString(window.location.search);
    const promotion = search.promotion;
    if (promotion) {
      localStorage.setItem(STORAGE_KEY_SHARE_PROMOTION, promotion);
    }
    window.location.href = 'https://www.chippergolf.com/plan-regions';
  }, []);

  return null;
}

export default function RoutesOuter() {
  return (
    <React.Suspense>
      <HistoryRouter history={history}>
        <Routes>
          <Route path="/share" element={<Share />} />
          <Route path="*" element={<App />} />
        </Routes>
      </HistoryRouter>
    </React.Suspense>
  );
};

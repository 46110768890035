import React, { useState, useEffect, useRef } from 'react';
import Preview from './Preview';
import Complete from './Complete';
import Blocked from './Blocked';
// import ErrorCheckIn from './ErrorCheckIn';
import MembershipPictureRequirement from '../MembershipPictureRequirement';
// import Page from '../../components/Page';
import Resubscribe from '../Resubscribe';
import UpdatePaymentMethod from './UpdatePaymentMethod';
import MempershipPolicies from '../MembershipPolicies';
import CheckInBaseSelector from './CheckInBaseSelector';
import useMeSWR from 'hooks/useMeSWR';
import useMySubscriptionsSWR from 'hooks/useMySubscriptionsSWR';
// import Branding from 'components/Branding';
import useLogic, { STAGES } from './useLogic';
import useNeedsUpdatedPaymentMethod from 'hooks/useNeedsUpdatedPaymentMethod';

function useShowPictureRequirement(user) {
  const shouldCheckRef = useRef(true);
  const [showPictureRequirement, setShowPictureRequirement] = useState(false);
  useEffect(() => {
    if (shouldCheckRef.current && !showPictureRequirement && user && user.membership_picture_deadline != null) {
      setShowPictureRequirement(true);
      shouldCheckRef.current = false;
    }
  }, [user, showPictureRequirement]);
  return [showPictureRequirement, setShowPictureRequirement];
}

export default function CheckIn() {
  const meSWR = useMeSWR();

  const {
    state,
    stage,
    sitesSWR,
    siteActivitiesSWR,
    previewSWR,
    // checkIn,
    lastCheckInSWR,
    createCheckInState,
    onReset,
    onSelectSite,
    onSelectSiteActivity,
    onSetOptions,
    onCreateCheckIn,
  } = useLogic();

  const mySubscriptionsSWR = useMySubscriptionsSWR();

  const [showPictureRequirement, setShowPictureRequirement] = useShowPictureRequirement(meSWR.data);

  const needsUpdatedPaymentMethod = useNeedsUpdatedPaymentMethod();

  /** Showing ticket has highest priority */
  if (stage === STAGES.COMPLETE) {
    return (
      <Complete
        checkIn={lastCheckInSWR.data}
      />
    );
  }

  if (mySubscriptionsSWR.data?.length === 0) {
    return (
      <Resubscribe showGoBackButton={false} />
    );
  }

  if (needsUpdatedPaymentMethod === true) {
    return (
      <UpdatePaymentMethod />
    );
  }

  if (meSWR.data?.accepted_policies_1 === false) {
    return (
      <MempershipPolicies />
    );
  }

  if (state.blocked) {
    return (
      <Blocked
        info={state.blocked}
        onReset={onReset}
      />
    );
  }

  if (showPictureRequirement) {
    return (
      <MembershipPictureRequirement
        onDismiss={() => setShowPictureRequirement(false)}
      />
    );
  }

  switch (stage) {
    case STAGES.INITIALIZATION:
      return null;
      // return (
      //   <div className="flex justify-center flex-1">
      //     <Branding logo={true} logoProps={{ size: 100, color: 'red' }} />
      //   </div>
      // );
    case STAGES.SITE_SELECTOR:
      return (
        <CheckInBaseSelector
          swr={sitesSWR}
          onSubmit={onSelectSite}
          title="Where are you playing?"
          optionTitle={(entity) => entity.name}
          optionDescription={(entity) => entity.Address?.city || ''}
        />
      );
    case STAGES.SITE_ACTIVITY_SELECTOR:
      return (
        <CheckInBaseSelector
          swr={siteActivitiesSWR}
          onSubmit={onSelectSiteActivity}
          title="What are you playing?"
          optionTitle={(entity) => entity.ActivityType?.name || entity.name}
        />
      );
    case STAGES.ACTIVITY_CONFIGURATION:
      return (
        <Preview
          state={state}
          previewSWR={previewSWR}
          onSetOptions={onSetOptions}
          onCancel={onReset}
          onSubmit={onCreateCheckIn}
          createCheckInState={createCheckInState}
        />
      );
    default:
      throw new Error(`Invalid check-in stage: ${stage}`);
  }
}

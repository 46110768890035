import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { animated, useSpring } from 'react-spring';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';


const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  color: var(--color-dark-gray);
  text-align: center;
  overflow: hidden;
  border-radius: 50%;
  transition: opacity .25s;
  user-select: none;

  &:active {
    opacity: 1;
    transition-delay: 0.25s;
  }

  ${(props) => props.hasImage
    ? css`
      opacity: 0;

      @media (hover: hover) {
        &:hover {
          opacity: 1;
        }
      }
    `
    : css`
      opacity: 1;
    `
  }
`;


export default function Dropzone({ title, disabled, accept, onFilesAdded, imageURL, children, openRef }) {
  const onDrop = useCallback((files) => {
    onFilesAdded([...files]);
  }, [onFilesAdded]);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept,
    disabled
  });

  if (openRef) {
    openRef.current = open;
  }

  const springProps = useSpring({
    opacity: isDragActive ? 0.2 : 1
  });

  return (
    /** TODO: width and height should not be hardcoded */
    <div className="relative w-[160px] h-[160px] outline-none" {...getRootProps()}>
      <input {...getInputProps()} />

      <animated.div
        className={`relative w-full h-full outline-none${isDragActive ? " outline-none" : ""}`}
        style={{ cursor: disabled ? 'default' : 'pointer', ...springProps }}
      >
        <div className="absolute">
          {children}
        </div>
        <div className="absolute h-full hover:cursor-pointer aspect-square">
          <Overlay hasImage={!!imageURL}>
            <FontAwesomeIcon icon={faUpload} />
            <span className="px-2">{title}</span>
          </Overlay>
        </div>
        
      </animated.div>
    </div>
  );
}

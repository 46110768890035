import React from 'react';
import styled from 'styled-components';

/** https://medium.com/@colebemis/building-a-checkbox-component-with-react-and-styled-components-8d3aa1d826dd */

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 3px;
  flex: 1;
`;


const StyledCheckbox = styled.div`
  display: flex;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: ${props => props.checked ? 'var(--color-golf-green)' : '#FDFDFD'};
  border-radius: 4px;
  transition: all 150ms;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  border: solid 2px #ffffff;

  ${Icon} {
    visibility: ${props => props.checked ? 'visible' : 'hidden'};
  }
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
`;


export default React.forwardRef(function Checkbox({ className, checked, ...props }, ref) {
  return (
    <div className="inline-block align-middle box-border">
      <HiddenCheckbox checked={checked} {...props} ref={ref} />
      <StyledCheckbox checked={checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </div>
  );
});
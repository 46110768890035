import React, { useMemo } from 'react';
import useSWR from 'swr';
import Page from '../components/Page';
import SiteActivityCard from '../components/SiteActivityCard';
import Resubscribe from './Resubscribe';
import * as API from '../lib/api';
import { subscriptionCanBeUnpaused } from '../lib/subscription';
import { CHECK_IN_TERMS, APP_URLS } from '../constants';
import useMySubscriptionsSWR from 'hooks/useMySubscriptionsSWR';
import useMeSWR from '../hooks/useMeSWR';
import { Link } from 'react-router-dom';


export default function MySiteActivities() {
  const meSWR = useMeSWR();
  const mySubscriptionsSWR = useMySubscriptionsSWR();

  const {
    data: siteActivities,
    // isValidating,
    error,
  } = useSWR('/siteactivities?user=me', () => API.listSiteActivities({
    user: 'me',
  }));

  const sections = useMemo(() => {
    const activeSites = [];
    const pausedSites = [];
    // const activeHigherAccess = [];
    // const puasedHigherAccess = [];

    // console.log(meData.subscriptions);

    if (siteActivities && mySubscriptionsSWR.data) {
      for (const subscription of mySubscriptionsSWR.data) {
        for (const siteActivity of siteActivities) {
          for (const service of siteActivity.Service) {
            if (
              subscription.Access.Activity.uid === service.Access.Activity.uid
              // && subscription.access.level >= service.access.level
            ) {
              if (subscription.paused && !subscriptionCanBeUnpaused(subscription)) {
                pausedSites.push(siteActivity);
              } else {
                activeSites.push(siteActivity);
              }
              break;
            }
          }
        }
      }
    }
    
    const sections = [];
    if (activeSites.length > 0) {
      sections.push({
        items: activeSites
      });
    }
    if (pausedSites.length > 0) {
      sections.push({
        title: 'Closed for the off-season',
        subtitle: 'Your membership at these courses is paused for the off-season',
        items: pausedSites
      });
    }
    return sections;
  }, [siteActivities, mySubscriptionsSWR.data]);

  const showUnpauseButton = useMemo(() => {
    if (mySubscriptionsSWR.data) {
      for (const subscription of mySubscriptionsSWR.data) {
        if (subscriptionCanBeUnpaused(subscription)) {
          return true;
        }
      }
    }
    return false;
  }, [mySubscriptionsSWR.data]);
  
  // const showFOWUpdate = useMemo(() => {
  //   if (mySubscriptionsSWR.data) {
  //     for (const subscription of mySubscriptionsSWR.data) {
  //       if (subscription.Access.Activity.Network.uid === 'ubYVlvLHHLfcddgMErgEU') {
  //         return true;
  //       }
  //     }
  //   }
  //   return false;
  // }, [mySubscriptionsSWR.data]);

  if (error) {
    return (
      <div className="text-red-500 text-sm font-bold">{error.message || error}</div>
    );
  }

  if (mySubscriptionsSWR.data?.length === 0) {
    return (
      <Resubscribe showGoBackButton={false} />
    );
  }

  if (!meSWR.data) {
    return null;
  }

  return (
    <Page>
      <div className="px-4">
        {/* {showFOWUpdate && (
          <div className="text-center mb-8">
            <div className="text-2xl font-bold">Important Course Update</div>
            <div className="font-medium">Fairways of Woodside will no longer be available starting on October 1st.</div>
            <div className="mt-2">
              <Link
                to={APP_URLS.FOW_UPDATE}
                className="btn btn-contained-primary"
              >
                Read Full Update
              </Link>
            </div>
          </div>
        )} */}

        <div className="text-center mb-8">
          <div className="text-3xl font-bold">My Courses</div>
          <div className="font-bold">Call your courses to book tee-times</div>
          <div>{CHECK_IN_TERMS}</div>
        </div>

        <div>
          {showUnpauseButton && (
            <div className="flex justify-center mb-4">
              <Link
                className="btn btn-contained-primary shadow-lg font-bold text-xl"
                to={APP_URLS.REACTIVATION}
              >
                Reactivate Subscription
              </Link>
            </div>
          )}

          {siteActivities && (
            <div className="grid gap-8">
              {sections.map((siteSection, idx) => (
                <div key={idx}>
                  {(siteSection.title || siteSection.subtitle) && (
                    <div style={{ marginBottom: '8px' }}>
                      {siteSection.title && (
                        <>
                          <div className="text-2xl font-bold">{siteSection.title}</div>
                        </>
                      )}
                      {siteSection.subtitle && (
                        <div>{siteSection.subtitle}</div>
                      )}
                    </div>
                  )}
                  <div
                    className="grid gap-4"
                    style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))' }}
                  >
                    {siteSection.items.map((siteActivity) => {
                      return (
                        <div key={siteActivity.uid}>
                          <SiteActivityCard
                            siteActivity={siteActivity}
                            address={siteActivity.Site.Address}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </Page>
  );
}
import MainLayout from '../components/MainLayout';
import Branding from '../components/Branding';
import useLogout from 'hooks/useLogout';

const openHowTo = () => window.open('https://www.chippergolf.com/early-access');
const openSupport = () => window.open('https://www.chippergolf.com/customer-support');

export default function WaitList() {
  const onLogout = useLogout();

  return (
    <MainLayout
      Header={{ className: "justify-center" }}
      header={(
        <Branding logo={true} name={true} />
      )}
      title={(
        <>
          <div className="text-3xl font-extrabold">Chipper Golf</div>
          <div className="text-3xl font-extrabold">Wait List</div>
        </>
      )}
      content={(
        <>
          <div className="grid gap-8 justify-items-center">
            <div className="text-xl text-center max-w-screen-sm">
              You have been added to our Chipper Golf wait list. We will notify you when a spot opens up via email. Please use the links below for more information.
            </div>
            <button className="btn-contained-primary" onClick={openHowTo}>How Chipper Works</button>
            <button className="btn-contained-primary" onClick={openSupport}>Contact Support</button>
            <button className="btn-outlined-primary" onClick={onLogout}>Log Out</button>
          </div>
        </>
      )}
    />
  );
}
import { createBrowserHistory } from 'history';
import { mixpanelTrack } from './integrations/mixpanel';

const history = createBrowserHistory();

history.listen(({ location, action }) => {
  mixpanelTrack('navigate', {
    action,
    location,
  });

  if (action === 'PUSH' || action === 'REPLACE') {
    window.scrollTo(0, 0);
  }
});

export default history;

// let __navigate;

// export function getNavigate() {
//   return __navigate;
// }

// export function setNavigate(navigate) {
//   __navigate = navigate;
// }
import * as AppActions from '../actions/app';

const initialState = {
  header: null
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case AppActions.REGISTER_HEADER:
      return {
        ...state,
        header: action.payload
      };
    case AppActions.CLEAR_HEADER:
      return {
        ...state,
        header: state.header?.id === action.payload ? null : state.header
      };
    default:
      return state;
  }
}

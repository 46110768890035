export function subscriptionPausing(subscription) {
  return subscription.pause_at_period_end;
}

export function subscriptionPaused(subscription) {
  return subscription.paused === true;
}

export function subscriptionTrialing(subscription) {
  return subscription.status === 'trialing';
}

export function subscriptionCanceling(subscription) {
  return subscription.cancel_at_period_end === true;
}

export function subscriptionCancelsImmediately(subscription) {
  return subscription.cancels_immediately;
}

export function subscriptionStatus(subscription = {}) {
  return {
    pausing: subscriptionPausing(subscription),
    paused: subscriptionPaused(subscription),
    trialing: subscriptionTrialing(subscription),
    canceling: subscriptionCanceling(subscription),
    cancelsImmediately: subscriptionCancelsImmediately(subscription)
  };
}



function devicePixelRatio() {
  const ratio = window.devicePixelRatio;
  if (Number.isFinite(ratio)) {
    return ratio;
  }
  return null;
}

export function getIKSmartSizeImageURL(url, width = 156, height = 156) {
  url = new URL(url);
  const pixelRatio = devicePixelRatio();
  url.searchParams.append('tr', `w-${width},h-${height}${pixelRatio ? `,dpr-${pixelRatio}` : ''}`);
  return url.toString();
}


export function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


export const FullMonthNames = [
  "January", "February", "March", "April",
  "May", "June", "July", "August",
  "September", "October", "November", "December"
];

export const ShortMonthNames = [
  'Jan', 'Feb', 'Mar', 'Apr',
  'May', 'Jun', 'Jul', 'Aug',
  'Sept', 'Oct', 'Nov', 'Dec'
];


export function objectEquals(x, y) {
  if (x === null || x === undefined || y === null || y === undefined) { return x === y; }
  // after this just checking type of one would be enough
  if (x.constructor !== y.constructor) { return false; }
  // if they are functions, they should exactly refer to same one (because of closures)
  if (x instanceof Function) { return x === y; }
  // if they are regexps, they should exactly refer to same one (it is hard to better equality check on current ES)
  if (x instanceof RegExp) { return x === y; }
  if (x === y || x.valueOf() === y.valueOf()) { return true; }
  if (Array.isArray(x) && x.length !== y.length) { return false; }

  // if they are dates, they must had equal valueOf
  if (x instanceof Date) { return false; }

  // if they are strictly equal, they both need to be object at least
  if (!(x instanceof Object)) { return false; }
  if (!(y instanceof Object)) { return false; }

  // recursive object equality check
  var p = Object.keys(x);
  return Object.keys(y).every(function (i) { return p.indexOf(i) !== -1; }) &&
    p.every(function (i) { return objectEquals(x[i], y[i]); });
}

export const getPriceAmount = (entity) => entity.price_config?.unit_amount;
export const getPriceInterval = (entity) => entity.price_config?.recurring?.interval;
export const getPriceIntervalCount = (entity) => entity.price_config?.recurring?.interval_count;
export const getPriceCurrency = (entity) => entity.price_config?.currency;
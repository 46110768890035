export const HowToURL = 'https://www.chippergolf.com/early-access';
export const SupportSiteURL = 'https://www.chippergolf.com/support';
export const PrivacyPolicyURL = 'https://www.chippergolf.com/privacy-policy';

export const MOBILE_BREAKPOINT = '(max-width:960px)';
export const DESKTOP_BREAKPOINT = '(min-width:961px)';

export const APP_URLS = {
  LOGIN: '/login',
  SIGNUP: '/signup',
  RESET_PASSWORD: '/reset-password',
  SUPPORT: '/support',
  CHECKIN: '/checkin',
  ACCOUNT: '/account',
  ACCOUNT_SETTINGS: '/account-settings',
  RESUBSCRIBE: '/resubscribe',
  INSTALL_APP: '/install-webapp',
  MANAGE_PLAN: '/manage-my-plan',
  ADD_UNLIMITED_CART: '/add-unlimited-cart',
  COURSES: '/courses',
  USER_REFERRAL_INFO: '/user-referral-info',
  CANCELATION_SURVEY: '/cancel-survey',
  PAUSE_SUBSCRIPTION_LEARNING: '/pause-subscription-learning',
  SUBSCRIBE: '/subscribe',
  ONE_LAST_THING: '/one-last-thing',
  GOLF_SIMULATOR_PROMOTION: '/golf-simulator-promotion',
  MEMBERSHIP_PICTURE_REQUIREMENT: '/membership-picture-requirement',
  REACTIVATION: '/reactivation',
  MY_SEASON: '/myseason',
  END_SEASON: '/endseason',
  SUBSCRIPTIONS: '/subscriptions',
  FOW_UPDATE: '/fow-update',
  MANAGE_SUBSCRIPTION: (subscriptionUID) => `/account/subscriptions/${subscriptionUID}`
};

export const CHECK_IN_TERMS = 'Only check in when you’re at the course golfing';

export const ActivityTypeRefs = {
  Golf: 'golf',
  GolfSimulator: 'golfsimulator'
};

export const ONBOARDING_STAGES = {
  SUBSCRIBE: 'subscribe',
  ONE_LAST_THING: 'one-last-thing'
};

export const IsIosDevice =
  typeof window !== 'undefined' &&
  window.navigator &&
  window.navigator.platform &&
  /iP(ad|hone|od)/.test(window.navigator.platform);

export const STORAGE_KEY_SHARE_PROMOTION = 'share-promotion';

export const SWR_LATEST_CHECKINS = 'LATEST_CHECKINS';
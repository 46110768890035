import FullPageIFrame from '../components/FullPageIFrame';

export default function LearnPause() {
  return (
    <FullPageIFrame
      title="Pause Membership"
      src="https://www.chippergolf.com/pause-account-iframe"
      showGoBackButton={true}
    />
  );
}
import { all } from 'redux-saga/effects';

import AppSagas from './app';
import AccountSagas from './account';
import SubscribeSagas from './subscribe';

export default function* root() {
  yield all([
    ...AppSagas,
    ...AccountSagas,
    ...SubscribeSagas
  ]);
}

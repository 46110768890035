

const EMAIL_REGEX = /\S+@\S+\.\S+/;
export function validateEmail(input) {
  return EMAIL_REGEX.test(input);
}

const PHONE_NUMBER_REGEX = /[0-9]{10}/;
export function validatePhoneNumber(input) {
  return !!(input && PHONE_NUMBER_REGEX.test(input));
}

const PIN_REGEX = /[0-9]{4}/;
export function validatePin(input) {
  return !!(input && PIN_REGEX.test(input));
}

const ZIP_CODE_REGEX = /[0-9]{5}/;
export function validateZipCode(input) {
  return !!(input && ZIP_CODE_REGEX.test(input));
}


export function validatePassword(input) {
  return !!(input && input.length >= 8);
}


export function validatePromoCode(code) {
  return !!(code && code.length >= 3);
}
import { action } from './common';

const BASE = 'APP/';

export const RESET = `${BASE}RESET`;
export const reset = () => action(RESET);

export const INITIALIZE = `${BASE}INITIALIZE`;
export const initialize = () => action(INITIALIZE);

export const REGISTER_HEADER = `${BASE}REGISTER_HEADER`;
export const registerHeader = (id, { title, action: actionFn }) => action(REGISTER_HEADER, { id, title, action: actionFn });
export const CLEAR_HEADER = `${BASE}CLEAR_HEADER`;
export const clearHeader = (id) => action(CLEAR_HEADER, id);
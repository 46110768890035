import styled from 'styled-components';

export default styled.div`
  border: 2px solid #f1f1f1;
  border-radius: 6px;
  overflow: hidden;
  & > * {
    border-top: 2px solid #f1f1f1;
  }
  & > *:first-child {
    border-top: none;
  }
`;
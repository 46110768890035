import styled from 'styled-components';

export default styled.div`
  padding-top: 16px;
  overflow-y: auto;

  flex-grow: 1;
  flex-shrink: 0;

  position: relative;

  &::after {
    content: "";
    display: block;
    clear: both;
    // padding-bottom: 80px;
    // padding-bottom: 92px;
    padding-bottom: 112px;
  }
`;
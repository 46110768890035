export default function YourPlans({ subscriptions, onReactivateSubscription }) {
  return (
    <div>
      <div className="font-bold text-xl">Your Plans</div>
      <div>
        {subscriptions.map(subscription => (
          <div key={subscription.uid} className="border-t mt-4">
            <div className="font-bold">{subscription.access.name}</div>
            <button
              onClick={() => onReactivateSubscription(subscription)}
              className="btn btn-contained-primary"
            >
              Continue
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}
import { useState } from 'react';

export default function useRequests({
  onSuccess: __onSuccess,
  onError: __onError
}) {
  const [loading, setLoading] = useState({ show: false });

  return {
    loading,
    useRequest: ({
      loadingTitle,
      fn,
      context,
      onSuccess,
      onError
    }) => async (...args) => {
      setLoading({
        show: true,
        title: loadingTitle
      });
      try {
        const request = await fn(...args);
        (onSuccess || __onSuccess)({ context, request, args, onSuccess: () => __onSuccess({ context, request, args }) });
      } catch (error) {
        (onError || __onError)({ context, error });
      }
      setLoading({ show: false });
    }
  }
}
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { useMedia } from 'react-use';
import Logo from '../components/Logo';
import MainLayout from '../components/MainLayout';
import { validateEmail } from '../lib/validation';
import { APP_URLS } from '../constants';
import TextInput from '../components/Inputs/Text';
import useResetPassword from 'hooks/useResetPassword';


function OnSubmitButton({ control, children }) {
  const { email } = useWatch({ control });

  const disabled = useMemo(() => {
    return !validateEmail(email);
  }, [email]);

  return (
    <button
      type="submit"
      className={disabled ? "btn-disabled" : "btn-contained-primary"}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export default function ResetPassword() {
  const isWide = useMedia('(min-width: 600px)');

  const onResetPassword = useResetPassword();

  const { handleSubmit, control, register } = useForm();

  return (
    <MainLayout
      Header={{ className: "justify-between" }}
      card
      header={(
        <>
          <Logo />
          <div className="flex flex-row items-center">
            {isWide && (<div className="text-lg font-bold mx-4">Don't have an account yet?</div>)}
            <Link
              to={APP_URLS.SIGNUP}
              className="btn-contained-primary"
            >
              Sign Up
            </Link>
          </div>
        </>
      )}
      title={(
        <div className="text-3xl font-extrabold">Reset Password</div>
      )}
      content={(
        <form
          className="grid gap-4"
          onSubmit={handleSubmit(onResetPassword)}
          noValidate
        >
          <div>
            <label>Email</label>
            <TextInput
              {...register("email")}
              type="email"
              autoFocus={true}
            // disabled={updating}
            />
          </div>

          <OnSubmitButton control={control}>
            Reset
          </OnSubmitButton>
          <div className="flex justify-center">
            <Link
              className="btn-link-primary"
              to={APP_URLS.LOGIN}
            >
              Cancel
            </Link>
          </div>
        </form>
      )}
    />
  );
}
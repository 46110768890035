import {
  action
} from './common';

const BASE = 'ACCOUNT/';

export const USER_INFO_RESULT = `${BASE}USER_INFO_RESULT`;
export const userInfoResult = (value) => action(USER_INFO_RESULT, value);

export const SHOW_CANCELATION_SURVEY = `${BASE}SHOW_CANCELATION_SURVEY`;
export const showCancelationSurvey = value => action(SHOW_CANCELATION_SURVEY, value);
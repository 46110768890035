import { formatCurrency } from '../../lib/formatting';

export default function CreditBalance({ amount }) {
  return (
    <div>
      <div className="text-lg font-bold">
        Credit
      </div>
      <div>
        {formatCurrency(-amount / 100)}
      </div>
    </div>
  )
}
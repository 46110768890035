export function toQueryString(params, doNotEncode) {
  return '?' + Object.entries(params)
    .map(([key, value]) => {
      if (value != null && value !== '') {
        if (doNotEncode === true) {
          return `${key}=${value}`;
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      }
      return null;
    }).filter(v => !!v).join('&');
}



function decode(str) {
  return str
    .replace(/[ +]/g, '%20')
    .replace(/(%[a-f0-9]{2})+/ig, function (match) {
      return decodeURIComponent(match);
    });
}


function appendTo(dict, name, value) {
  const val = typeof value === 'string' ? value : (
    value !== null && value !== undefined && typeof value.toString === 'function' ? value.toString() : JSON.stringify(value)
  );

  // #47 Prevent using `hasOwnProperty` as a property name
  if (Object.prototype.hasOwnProperty.call(dict, name)) {
    if (Array.isArray(dict[name])) {
      dict[name].push(value);
    } else {
      dict[name] = [dict[name], value];
    }
  } else {
    dict[name] = val;
  }
}


export function parseQueryString(search) {
  const dict = {};

  if (typeof search === "object") {
    // if `search` is an array, treat it as a sequence
    if (Array.isArray(search)) {
      for (let i = 0; i < search.length; i++) {
        const item = search[i];
        if (Array.isArray(item) && item.length === 2) {
          appendTo(dict, item[0], item[1]);
        } else {
          throw new TypeError("Failed to construct 'URLSearchParams': Sequence initializer must only contain pair elements");
        }
      }

    } else {
      for (let key in search) {
        if (search.hasOwnProperty(key)) {
          appendTo(dict, key, search[key]);
        }
      }
    }

  } else {
    // remove first '?'
    if (search.indexOf("?") === 0) {
      search = search.slice(1);
    }

    const pairs = search.split("&");
    for (let j = 0; j < pairs.length; j++) {
      let value = pairs[j],
        index = value.indexOf('=');

      if (-1 < index) {
        appendTo(dict, decode(value.slice(0, index)), decode(value.slice(index + 1)));

      } else {
        if (value) {
          appendTo(dict, decode(value), '');
        }
      }
    }
  }

  return dict;
}
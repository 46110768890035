import useHeader from '../../hooks/useHeader';
import Page from '../../components/Page';
import { APP_URLS } from '../../constants';


export default function EndYourSeason() {
  useHeader(APP_URLS.END_SEASON + '/complete', {
    title: 'Membership Paused',
    action: {
      isBack: true,
      url: APP_URLS.ACCOUNT
    }
  });

  return (
    <Page>
      <div className="px-4 flex justify-center">
        <div className="max-w-md">
          <div className="grid gap-4">
            <img src="https://ik.imagekit.io/chippergolf/core/PauseHeader2021.png?updatedAt=1629903151451" alt="pause header" />

            <div className="font-medium text-2xl">Membership Successfully Paused for the Season</div>

            <div>
              <div className="font-bold">Your Golf Access</div>
              <div>Paused for offseason. If you golf again this season you’ll be charged a single round fee.</div>
            </div>

            {/* <div>
              <div>Didn’t mean to end your season?</div>
            </div>

            <button
              className='btn-contained-primary'
            >
              Reactivate Now
            </button> */}
          </div>

          <div className="grid gap-4">
            <div className="bg-gray-200 h-0.5 mt-10 mb-4"></div>

            <div className="font-medium text-2xl">What to Expect Next</div>

            <div>
              <div className="font-bold">Carry Over Days: Locked In</div>
              <div>The remaining days in your billing period have been locked in for next season. They’ll be added to your first month back next year.</div>
            </div>

            <div>
              <div className="font-bold">$1 Month - Applied To Next Season</div>
              <div>You’ll be charged $1/mo starting Jan 1st to hold your spot. Every dollar will be applied to your first month back next season.</div>
            </div>
            
            <div>
              <div className="font-bold">2022 Reactivation</div>
              <div>Your membership will be reactivated on May 1st unless you’ve reactivated it sooner. You can cancel anytime if you change your mind.</div>
            </div>
            
            <div>
              <div className="font-bold">We’ll Email Any Updates</div>
              <div>If anything changes with your plan during the offseason, you’ll be updated by email.</div>
            </div>

            <div>
              <div>Thanks for a great year. We’ll see you next season. If you have any questions, you can always reach us at support@chippergolf.com.</div>
            </div>
            
          </div>
        </div>

      </div>
    </Page>
  );
}
import useAuth0 from './useAuth0';
import useSWR from 'swr';
import * as API from '../lib/api';

export default function useMySubscriptionsSWR() {
  const { isAuthenticated } = useAuth0();

  return useSWR(isAuthenticated ? '/subscriptions?user=me' : null, async () => {
    return await API.listSubscriptions();
    
    // const start = Date.now();
    // const subscriptions = await API.listSubscriptions();
    // console.log('SUBSCRIPTIONS', subscriptions);
    // console.log('BENCHMARK', Date.now() - start);
    // return subscriptions;
  });
}

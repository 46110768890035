export default function ActivityTypeOptionItem({ title, description, icon }) {
  return (
    <div className="flex flex-col items-center cursor-pointer py-2 px-3.5">
      <div className="flex">
        <div className="text-xl">
          {title}
        </div>
        {icon}
      </div>
      {description && (
        <div className="mt-1 text-sm">
          {description}
        </div>
      )}
    </div>
  );
}
import mixpanel from 'mixpanel-browser';
import { Sentry } from './sentry';

mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN, { debug: process.env.NODE_ENV !== 'production' });

function mixpanelTrack(event_name, props) {
  try {
    if (typeof props !== 'object' || props == null) {
      props = { props };
    }
    mixpanel.track(event_name, JSON.parse(JSON.stringify(props)));
  } catch (err) {
    Sentry.captureException(err);
  }
}
export {
  mixpanel,
  mixpanelTrack,
};
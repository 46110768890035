import React, { useState, useCallback, useEffect, useRef } from 'react';
import LoadingBar from '../../components/LoadingBar';
import { OptionGroup, OptionGroupItem } from '../../components/OptionGroup';
import BaseContainer from './BaseContainer';
import { delay } from '../../lib/helpers';


export default function BaseSelector({ title, data, error, renderOption, onSubmit }) {
  const submittedRef = useRef(false);
  const [selectedEntity, setSelectedEntity] = useState(null);

  const handleSubmit = useCallback(async (entity) => {
    submittedRef.current = true;
    setSelectedEntity(entity);
    await delay(120);
    onSubmit(entity);
  }, [onSubmit]);

  useEffect(() => {
    if (!submittedRef.current && data && data.length === 1) {
      submittedRef.current = true;
      onSubmit(data[0]);
    }
  }, [data, onSubmit]);

  return (
    <BaseContainer title={title}>
      {!error && !data && (
        <LoadingBar delay={120} />
      )}
      {error && (
        <div className="text-red-500 text-sm font-bold">{error.message || error}</div>
      )}
      {data && (
        <>
          {data.length === 0 && (
            <div>No Options</div>
          )}
          {data.length > 0 && (
            <OptionGroup className="shadow-md bg-white" vertical>
              {data.map((entity, idx) => {
                const { uid } = entity;

                return (
                  <OptionGroupItem
                    key={uid || idx}
                    selected={selectedEntity && uid === selectedEntity.uid}
                    onClick={!selectedEntity ? () => handleSubmit(entity) : undefined}
                  >
                    {renderOption(entity, idx)}
                  </OptionGroupItem>
                )
              })}
            </OptionGroup>
          )}
        </>
      )}
    </BaseContainer>
  );
}
import { useForm, useWatch, Controller } from 'react-hook-form';
import clsx from 'clsx';
import { toast } from 'react-hot-toast';
import Page from '../components/Page';
import Checkbox from '../components/Checkbox';
import useHeader from '../hooks/useHeader';
import * as API from '../lib/api';
import useMeSWR from 'hooks/useMeSWR';


function OnSubmitButton({ children, control, formState }) {
  const { skippingTeeTimes, noSharingMemberships } = useWatch({ control });

  const disabled = !skippingTeeTimes || !noSharingMemberships || formState.isSubmitting;

  return (
    <button
      type="submit"
      className={clsx("btn mt-4 w-full", disabled ? "btn-disabled" : "btn-contained-primary")}
      disabled={disabled}
    >
      {children}
    </button>
  );
}


export default function MembershipPolicies() {
  useHeader('MembershipPolicies', {
    title: 'Membership Policies'
  });

  const { handleSubmit, control, formState, setValue, getValues } = useForm();

  const meSWR = useMeSWR();

  async function onSubmit() {
    try {
      const updatedUser = await API.updateUser({
        accepted_policies_1: true
      });
      meSWR.mutate(updatedUser);
    } catch (err) {
      toast.error(err.message);
    }
  }

  return (
    <Page className="px-4">
      <div className="flex justify-center">
        <div className="max-w-md">
          <div className="font-bold">Membership Policies</div>
          <div>
            Please review and accept a few of our most important membership policies.
            Click each box after reading to accept.
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              className="mt-4 bg-white rounded border-2 p-2"
              onClick={() => setValue("skippingTeeTimes", !getValues("skippingTeeTimes"))}
            >
              <div className="flex justify-between">
                <div className="font-bold text-lg">Skipping Tee Times</div>
                <Controller
                  name="skippingTeeTimes"
                  defaultValue={false}
                  control={control}
                  render={({ field }) => (
                    <label onClick={ev => ev.stopPropagation()}>
                      <Checkbox
                        disabled={formState.isSubmitting}
                        checked={field.value}
                        {...field}
                      />
                    </label>
                  )}
                />
              </div>
              <div>
                You will be charged a <b>$10 no-show fee</b> if you don't show up for
                a booked tee time or cancel at least 24 hours in advanced. Canceling
                unwanted tee times in advance helps our partner couses avoid loses.
              </div>
            </div>

            <div
              className="mt-4 bg-white rounded border-2 p-2"
              onClick={() => setValue("noSharingMemberships", !getValues("noSharingMemberships"))}
            >
              <div className="flex justify-between">
                <div className="font-bold text-lg">Don't Share Your Account</div>
                <Controller
                  name="noSharingMemberships"
                  defaultValue={false}
                  control={control}
                  render={({ field }) => (
                    <label onClick={ev => ev.stopPropagation()}>
                      <Checkbox
                        disabled={formState.isSubmitting}
                        checked={field.value}
                        {...field}
                      />
                    </label>
                  )}
                />
              </div>
              <div>
                Memberships are not allowed to be shared. Your membership will be canceled
                if it is being used by anyone else but you.
              </div>
            </div>

            <div className="mt-4">
              If you ever believe you're improperly charged due to these policies, please reach
              out to our team. We're happy to help and reimburse you if wrongfully charged.
            </div>

            <OnSubmitButton
              control={control}
              formState={formState}
            >
              Accept
            </OnSubmitButton>
          </form>


          <div
            className="mt-4"
          >
            {'By clicking Accept or using Chipper Golf, you agree to our '}
            <a
              className="text-green-600 hover:text-gray-700 whitespace-nowrap"
              href="https://chippergolf.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Use
            </a>
            {' and '}
            <a
              className="text-green-600 hover:text-gray-700 whitespace-nowrap"
              href="https://chippergolf.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            {', including, but not limited to, the policies shown above.'}
          </div>
        </div>
      </div>
    </Page>
  );
}
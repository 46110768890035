import React, { useState, useCallback, useEffect, useRef } from 'react';
import Page from '../../components/Page';
import PickerOptionItem from '../../components/PickerOptionItem';
import LoadingBar from '../../components/LoadingBar';
import { OptionGroup, OptionGroupItem } from '../../components/OptionGroup';
import ContentContainer from './common/ContentContainer';
import Content from './common/Content';
import { CHECK_IN_TERMS } from '../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';


export default function CheckInBaseSelector({ swr, title, onSubmit, optionTitle, optionDescription }) {
  const [selectedEntity, setSelectedEntity] = useState('');

  const submittedRef = useRef(false);
  const handleSubmit = useCallback((entity) => {
    submittedRef.current = true;
    setSelectedEntity(entity);
    setTimeout(() => onSubmit(entity), 200);
  }, [onSubmit]);

  useEffect(() => {
    if (!submittedRef.current && swr.data?.length === 1) {
      submittedRef.current = true;
      onSubmit(swr.data[0]);
    }
  }, [onSubmit, swr.data]);

  return (
    <Page>
      <ContentContainer>
        <Content>
          <div className="text-center">
            <div className="text-2xl font-extrabold select-none">{title}</div>
            <div className="text-gray-400 select-none">{CHECK_IN_TERMS}</div>
          </div>
          {!swr.error && !swr.data && (
            <LoadingBar delay={1000} />
          )}
          {swr.error && (
            <div className="error">{swr.error.message || swr.error}</div>
          )}
          {swr.data && (
            <OptionGroup vertical className="shadow-md bg-white">
              {swr.data.map((entity) => (
                <OptionGroupItem
                  key={entity.uid}
                  selected={selectedEntity && entity.uid === selectedEntity.uid}
                  onClick={!selectedEntity ? () => handleSubmit(entity) : undefined}
                >
                  <PickerOptionItem
                    title={optionTitle(entity)}
                    description={optionDescription ? optionDescription(entity) : undefined}
                    icon={<FontAwesomeIcon icon={faChevronRight} className="mr-2" />}
                  />
                </OptionGroupItem>
              ))}
            </OptionGroup>
          )}
        </Content>
      </ContentContainer>
    </Page>
  );
}
import React from 'react';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';
import GolfBall from './GolfBall';

const BOX_SHADOW = '0 0 9px 0 #aab7c4';

const Container = styled.div`
  height: 24px;
  position: relative;
`;

const Layer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;

const ContentLayer = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Bar = styled.div`
  background-color: #F1F1F1;
  box-sizing: content-box;
  border: 1px solid #FFFFFF;
  box-shadow: ${BOX_SHADOW};
  flex: 1;
  border-radius: 6px;
  display: flex;
  justify-items: flex-start;
  overflow: hidden;
`;

const CompletedBar = styled(animated.div)`
  height: 6px;
  background-color: var(--color-golf-green);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`;

const StyledGolfBall = styled(GolfBall)`
  box-shadow: ${BOX_SHADOW};
`;


export default function ProgressBar({ progress, ...props }) {
  const springProps = useSpring({ flex: progress });

  return (
    <Container {...props}>
      <Layer>
        <ContentLayer>
          <Bar>
            <CompletedBar style={springProps} />
          </Bar>
        </ContentLayer>
      </Layer>
      <Layer>
        <ContentLayer>
          <StyledGolfBall />
        </ContentLayer>
      </Layer>
    </Container>
  );
};
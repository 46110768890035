import React, { useMemo } from 'react';
import { formatCurrency, formatPlanIntervalFraction } from '../lib/formatting';
import ItemList from '../components/ItemList';
import InfoListItem from '../components/InfoListItem';

import {
  getPriceAmount,
  getPriceInterval,
  getPriceIntervalCount,
  getPriceCurrency,
} from '../lib/helpers';


function getAccessDescription(subscription) {
  const { paused, pause_at_period_end, cancel_at_period_end } = subscription;

  if (paused) {
    return 'Subscription paused';
  } else if (pause_at_period_end) {
    return `Pausing on ${(new Date(subscription.current_period_end)).toLocaleDateString()}`;
  } else if (cancel_at_period_end) {
    return `Canceling on ${(new Date(subscription.current_period_end)).toLocaleDateString()}`;
  } else {
    return `Next Bill Date: ${(new Date(subscription.current_period_end)).toLocaleDateString()}`;
  }
}


export default function MyPlan({ user, subscription, onChangeSubscription, onOpenSubscriptionAddon, manageSubscriptionHref }) {
  const {
    accessSubtotal,
    taxes,
    total,
    addonAmounts,
    accessDescription,
    coupon
  } = useMemo(() => {
    let total = getPriceAmount(subscription.Access);
    
    const addonAmounts = (subscription.SubscriptionAddon || []).map((subscriptionAddon) => {
      const addonPriceAmount = getPriceAmount(subscriptionAddon.Addon);
      total += subscriptionAddon.cancel_at_period_end ? 0 : addonPriceAmount;
      return {
        amount: formatCurrency(addonPriceAmount / 100),
        frequency: formatPlanIntervalFraction(getPriceInterval(subscriptionAddon.Addon), getPriceIntervalCount(subscriptionAddon.Addon), true),
      };
    });
    let taxes;
    if (user.TaxRate && user.TaxRate.combined_rate) {
      taxes = total * user.TaxRate.combined_rate;
      total += taxes;
    }
    const currency = getPriceCurrency(subscription.Access);
    return {
      accessSubtotal: formatCurrency(getPriceAmount(subscription.Access) / 100),
      total: formatCurrency(total / 100, currency),
      taxes: formatCurrency(taxes / 100, currency),
      coupon: subscription.coupon_amount > 0 ? formatCurrency(-subscription.coupon_amount / 100, currency) : null,
      addonAmounts,
      accessDescription: getAccessDescription(subscription)
    };
  }, [user, subscription]);

  const {
    // paused,
    cancel_at_period_end: canceling
  } = subscription;

  // const unpauseAvailable = subscription.activity?.unpause_enabled;

  return (
    <ItemList>
      <InfoListItem
        title={subscription.Access.name}
        option={canceling ? {
          type: 'text',
          value: 'Canceling'
        } : {
          type: 'button',
          value: 'Change My Plan',
          onClick: () => onChangeSubscription(subscription)
        }}
      >
        <div className="text-sm">{accessDescription}</div>
      </InfoListItem>
      {(subscription.SubscriptionAddon || []).map((subscriptionAddon, idx) => {
        const addonCanceling = subscriptionAddon.cancel_at_period_end;
        return (
          <InfoListItem
            key={subscriptionAddon.uid}
            title={subscriptionAddon.Addon.title}
            option={canceling ? null : {
              type: 'button',
              value: addonCanceling ? 'Reactivate' : 'Remove',
              onClick: () => onOpenSubscriptionAddon({ subscription, subscriptionAddon })
            }}
          >
            <div className="text-sm">
              <div>{`${addonAmounts[idx].amount}/${addonAmounts[idx].frequency}${subscriptionAddon.cancel_at_period_end ? ` (Canceling on ${(new Date(subscription.current_period_end)).toLocaleDateString()})` : ''}`}</div>
            </div>
          </InfoListItem>
        );
      })}
      <InfoListItem
        title="Monthly Bill"
        option={{
          type: 'link',
          value: 'Manage Plan',
          href: manageSubscriptionHref
        }}
      >
        <div className="text-sm">
          <table>
            <tbody>
              <tr>
                <td className="pr-4">Membership</td>
                <td className="text-right">{accessSubtotal}</td>
              </tr>
              {(subscription.SubscriptionAddon || []).filter((addon) => !addon.cancel_at_period_end).map((subscriptionAddon, idx) => (
                <tr key={subscriptionAddon.uid}>
                  <td className="pr-4 leading-none">{subscriptionAddon.Addon.title}</td>
                  <td className="text-right">{addonAmounts[idx].amount}</td>
                </tr>
              ))}
              {coupon && (
                <tr>
                  <td className="pr-4">Coupon</td>
                  <td className="text-right">{coupon}</td>
                </tr>
              )}
              <tr>
                <td className="pr-4">Taxes</td>
                <td className="text-right">{taxes}</td>
              </tr>
              <tr>
                <td className="pr-4"><b>Total</b></td>
                <td className="text-right"><b>{total}</b></td>
              </tr>
            </tbody>
          </table>
        </div>
      </InfoListItem>
    </ItemList>
  );
}

import React, { useCallback, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import Page from '../../components/Page';
import Modal from '../../components/Modal';
import * as API from '../../lib/api';
import useHeader from '../../hooks/useHeader';
import useRequests from '../../hooks/useRequests';
import useConfirmation from '../../hooks/useConfirmation';
import useSubscriptionSWR from '../../hooks/useSubscriptionSWR';
import * as AccountActions from '../../actions/account';
import { APP_URLS } from '../../constants';
import useMeSWR from 'hooks/useMeSWR';

import ConfirmCancel from './Confirmations/Cancel';
import ConfirmUncancel from './Confirmations/Uncancel';


export default function ManageSubscription() {
  const { uid } = useParams();

  useHeader(`subscriptions/${uid}`, {
    title: 'Manage Subscription',
    action: {
      isBack: true,
      url: APP_URLS.ACCOUNT
    }
  });

  const subscriptionSWR = useSubscriptionSWR(uid);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClose = useCallback(() => {
    navigate(APP_URLS.ACCOUNT, { replace: true });
  }, [navigate]);

  const confirmation = useConfirmation();

  const canPause = useMemo(() => {
    return subscriptionSWR.data?.paused !== true && subscriptionSWR.data?.pause_at_period_end !== true && subscriptionSWR.data?.activity?.pause_enabled;
  }, [subscriptionSWR]);

  const canCancel = useMemo(() => {
    return subscriptionSWR.data?.cancel_at_period_end === false || subscriptionSWR.data?.paused === true;
  }, [subscriptionSWR]);

  const canUncancel = useMemo(() => {
    return subscriptionSWR.data?.cancel_at_period_end;
  }, [subscriptionSWR]);

  const meSWR = useMeSWR();


  const { useRequest, loading } = useRequests({
    onError: ({ error }) => {
      toast.error(error.message);
    },
    onSuccess: ({ context }) => {
      subscriptionSWR.mutate();
      confirmation.hide();
      if (context?.successMessage) {
        toast.success(context.successMessage);
      }
    }
  });

  const onCancelRequest = useRequest({
    loadingTitle: 'Canceling your subscription',
    context: { successMessage: 'Your subscription has been canceled' },
    fn: () => API.cancelSubscription(uid),
    onSuccess: () => {
      meSWR.mutate();
      dispatch(AccountActions.showCancelationSurvey({ subscription: subscriptionSWR.data }));
      navigate(APP_URLS.CANCELATION_SURVEY, { replace: true });
    }
  });


  const onUncancelRequest = useRequest({
    loadingTitle: 'Uncanceling your subscription',
    context: { successMessage: 'Your subscription has been uncanceled' },
    fn: () => API.uncancelSubscription(uid),
    onSuccess: ({ onSuccess }) => {
      meSWR.mutate();
      onSuccess();
    }
  });

  useEffect(() => {
    if (subscriptionSWR.data?.active === false) {
      onClose();
    }
  }, [subscriptionSWR, onClose]);

  if (subscriptionSWR.data?.active === false) {
    return null;
  }

  return (
    <Page>
      <div className="px-5">
        {canCancel && canPause && (
          <div>
            <div className="text-2xl font-medium">Pause or Cancel Your Membership</div>
            <div>There’s two ways to end your membership - you can pause for the offseason or cancel your membership.</div>
            <div className="h-0.5 bg-gray-200 my-6"></div>
            
          </div>
        )}
        {canPause && (
          <div>
            <div className="text-lg font-semibold">Pause For the Offseason</div>
            <div>Pausing for the offseason is a risk free way to lock in your spot and make sure you get the best deal next season.</div>
            <ul className="list-disc list-inside mt-2">
              <li>End your monthly dues</li>
              <li>Carry over remaining days to next season</li>
              <li>Lock in your spot</li>
              <li>Cancel if you change your mind</li>
            </ul>
            <Link
              className="btn-contained-primary inline-block mt-4 min-w-full sm:min-w-0 text-center"
              to={APP_URLS.END_SEASON}
            >
              Learn More
            </Link>
            <div className="h-0.5 bg-gray-200 my-6"></div>
          </div>
        )}
        {canCancel && (
          <div>
            <div className="text-lg font-semibold">Cancel Your Membership</div>
            <div>You’re free to cancel your membership and rejoin at anytime. However, we cannot guarantee access in the future if our plans have reached capacity. New plan pricing and policies may apply.</div>
            <button
              className="btn-outlined-primary inline-block mt-4 min-w-full sm:min-w-0"
              onClick={() => confirmation.open('cancel', subscriptionSWR.data)}
            >
              Cancel Membership
            </button>

          </div>
        )}
        {canUncancel && (
          <div>
            <div className="flex justify-center">
              <div className="grid gap-6 justify-items-center max-w-screen-sm">
                <div>
                  <div className="font-bold mb-2">
                    Want to undo your cancel?
                  </div>
                  <div>
                    You may undo your cancel before the end of your current billing period
                  </div>
                </div>
                <button
                  className="btn-contained-primary"
                  onClick={() => confirmation.open('uncancel', subscriptionSWR.data)}
                >
                  Undo Cancelation
                </button>
              </div>
            </div>
            <div className="bg-gray-200 my-10 w-full h-0.5"></div>
          </div>
        )}
        <Modal
          isOpen={confirmation.active}
          onRequestClose={confirmation.hide}
        >
          <>
            {confirmation.type === 'cancel' && (
              <ConfirmCancel
                subscription={subscriptionSWR.data}
                loading={loading}
                onClose={confirmation.hide}
                onSubmit={onCancelRequest}
              />
            )}
            {confirmation.type === 'uncancel' && (
              <ConfirmUncancel
                subscription={subscriptionSWR.data}
                loading={loading}
                onClose={confirmation.hide}
                onPause={() => confirmation.open('pause', subscriptionSWR.data)}
                onSubmit={onUncancelRequest}
              />
            )}
          </>
        </Modal>
      </div>
    </Page>
  );
}
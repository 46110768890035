import React, { useMemo } from 'react';
import AspectRatio from './AspectRatio';
import { formatPhoneNumber } from '../lib/formatting';
import { ActivityTypeRefs } from '../constants';


export default function SiteActivityCard({ siteActivity, address }) {
  const primaryInfo = useMemo(() => {
    const items = [];
    if (address?.city && address?.state) {
      items.push(`${address.city}, ${address.state}`);
    }

    if (siteActivity.ActivityType.ref === ActivityTypeRefs.Golf) {
      items.push(`${siteActivity.config.hole_count} holes`);
    }

    return items.join(' • ');
  }, [siteActivity, address]);

  const secondaryInfo = useMemo(() => {
    const items = [];
    const reservationInstructions = siteActivity.reservation_instructions;
    if (reservationInstructions) {
      items.push(reservationInstructions);
    }

    if (siteActivity.ActivityType.ref === ActivityTypeRefs.Golf) {
      const { allows_walking, allows_golf_cart } = siteActivity.config;
      if (allows_walking && !allows_golf_cart) {
        items.push('Walking Only');
      } else if (!allows_walking && allows_golf_cart) {
        items.push('Golf Cart Required');
      }
    }

    return items.join(' • ');
  }, [siteActivity]);

  const phoneNumber = siteActivity.phone_number;

  const formattedPhoneNumber = useMemo(() => {
    return formatPhoneNumber(phoneNumber);
  }, [phoneNumber]);

  return (
    <div className="shadow rounded overflow-hidden">
      <AspectRatio
        className="bg-cover bg-no-repeat bg-center"
        ratio={42}
        style={{
          backgroundImage: `url(${siteActivity.profile_image || 'https://ik.imagekit.io/chippergolf/core/chippergolf2018.jpg'})`,
          backgroundPosition: !siteActivity.profile_image ? 'center bottom' : null
        }}
      >
        {siteActivity.special_notice && (
          <div className="relative h-full">
            <div className="font-bold text-sm bg-white w-3/4 rounded-br py-1 px-2">
              {siteActivity.special_notice}
            </div>
          </div>
        )}
      </AspectRatio>
      <div className="py-2 px-4 bg-white">
        <div className="font-bold text-xl">{siteActivity.name}</div>
        <div className="text-gray-700 text-sm">{primaryInfo}</div>
        {secondaryInfo && (
          <div className="text-gray-700 text-sm">{secondaryInfo}</div>
        )}
        {siteActivity.reservation_via_phone && formattedPhoneNumber && (
          <a
            href={`tel:${phoneNumber}`}
            className="btn-contained-primary text-sm inline-flex items-center mt-2"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="fill-current w-4 h-4 mr-2" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="0" strokeLinecap="round" strokeLinejoin="round"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
            <span>{formattedPhoneNumber}</span>
          </a>
        )}
        {siteActivity.general_notice && (
          <div className="text-gray-400 text-sm mt-2 whitespace-pre-wrap">
            {siteActivity.general_notice}
          </div>
        )}
      </div>
    </div>
  );
}

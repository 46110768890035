export default function BaseContainer({ title, children }) {
  return (
    <div>
      <div className="text-xl font-black mb-1">
        {title}
      </div>
      <div className="grid gap-4">
        {children}
      </div>
    </div>
  );
}
import styled from 'styled-components';


const Base = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgb(240, 240, 240);
  background:
    radial-gradient(circle farthest-corner at 100% 0px, rgb(254, 254, 254), rgb(230, 230, 230));
    
  background-position: 100% 0;
  overflow: hidden;
  box-sizing: border-box;
  outline:
`;

const Circles = styled.div`
  transform: scale(1.1) rotate(5deg);
  height: 100%;
  width: 100%;
`;

export default function GolfBall(props) {
  const size = '100%';
  const partSize = '12.5%';
  return (
    <Base {...props}>
      <Circles>
        <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient id="Gradient1" x1="0%" x2="0%" y1="0%" y2="100%">
              <stop offset="5%" stopColor="rgb(245, 245, 245)" />
              <stop offset="95%" stopColor="rgb(245, 245, 245)" />
            </linearGradient>
            <linearGradient id="Gradient2" x1="0%" x2="0%" y1="0%" y2="100%">
              <stop offset="5%" stopColor="rgb(240, 240, 240)" />
              <stop offset="95%" stopColor="rgb(250, 250, 250)" />
            </linearGradient>
            <linearGradient id="Gradient3" x1="0%" x2="0%" y1="0%" y2="100%">
              <stop offset="5%" stopColor="rgb(235, 235, 235)" />
              <stop offset="95%" stopColor="rgb(250, 250, 250)" />
            </linearGradient>
            <linearGradient id="Gradient4" x1="0%" x2="0%" y1="0%" y2="100%">
              <stop offset="5%" stopColor="rgb(235, 235, 235)" />
              <stop offset="95%" stopColor="rgb(250, 250, 250)" />
            </linearGradient>
            <linearGradient id="Gradient5" x1="0%" x2="0%" y1="0%" y2="100%">
              <stop offset="5%" stopColor="rgb(230, 230, 230)" />
              <stop offset="95%" stopColor="rgb(250, 250, 250)" />
            </linearGradient>
            <linearGradient id="Gradient6" x1="0%" x2="0%" y1="0%" y2="100%">
              <stop offset="5%" stopColor="rgb(225, 225, 225)" />
              <stop offset="95%" stopColor="rgb(250, 250, 250)" />
            </linearGradient>
            <linearGradient id="Gradient7" x1="0%" x2="0%" y1="0%" y2="100%">
              <stop offset="5%" stopColor="rgb(220, 220, 220)" />
              <stop offset="95%" stopColor="rgb(250, 250, 250)" />
            </linearGradient>
            <linearGradient id="Gradient8" x1="0%" x2="0%" y1="0%" y2="100%">
              <stop offset="5%" stopColor="rgb(215, 215, 215)" />
              <stop offset="95%" stopColor="rgb(250, 250, 250)" />
            </linearGradient>
            <pattern id="Pattern1" x="0" y="0" width=".125" height="1">
              <circle cx="5%" cy="5%" r="5%" fill="url(#Gradient1)" fillOpacity="1" />
            </pattern>
            <pattern id="Pattern2" x="0" y="0" width=".125" height="1">
              <circle cx="5%" cy="5%" r="5%" fill="url(#Gradient2)" fillOpacity="1" />
            </pattern>
            <pattern id="Pattern3" x="0" y="0" width=".125" height="1">
              <circle cx="5%" cy="5%" r="5%" fill="url(#Gradient3)" fillOpacity="1" />
            </pattern>
            <pattern id="Pattern4" x="0" y="0" width=".125" height="1">
              <circle cx="5%" cy="5%" r="5%" fill="url(#Gradient4)" fillOpacity="1" />
            </pattern>
            <pattern id="Pattern5" x="0" y="0" width=".125" height="1">
              <circle cx="5%" cy="5%" r="5%" fill="url(#Gradient5)" fillOpacity="1" />
            </pattern>
            <pattern id="Pattern6" x="0" y="0" width=".125" height="1">
              <circle cx="5%" cy="5%" r="5%" fill="url(#Gradient6)" fillOpacity="1" />
            </pattern>
            <pattern id="Pattern7" x="0" y="0" width=".125" height="1">
              <circle cx="5%" cy="5%" r="5%" fill="url(#Gradient7)" fillOpacity="1" />
            </pattern>
            <pattern id="Pattern8" x="0" y="0" width=".125" height="1">
              <circle cx="5%" cy="5%" r="5%" fill="url(#Gradient8)" fillOpacity="1" />
            </pattern>
          </defs>
          <rect fill="url(#Pattern1)" width={size} height={partSize} />
          <rect fill="url(#Pattern2)" width={size} height={partSize} y="12.5%" x="5%" />
          <rect fill="url(#Pattern3)" width={size} height={partSize} y="25%" x="10%" />
          <rect fill="url(#Pattern4)" width={size} height={partSize} y="37.5%" x="2%" />
          <rect fill="url(#Pattern5)" width={size} height={partSize} y="50%" x="7%" />
          <rect fill="url(#Pattern6)" width={size} height={partSize} y="62.5%" x="1%" />
          <rect fill="url(#Pattern7)" width={size} height={partSize} y="75%" x="6%" />
          <rect fill="url(#Pattern8)" width={size} height={partSize} y="87.5%" x="0%" />
        </svg>
      </Circles>
    </Base>
  );
}
import { useState, useMemo, useCallback, useEffect } from 'react';
import useHeader from '../../hooks/useHeader';
import { APP_URLS } from '../../constants';
import Page from '../../components/Page';
import YourPlans from './YourPlans';
import Reactivate from './Reactivate';
import { useNavigate } from 'react-router-dom';
import { subscriptionCanBeUnpaused } from '../../lib/subscription';
import useMySubscriptionsSWR from 'hooks/useMySubscriptionsSWR';
import useMeSWR from 'hooks/useMeSWR';


export default function Reactivation() {
  const subscriptionsSWR = useMySubscriptionsSWR();
  const meSWR = useMeSWR();

  const subscriptions = useMemo(() => {
    if (subscriptionsSWR.data) {
      return subscriptionsSWR.data.filter(subscriptionCanBeUnpaused);
    }
    return [];
  }, [subscriptionsSWR.data]);

  const navigate = useNavigate();

  useEffect(() => {
    if (subscriptions.length === 0) {
      navigate(APP_URLS.COURSES, { replace: true });
    }
  }, [subscriptions.length, navigate]);

  const [selectedSubscriptionUID, setSelectedSubscriptionUID] = useState(() => subscriptions.length === 1 ? subscriptions[0].uid : null);

  const selectedSubscription = useMemo(() => {
    if (selectedSubscriptionUID) {
      return subscriptions.find((subscription) => subscription.uid === selectedSubscriptionUID);
    }
    return null;
  }, [subscriptions, selectedSubscriptionUID]);

  const onDismiss = useCallback(() => {
    if (subscriptions.length === 1) {
      navigate(APP_URLS.COURSES, { replace: true });
    }
    else {
      setSelectedSubscriptionUID(null);
    }
  }, [subscriptions, navigate]);

  useHeader('reactivation/yourplans', {
    title: 'Reactivate',
    action: {
      isBack: true,
      url: !selectedSubscription ? APP_URLS.COURSES : undefined
    }
  });

  if (!meSWR.data) {
    return null;
  }

  return (
    <Page className="px-4">
      {!selectedSubscription && (
        <YourPlans
          user={meSWR.data}
          subscriptions={subscriptions}
          onReactivateSubscription={(subscription) => setSelectedSubscriptionUID(subscription.uid)}
        />
      )}
      {selectedSubscription && (
        <Reactivate
          user={meSWR.data}
          subscription={selectedSubscription}
          onDismiss={onDismiss}
        />
      )}
    </Page>
  );
}
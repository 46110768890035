import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { useMedia } from 'react-use';
import MainLayout from '../components/MainLayout';
import Logo from '../components/Logo';
import { APP_URLS } from '../constants';
import TextInput from '../components/Inputs/Text';
import PasswordInput from '../components/Inputs/Password';
import useTransaction from 'hooks/useTransaction';
import * as Auth from '../lib/auth';
import { usePrevious } from 'react-use';

function FieldError({ error }) {
  if (!error) {
    return null;
  }

  return (
    <div className="error">{error.message}</div>
  );
}

function TrxErrorReset({ control, trx }) {
  const values = useWatch({ control });

  const previousError = usePrevious(trx.error);

  useEffect(() => {
    if (previousError && trx.error) {
      trx.reset();
    }
  }, [values, trx, previousError]);

  return null;
}

export default function Login() {
  const trx = useTransaction();

  const onSubmit = useCallback(async (values) => {
    trx.begin();
    // console.log(values);
    // await new Promise((res) => setTimeout(res, 2000));
    // trx.end();
    try {
      await Auth.login(values);
      trx.end();
    } catch (err) {
      trx.end(err);
    }
  }, [trx]);

  const { handleSubmit, register, formState: { errors }, control } = useForm();
  const isWide = useMedia('(min-width: 600px)');

  return (
    <MainLayout
      Header={{ className: "justify-between" }}
      card
      header={(
        <>
          <Logo />
          <div className="flex flex-row items-center">
            {isWide && (<div className="text-lg font-bold mx-4">Don't have an account yet?</div>)}
            <Link
              to={APP_URLS.SIGNUP}
              className="btn-contained-primary"
            >
              Sign Up
            </Link>
          </div>
        </>
      )}
      title={(
        <div className="text-3xl font-extrabold">Log In</div>
      )}
      content={(
        <form
          className="grid gap-4"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <TrxErrorReset control={control} trx={trx} />
          <div>
            <label>Email</label>
            <TextInput
              {...register("email", {
                required: 'Email is required',
              })}
              type="email"
              autoFocus={true}
              disabled={trx.loading}
            />
            <FieldError error={errors.email} />
          </div>

          <div>
            <label>Password</label>
            <PasswordInput
              {...register("password", {
                required: 'Password is required',
              })}
              disabled={trx.loading}
            />
            <FieldError error={errors.password} />
          </div>

          <button
            type="submit"
            className={trx.loading ? "btn-disabled" : "btn-contained-primary"}
            disabled={trx.loading}
          >
            {trx.loading ? 'Loggin In' : 'Log In'}
          </button>

          {trx.error && (
            <div className="error">{trx.error.message}</div>
          )}

          <div className="flex justify-center">
            <Link
              className="btn-link-primary"
              to={APP_URLS.RESET_PASSWORD}
            >
              Forgot Password
            </Link>
          </div>
        </form>
      )}
    />
  );
}

import styled from 'styled-components';

export default styled.div`
  position: relative;

  &::after {
    display: block;
    content: '';
    padding-bottom: ${props => props.ratio}%;
  }

  /* Image is positioned absolutely relative to the parent element */
  & > * {
    /* Image should match parent box size */
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;
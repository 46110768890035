import clsx from 'clsx';

export default function PickerOptionItem({ title, description, icon, disabled }) {
  return (
    <div className={clsx("flex p-3", disabled && "bg-gray-200 cursor-default")}>
      <div className="flex flex-col items-start flex-1">
        <div className={clsx("text-lg", disabled && "text-gray-400")}>{title}</div>
        <div className="text-gray-500">{description}</div>
      </div>
      {icon && (
        <div className={clsx("flex flex-col justify-center", disabled && "text-gray-400")}>
          {icon}
        </div>
      )}
    </div>
  );
}
import {
  put,
  call,
  fork,
  takeLatest
} from 'redux-saga/effects';

import * as SubscribeActions from '../actions/subscribe';
import * as API from '../lib/api';
import { APP_URLS } from '../constants';
import history from '../history';


function* handlePreload(action) {
  const { access: accessUID } = action.payload || {};
  if (accessUID) {
    try {
      const access = yield call(API.getAccess, accessUID);
      yield put(SubscribeActions.setAccess(access));
    } catch (err) {
      console.log(err);
    }
  }
  // const navigate = yield call(getNavigate);
  // yield call(navigate, `${APP_URLS.SUBSCRIBE}${window.location.search}${window.location.hash}`, { replace: true });
  yield call(history.replace, `${APP_URLS.SUBSCRIBE}${window.location.search}${window.location.hash}`);
  yield put(SubscribeActions.preloadFinished());
}


function* watchPreload() {
  yield takeLatest(SubscribeActions.PRELOAD, handlePreload);
}


const sagas = [
  fork(watchPreload)
];


export default sagas;
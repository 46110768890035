import clsx from 'clsx';
import ItemList from './ItemList';

function BasicList({ title, children, className, ...props }) {
  return (
    <div className={clsx("select-none", className)} {...props}>
      {title && (
        <div className="text-xl font-medium">{title}</div>
      )}
      <ItemList>
        {children}
      </ItemList>
    </div>
  );
}

function BasicListItem({ title, children, className, ...props }) {
  return (
    <div className={clsx("bg-white py-2 px-3.5", className)} {...props}>
      <div className="text-lg font-medium mb-1">{title}</div>
      <div>{children}</div>
    </div>
  );
}

export { BasicList, BasicListItem };
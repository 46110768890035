import ContentContainer from './common/ContentContainer';
import Content from './common/Content';
import Page from '../../components/Page';
import Ticket from './Ticket';
import { ActivityTypeRefs } from '../../constants';
import useConfirmation from '../../hooks/useConfirmation';
import Modal from '../../components/Modal';
import AddGolfCartConfirmation from './AddGolfCartConfirmation';


export default function Complete({ checkIn }) {
  const date = new Date();

  const canAddGolfCart = (
    checkIn
    && checkIn.Service.Access.Activity.ActivityType.ref === ActivityTypeRefs.Golf
    && checkIn.options?.golf_cart === false
    && date > new Date(checkIn.active_start) && date < new Date(checkIn.active_end)
  );

  const confirmation = useConfirmation();

  if (!checkIn) {
    console.log('CHECKIN COMPLETE: checkin is null');
    return null;
  }

  return (
    <Page>
      <ContentContainer>
        <Content>
          <div className="text-center">
            <div className="text-2xl font-extrabold select-none">Ready To Golf!</div>
          </div>

          <div className="grid gap-6 justify-items-center">
            <Ticket checkIn={checkIn} />
          </div>

          {canAddGolfCart && (
            <>
              <div className="h-0.5 w-full bg-gray-200 my-2"></div>
              <div>
                <div className="font-bold">Add Golf Cart</div>
                <div>Do you want to add a golf cart? Once added it cannot be removed.</div>
              </div>
              <button
                className="btn btn-contained-primary"
                onClick={() => confirmation.open('add_golf_cart', checkIn)}
              >
                Add Golf Cart
              </button>
            </>
          )}
        </Content>
      </ContentContainer>
      <Modal
        isOpen={confirmation.active}
        onRequestClose={confirmation.hide}
      >
        {confirmation.type === 'add_golf_cart' && (
          <AddGolfCartConfirmation checkIn={checkIn} onDismiss={confirmation.hide} />
        )}
      </Modal>
    </Page>
  );
}
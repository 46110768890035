import * as Confirmation from '../../../components/Confirmation';

export default function ConfirmUncancel({ subscription, onSubmit, onClose, onPause, loading }) {
  return (
    <Confirmation.Container>
      <Confirmation.Header>Reactivate My Subscription</Confirmation.Header>
      <div>Start playing with Chipper today. By reactivating your subscription you authorize Chipper to continue to charge your payment method with the current billing cycle.</div>

      {!loading.show && (
        <>
          <button
            className="btn-contained-primary"
            onClick={onSubmit}
          >
            Reactivate
          </button>
          {/* {subscription.activity?.pause_enabled && (
            <button
              className="btn-outlined-primary"
              onClick={onPause}
            >
              Pause Subscription Now For Season
            </button>
          )} */}
          <button
            className="btn-link-primary"
            onClick={onClose}
          >
            Keep Cancelation
          </button>
        </>
      )}
      <Confirmation.Loading loading={loading} />
    </Confirmation.Container>
  );
}
import { useCallback, useRef } from 'react';
import useSWR from 'swr';
import * as API from '../../lib/api';
import { formatCurrency } from '../../lib/formatting';
import useTransaction from '../../hooks/useTransaction';
import { delay } from '../../lib/helpers';
import { APP_URLS } from '../../constants';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { toast } from 'react-hot-toast';
import useLastCheckInSWR from 'hooks/useLastCheckInSWR';
import { mixpanelTrack } from '../../integrations/mixpanel';

export default function ConfirmAddGolfCart({ checkIn, onDismiss }) {
  const random = useRef(Date.now());

  const previewState = useSWR(
    ['/checkins/add_golf_cart', checkIn.uid, random],
    () => API.checkInAddGolfCart(checkIn.uid, {
      preview: true
    }),
  );

  const trx = useTransaction();
  const lastCheckInSWR = useLastCheckInSWR();

  const onSubmit = useCallback(async ({ fee_total }) => {
    trx.begin();
    try {
      await API.checkInAddGolfCart(checkIn.uid, {
        preview: false,
        fee_total,
      });

      mixpanelTrack('checkin.type_action.golf.add_golf_cart', {
        checkIn,
      });

      trx.end();
    } catch (err) {
      trx.end(err);
      return;
    }

    try {
      await lastCheckInSWR.mutate();
    } catch (err) {
      console.log(err);
    }

    toast.success('Cart successfully added');

    await delay(400);

    onDismiss();
  }, [trx, onDismiss, checkIn, lastCheckInSWR]);

  const buttonsDisabled = trx.loading || trx.complete;

  const invoice = previewState.data?.invoice;

  return (
    <div className="grid gap-4">
      <div className="font-bold text-xl text-center">Add Golf Cart</div>
      <div>
        Are you sure you want to add a cart?
        Once added it cannot be removed.
      </div>

      {!previewState.data && previewState.isValidating && !previewState.error && (
        <div>Loading preview...</div>
      )}
      {invoice && (
        <>
          {invoice.total > 0 && (
            <div>{`You'll be charged ${formatCurrency(invoice.total / 100, invoice.currency)}`}</div>
          )}
          
          <button
            className={clsx("btn", !buttonsDisabled ? "btn-contained-primary" : "btn-disabled")}
            disabled={buttonsDisabled}
            onClick={() => onSubmit({ fee_total: invoice.total })}
          >
            {buttonsDisabled ? `Adding Cart` : `Add Cart${invoice.total > 0 ? ` | ${formatCurrency(invoice.total / 100, invoice.currency)}` : ''}`}
          </button>
        </>
      )}
      <button
        className={clsx("btn", !buttonsDisabled ? "btn-link-primary" : "btn-link-disabled")}
        onClick={onDismiss}
        disabled={buttonsDisabled}
      >
        Don't Add Cart
      </button>
      {trx.error && (
        <>
          <div className="text-red-500 text-sm font-bold">{trx.error.message}</div>
          <div>
            <Link to={APP_URLS.ACCOUNT_SETTINGS} className="btn btn-contained-primary">
              Update payment method
            </Link>
          </div>
        </>
      )}
    </div>
  );
}
import React from 'react';
import styled from 'styled-components';
import useWebShare from 'react-use-web-share';
import Page from '../components/Page';
import { IsIosDevice } from '../constants';
import * as API from '../lib/api';
import { getIKSmartSizeImageURL } from '../lib/helpers';
import useSWR from 'swr';
import useAuth0 from 'hooks/useAuth0';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareNodes, faArrowUpFromBracket } from '@fortawesome/free-solid-svg-icons';

const StyledPage = styled(Page)`
  padding-left: 146px;
  padding-right: 146px;

  @media(max-width: 640px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const imageWidth = 800;
const imageHeight = 312;
const imageURL = getIKSmartSizeImageURL("https://ik.imagekit.io/chippergolf/core/GolfIsBetterTogether.png", imageWidth, imageHeight);

export default function UserReferral() {
  const { isAuthenticated } = useAuth0();
  const userReferralSWR = useSWR(isAuthenticated ? '/userreferrals?user=me' : null, async () => {
    return await API.listUserReferrals();
  });

  const referralCode = userReferralSWR.data?.[0]?.PromotionCode?.uid;
  
  const { isSupported, share } = useWebShare();

  return (
    <div className="flex flex-1 flex-col">
      <StyledPage>
        <div className="flex justify-center">
          <div className="max-w-[800px]">
            <img
              className="w-full h-auto"
              src={imageURL}
              alt="Referral"
              width={imageWidth}
              height={imageHeight}
            />

            <div className="text-center min-h-[100px]">
              <div className="my-2">My Referral Code:</div>
              {isSupported && (
                <button
                  className="btn-contained-primary font-mono text-3xl font-bold my-4 inline-flex items-center"
                  onClick={() => share({
                    title: 'Join Chipper Golf',
                    text: `Join Chipper Golf with my referral code ${referralCode}`,
                    url: `${window.location.origin}/share?promotion=${referralCode}`
                  })}
                >
                  {IsIosDevice ? <FontAwesomeIcon icon={faArrowUpFromBracket} /> : <FontAwesomeIcon icon={faShareNodes} />}
                  <span className="ml-5 select-text">{referralCode}</span>
                </button>
              )}
              {!isSupported && (
                <div className="font-mono text-3xl font-bold my-4 text-gray-600 select-text">
                  {referralCode}
                </div>
              )}
            </div>

            <div className="whitespace-pre-wrap mb-4">
              <strong>Get $10 off your next month</strong><br />
              <>Refer a friend to Chipper Golf and we’ll give you a $10 discount. Your friend will also save $10 when they join Chipper Golf.</>
            </div>
            <div className="whitespace-pre-wrap">
              <strong>How it works</strong><br />
              1. Tell your friends about Chipper Golf<br />
              2. They use your code while signing up<br />
              3. You both get a $10 discount<br />
              4. Refer more friends to save more!
            </div>
          </div>
        </div>
      </StyledPage>
    </div>
  );
}
import { useMemo, useEffect } from "react";
import useMeSWR from './useMeSWR';
import { mixpanelTrack } from '../integrations/mixpanel';

export const ONBOARDING_STAGES = {
  SUBSCRIBE: 'subscribe',
  ONE_LAST_THING: 'one_last_thing',
};

let lastOnboardingStage;

export default function useOnboardingStage() {
  const meSWR = useMeSWR();

  const onboardingStage = useMemo(() => {
    if (!meSWR.data) {
      return null;
    }
    return meSWR.data.onboarding_stage;
    // if (!meSWR.data.payment_method && meSWR.data.membership_picture_deadline == null) {
    //   return ONBOARDING_STAGES.SUBSCRIBE;
    // }
    // if (!meSWR.data.phone_number || !meSWR.data.pin) {
    //   return ONBOARDING_STAGES.ONE_LAST_THING;
    // }
    // return null;
  }, [meSWR.data]);

  useEffect(() => {
    if (onboardingStage !== lastOnboardingStage) {
      lastOnboardingStage = onboardingStage;
      mixpanelTrack(`onboarding_stage.${onboardingStage}`);
    }
  }, [onboardingStage]);

  return onboardingStage;
}
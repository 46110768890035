import styled from 'styled-components';
import MuiBottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';
import { MOBILE_BREAKPOINT } from '../constants';

const Container = styled.nav`
  position: relative;
  bottom: 0px;

  @supports (margin-bottom: env(safe-area-inset-bottom)) {
    margin-bottom:env(safe-area-inset-bottom);
  }
`;

const TabBarContainer = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;

  box-shadow: 0 0 #0000,
              0 0 #0000,
              // 0 -1px 3px 0 rgba(0, 0, 0, 0.1),
              0 -1px 2px 0 rgba(0, 0, 0, 0.06);

  @supports (bottom: env(safe-area-inset-bottom)) and (height:env(safe-area-inset-bottom)) {
    bottom: env(safe-area-inset-bottom);

    &::after {
      background-color: var(--color-background);
      content: '';
      height: env(safe-area-inset-bottom);
      left: 0;
      position: absolute;
      right: 0;
      top: 56px;
    }
  }
`;

export default function BottomNavigation({ tabs }) {
  const isMobile = useMedia(MOBILE_BREAKPOINT);

  const navigate = useNavigate();
  const location = useLocation();

  const href = location.pathname;

  if (!isMobile) return null;

  return (
    <Container>
      <div className="relative flex flex-col h-14 z-50">
        <TabBarContainer>
          <MuiBottomNavigation
            value={href}
            onChange={(event, newValue) => {
              navigate(newValue);
            }}
            showLabels
          >
            {tabs.map(tab => (
              <BottomNavigationAction
                key={tab.href}
                className="focus:outline-none"
                classes={{
                  label: "whitespace-nowrap"
                }}
                label={tab.tabTitle || tab.menuTitle || tab.title}
                value={tab.href}
                icon={tab.icon}
              />
            ))}
          </MuiBottomNavigation>
        </TabBarContainer>
      </div>
    </Container>
  );
}
import FullPageIFrame from '../components/FullPageIFrame';

export default function AddUnlimitedCart() {
  return (
    <FullPageIFrame
      title="Unlimited Cart"
      src="https://www.chippergolf.com/add-unlimited-cart"
      showGoBackButton={true}
    />
  );
}
import { useMemo } from 'react';
import useSWR from 'swr';
import clsx from 'clsx';
import UserImage from '../components/UserImage';
import Page from '../components/Page';
import CheckInCard from '../components/CheckInCard';
import { FullMonthNames } from '../lib/helpers';
import * as API from '../lib/api';
import useLatestCheckIns from 'hooks/useLatestCheckIns';
import useMeSWR from 'hooks/useMeSWR';

function ValueOrWordBreak(val) {
  if (!!val) {
    return val;
  } else {
    return (
      <span className='animate-pulse whitespace-pre bg-gray-200 rounded-full'>      </span>
    );
  }
}

const PreloadCheckInList = new Array(2).fill(null);

export default function MySeason() {
  const meSWR = useMeSWR();

  const memberSince = useMemo(() => {
    if (!meSWR.data) return '';
    const date = new Date(meSWR.data?.createdAt);
    return `Member since ${FullMonthNames[date.getMonth()]} ${date.getFullYear()}`;
  }, [meSWR.data]);

  const mySeasonStatsSWR = useSWR('/users/me/myseasonstats', API.userGetMySeasonStats);

  const checkInsSWR = useLatestCheckIns();

  if (!meSWR.data) {
    return null;
  }

  return (
    <Page>
      <div className="px-4 flex justify-center">
        <div className="max-w-lg flex-1">
          <div className="flex justify-center pt-2 pb-6">
            <div className="flex items-center">
              {meSWR.data?.profile_image && (
                <UserImage
                  className="mr-4"
                  size={100}
                  style={{ borderWidth: '4px' }}
                  src={meSWR.data?.profile_image}
                />
              )}
              <div className={clsx(!meSWR.data?.profile_image && "text-center")}>
                <div className="text-lg font-bold">{meSWR.data?.full_name}</div>
                <div>{memberSince}</div>
              </div>
            </div>
          </div>

          {mySeasonStatsSWR.data?.total_checkins !== 0 && (
            <div className="border-t-2 border-b-2 flex text-center font-bold">
              <div className="flex-1 py-2 border-r-2">
                <div className="text-2xl">{ValueOrWordBreak(mySeasonStatsSWR.data?.total_checkins)}</div>
                <div>{`Round${mySeasonStatsSWR.data?.total_checkins !== 1 ? 's' : ''}`}</div>
              </div>
              <div className="flex-1 py-2 border-r-2">
                <div className="text-2xl">{ValueOrWordBreak(mySeasonStatsSWR.data?.total_holes)}</div>
                <div>Holes</div>
              </div>
              <div className="flex-1 py-2">
                <div className="text-2xl">{ValueOrWordBreak(mySeasonStatsSWR.data?.total_sites)}</div>
                <div>{`Course${mySeasonStatsSWR.data?.total_sites !== 1 ? 's' : ''}`}</div>
              </div>
            </div>
          )}
          
          {mySeasonStatsSWR.data?.total_checkins === 0 && (
            <div className="text-center text-gray-500 mt-20">
              It looks like you haven’t golfed with Chipper yet. Check back after golfing to see stats about your season.
            </div>
          )}

          <div className="flex justify-center">
            <div
              className="flex-1 mt-6 grid gap-6"
            // style={{ maxWidth: '400px' }}
            >
              {(checkInsSWR.data || PreloadCheckInList).map((checkIn, idx) => (
                <CheckInCard
                  key={checkIn?.uid || idx}
                  checkIn={checkIn}
                  // checkIn={idx % 2 === 0 ? checkIn : null}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}
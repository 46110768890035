import FullPageIFrame from '../components/FullPageIFrame';

export default function Support() {
  return (
    <FullPageIFrame
      title="Contact Support"
      src="https://www.chippergolf.com/support"
      showGoBackButton={true}
    />
  );
}

import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useAuth0 from './useAuth0';
import * as AppActions from '../actions/app';
import { APP_URLS } from '../constants';
import { mixpanelTrack } from '../integrations/mixpanel';

export default function useLogout() {
  const dispatch = useDispatch();
  const auth0 = useAuth0();
  const navigate = useNavigate();

  const logout = useCallback(() => {
    auth0.logout();
    mixpanelTrack('user.logout');
    dispatch(AppActions.reset());
    navigate(APP_URLS.LOGIN, { replace: true });
    dispatch(AppActions.initialize());
  }, [dispatch, auth0, navigate]);

  return logout;
}
import React, { useCallback, useEffect } from 'react';
import { useForm, useWatch, Controller } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Page from '../components/Page';
import { validateEmail, validatePin, validatePhoneNumber, validateZipCode } from '../lib/validation';
import { APP_URLS } from '../constants';
import useHeader from '../hooks/useHeader';
import PhoneNumberInput, { PhoneNumberInputTransform } from '../components/Inputs/PhoneNumber';
import PinInput from '../components/Inputs/Pin';
import ZipCodeInput from '../components/Inputs/ZipCode';
import PaymentMethodInput from '../components/Inputs/Payment';
import TextInput from '../components/Inputs/Text';
import PaymentMethod from '../components/PaymentMethod';
import * as API from '../lib/api';
import useMeSWR from 'hooks/useMeSWR';
import { usePrevious } from 'react-use';

function OnSubmitButton({ control, children, updating, data }) {
  let { email, phone_number, pin, zip_code, payment } = useWatch({ control });

  phone_number = PhoneNumberInputTransform(phone_number);

  if (!data) {
    return null;
  }

  const hasChange = (
    data.email !== email
    || data.phone_number !== phone_number
    || data.pin !== pin
    || data.zip_code !== zip_code
    || !!payment
  );

  const allowUpdate = hasChange && (
    validateEmail(email)
    && validatePin(pin)
    && validatePhoneNumber(phone_number)
    && validateZipCode(zip_code)
  );

  // console.log(hasChange, validatePin(pin));

  return (
    <button
      className={!allowUpdate || updating ? "btn-disabled" + (updating ? " animate-pulse" : "") : "btn-contained-primary"}
      disabled={!allowUpdate || updating}
      type="submit"
    >
      {children}
    </button>
  );
}


export default function Settings() {
  const meSWR = useMeSWR();

  const onSubmit = useCallback(async ({ payment, ...values }) => {
    const updateObj = {};

    function updater(key, current, next) {
      if (current !== next) updateObj[key] = next;
    }

    updater('email', meSWR.data.email, values.email);
    updater('phone_number', meSWR.data.phone_number, PhoneNumberInputTransform(values.phone_number));
    updater('pin', meSWR.data.pin, values.pin);
    updater('zip_code', meSWR.data.pin, values.zip_code);

    if (payment && payment.getSource) {
      const sourceRequest = await payment.getSource();
      if (sourceRequest.error) {
        toast.error(sourceRequest.error.message);
      } else {
        updateObj.payment_source = sourceRequest.source.id;
      }
    }

    try {
      const updatedUser = await API.updateUser(updateObj);
      meSWR.mutate(updatedUser);
      toast.success('Settings saved successfully');
    } catch (err) {
      toast.error(err.message);
    }
  }, [meSWR]);

  const { handleSubmit, control, register, formState, reset } = useForm({
    defaultValues: meSWR.data
  });

  const previousData = usePrevious(meSWR.data);

  useEffect(() => {
    if (!previousData && meSWR.data) {
      reset(meSWR.data);
    }
  }, [previousData, meSWR.data, reset]);

  const updating = formState.isSubmitting;

  const navigate = useNavigate();

  useHeader('Settings', {
    action: {
      isBack: true,
      fn: () => navigate(APP_URLS.ACCOUNT)
    }
  });

  if (!meSWR.data) {
    return null;
  }

  return (
    <div className="flex flex-1 flex-col">
      <Page>
        <div className="px-3">
          <div className="flex justify-center">
            <div className="flex-1 max-w-lg">
              <form
                className="flex-1 grid gap-4"
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
              >
                <div>
                  <label>Email</label>
                  <TextInput
                    {...register("email")}
                    type="email"
                    disabled={updating}
                  />
                </div>
                
                <div>
                  <label>Phone Number</label>
                  <Controller
                    name="phone_number"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <PhoneNumberInput {...field} disabled={updating} />
                    )}
                  />
                </div>
                <div>
                  <label>Pin</label>
                  <Controller
                    name="pin"
                    control={control}
                    render={({ field }) => (
                      <PinInput {...field} disabled={updating} />
                    )}
                  />
                </div>
                <div>
                  <label>Billing Zip Code</label>
                  <Controller
                    name="zip_code"
                    control={control}
                    render={({ field }) => (
                      <ZipCodeInput {...field} disabled={updating} />
                    )}
                  />
                </div>
                <div>
                  <label>Payment Method</label>
                  <PaymentMethod payment={meSWR.data?.payment_method} className="pb-1" />
                  <Controller
                    name="payment"
                    control={control}
                    render={({ field }) => {
                      return (
                        <PaymentMethodInput
                          hidePostalCode={true}
                          current={meSWR.data?.payment_method} // keep this here for now, when this changes, the input field clears
                          disabled={updating}
                          {...field}
                          onChange={ev => field.onChange(ev.getSource ? ev : undefined)}
                        />
                      );
                    }}
                  />
                </div>
                
                <OnSubmitButton control={control} updating={updating} data={meSWR.data}>
                  {updating ? 'Saving' : 'Save'}
                </OnSubmitButton>
              </form>
            </div>
          </div>
        </div>
      </Page>
    </div>
  );
}

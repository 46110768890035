import { combineReducers } from 'redux';
import app from './app';
import account from './account';
import subscribe from './subscribe';
import { RESET } from '../actions/app';
import { mixpanelTrack } from '../integrations/mixpanel';

const allReducers = combineReducers({
  app,
  account,
  subscribe
});

export default function rootReducer(state, action) {
  if (action.type === RESET) {
    state = undefined;
  }

  if (action.type.indexOf('@@redux/') !== 0) {
    mixpanelTrack(`redux.${action.type}`, action.payload);
  }

  return allReducers(state, action);
}
import React, { useState } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import Page from '../../../components/Page';
import ContentContainer from '../common/ContentContainer';
import Content from '../common/Content';
import { BasicList, BasicListItem } from '../../../components/BasicList';
import Checkbox from '../../../components/Checkbox';
import GolfConfigurator from './Configurators/Golf';
import GolfSimulatorConfigurator from './Configurators/GolfSimulator';
import { ActivityTypeRefs } from '../../../constants';
import { formatCurrency } from '../../../lib/formatting';


function Invoice({ invoice, disabled }) {
  if (!invoice || !Array.isArray(invoice.items) || invoice.items.length === 0) {
    return null;
  }

  return (
    <BasicList title="Round Total">
      {invoice.items.map((item) => (
        <BasicListItem key={item.uid} title={item.title} className={clsx(disabled === true && "text-gray-300")}>
          {`${formatCurrency(item.amount / 100)}`}
        </BasicListItem>
      ))}
      <BasicListItem title="Total" className={clsx(disabled === true && "text-gray-300")}>
        {`${formatCurrency(invoice.total / 100)} - includes tax`}
      </BasicListItem>
    </BasicList>
  );
}


function Actions({ items }) {
  if (!Array.isArray(items) || items.length === 0) {
    return null;
  }

  return (
    <BasicList title="Actions">
      {items.map((action) => (
        <BasicListItem key={action.uid} title={action.title}>
          {action.description}
        </BasicListItem>
      ))}
    </BasicList>
  );
}


function ActivityTypeConfiguration({ state, ...props }) {
  switch (state.site_activity.ActivityType.ref) {
    case ActivityTypeRefs.Golf:
      return (<GolfConfigurator state={state} {...props} />);
    case ActivityTypeRefs.GolfSimulator:
      return (<GolfSimulatorConfigurator state={state} {...props} />);
    default:
      return null;
  }
}


export default function Preview({ state, onSetOptions, onSubmit, onCancel, previewSWR, createCheckInState }) {
  const [confirmed, setConfirmed] = useState(false);

  // const disableCheckIn = !confirmed || !checkIn.preview || previewLoading;
  const disableCheckIn = !confirmed || !previewSWR.data || previewSWR.isValidating || createCheckInState.loading;

  return (
    <Page>
      <ContentContainer>
        <Content>
          <div className="text-center">
            <div className="text-3xl font-extrabold">Confirm<wbr /> & Check In</div>
            <div className="text-xl font-bold text-gray-500">
              {`${state.site_activity.ActivityType.name} @ ${state.site.name}`}
            </div>
          </div>

          <button
            className="btn-link-primary flex items-center justify-center"
            onClick={onCancel}
          >
            {/* <ChevronLeft /> */}
            <FontAwesomeIcon className='min-w-[24px]' icon={faChevronLeft} />
            <span>
              Change Course
            </span>
          </button>

          <ActivityTypeConfiguration
            state={state}
            previewSWR={previewSWR}
            onSetOptions={onSetOptions}
          />

          <Actions items={previewSWR.data?.actions} disabled={previewSWR.isValidating} />
          <Invoice invoice={previewSWR.data?.invoice} disabled={previewSWR.isValidating} />

          <div className="rounded shadow bg-white">
            <div className="flex items-center justify-center p-3">
              <div className="mr-3 text-center flex-1">I confirm I am at the course and ready to golf now</div>
              <div className="px-2">
                <label>
                  <Checkbox
                    checked={confirmed}
                    onChange={() => setConfirmed(!confirmed)}
                  />
                </label>
              </div>
            </div>
          </div>

          {previewSWR.error && (
            <div className="error text-base text-center mt-4">{previewSWR.error.message}</div>
          )}

          {!previewSWR.error && (
            <button
              className={clsx("btn", disableCheckIn ? "btn-disabled" : "btn-contained-primary")}
              disabled={disableCheckIn}
              type="submit"
              onClick={disableCheckIn ? null : onSubmit}
            >
              <span>{!previewSWR.data && !previewSWR.isValidating ? "Please Choose Your Options" : "Confirm & Play Now"}</span>
              {/* <div className="absolute right-2 h-full flex flex-col justify-center">
                <Fade in={previewSWR.isValidating} unmountOnExit>
                  <CircularProgress className="text-white" size={24} />
                </Fade>
              </div> */}
            </button>
          )}
          <button className="btn-link-primary" onClick={onCancel}>
            Cancel Check In
          </button>
        </Content>
      </ContentContainer>
    </Page>
  );
}
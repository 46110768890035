import styled from 'styled-components';
import BackgroundCSS from './BackgroundCSS';
import { useMedia } from 'react-use';

const BREAKPOINT = '(min-width:600px)';

const DarkLine = styled.div`
  flex: none;
  height: 2px;
  background-color: #F1F1F1;
`;

const LightLine = styled.div`
  flex: none;
  height: 2px;
  background-color: #FFFFFF;
`;

function BaseDividerLine({ className }) {
  return (
    <>
      <DarkLine className={className} />
      <LightLine className={className} />
    </>
  );
}

const BaseRootContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0px;
  height: 100%;

  @media ${BREAKPOINT} {
    padding: 0px 24px;
  }
`;

const BaseHeaderContainer = styled.header`
  display: flex;
  flex: none;
  height: 60px;
  flex-direction: row;
  align-items: center;
  padding: 0px 24px;

  @media ${BREAKPOINT} {
    padding: 0px;
  }
`;

const BaseContainer = styled.div`
  flex: 1;
  overflow-y: auto;

  ${props => props.card ? BackgroundCSS : null}
`;

const BaseContentContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 60px 0px;
`;

const BaseContent = styled.div`
  padding: 0px 20px;

  @media ${BREAKPOINT} {
    padding: 20px;
  }
`;

const BaseTitleContainer = styled.div`
  padding-left: 28px;
  padding-right: 28px;
  padding-bottom: 28px;
  text-align: center;

  @media ${BREAKPOINT} {
    padding: 30px;
  }
`;

const BaseFullContainer = styled.div`
  overflow-y: auto;

  &:after {
    content: "";
    display: block;
    clear: both;
    padding-bottom: 80px;
  }
`;


export default function MainLayout({
  RootContainer = {},
  Header = {},
  Container = {},
  ContentContainer = {},
  Content = {},
  TitleContainer = {},
  DividerLine = {},
  header, title, content,
  card
}) {
  const matches = useMedia(BREAKPOINT);

  return (
    <BaseRootContainer {...RootContainer}>
      {header !== null && (
        <BaseHeaderContainer {...Header}>
          {header}
        </BaseHeaderContainer>
      )}

      {(!card || !matches) && (
        <BaseFullContainer>
          {title && (
            <BaseTitleContainer {...TitleContainer}>
              {title}
            </BaseTitleContainer>
          )}
          {content && (
            <BaseContent {...Content}>
              {content}
            </BaseContent>
          )}
        </BaseFullContainer>
      )}
      {card && matches && (
        <BaseContainer card {...Container}>
          <BaseContentContainer {...ContentContainer}>
            <div
              className="shadow rounded overflow-hidden"
              style={{
                width: '480px',
                backgroundColor: 'var(--color-off-white)'
              }}
            >
              {title && (
                <>
                  <BaseTitleContainer {...TitleContainer}>
                    {title}
                  </BaseTitleContainer>
                  <BaseDividerLine {...DividerLine} />
                </>
              )}
              <BaseContent {...Content}>
                {content}
              </BaseContent>
            </div>
          </BaseContentContainer>
        </BaseContainer>
      )}
    </BaseRootContainer>
  );
}
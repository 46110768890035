import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { useEvent } from 'react-use';
import { useNavigate } from 'react-router-dom';
import FullPageIFrame from '../components/FullPageIFrame';
import useMeSWR from 'hooks/useMeSWR';
import * as AccountActions from '../actions/account';
import { APP_URLS } from '../constants';
import { toQueryString } from '../lib/qs';


export default function CancelationSurvey() {
  const showCancelationSurvey = useSelector((state) => state.account.showCancelationSurvey);
  const meSWR = useMeSWR();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEvent('message', function (ev) {
    if (ev.origin === 'https://www.chippergolf.com' && ev.data === 'complete') {
      dispatch(AccountActions.showCancelationSurvey(false));
      toast.success('Subscription canceled. Thank you for your feedback.');
    }
  }, window);

  useEffect(() => {
    if (!showCancelationSurvey) {
      navigate(APP_URLS.ACCOUNT, { replace: true });
    }

    return () => {
      if (showCancelationSurvey) {
        dispatch(AccountActions.showCancelationSurvey(false));
      }
    };
  }, [navigate, dispatch, showCancelationSurvey]);

  const src = useMemo(() => {
    if (showCancelationSurvey) {
      const subscription = showCancelationSurvey.subscription;
      
      let remainingDays = -1;
      if (subscription && subscription.current_period_end != null) {
        if (subscription.paused) {
          remainingDays = subscription.pause_carryover_time > 0 ? Math.round(subscription.pause_carryover_time / 1000 / 60 / 60 / 24) : 0;
        } else {
          const periodEndDate = new Date(subscription.current_period_end);
          if (!isNaN(periodEndDate)) {
            const date = new Date();
            remainingDays = date < periodEndDate ? Math.ceil((periodEndDate - date) / (24 * 60 * 60 * 1000)) : 0;
          }
        }
      }
      // if (subscription && subscription.current_period_end != null) {
      //   const periodEndDate = new Date(subscription.current_period_end);
      //   if (!isNaN(periodEndDate)) {
      //     const date = new Date();
      //     remainingDays = date < periodEndDate ? Math.ceil((periodEndDate - date) / (24 * 60 * 60 * 1000)) : 0;
      //   }
      // }

      const query = toQueryString({
        SQF_PASSED_EMAIL: meSWR.data?.email,
        SQF_PASSED_MEMBERSHIP: subscription?.access?.name,
        SQF_REMAINING_DAYS: remainingDays
      });
      return `https://www.chippergolf.com/cancelation-survey/${query}`;
    }
  }, [showCancelationSurvey, meSWR.data?.email]);

  if (!showCancelationSurvey) {
    return null;
  }

  return (
    <FullPageIFrame
      title="Feedback"
      src={src}
    />
  );
}

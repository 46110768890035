import { useReducer } from 'react';

const initialState = {
  loading: false,
  complete: false,
  error: null
};

function reducer(state, { type, payload }) {
  switch (type) {
    case 'reset':
      return initialState;
    case 'begin':
      return { loading: true, complete: false, error: null };
    case 'error':
      return { loading: false, complete: false, error: payload };
    case 'complete':
      return { loading: false, complete: true, error: null };
    default:
      return state;
  }
}

export default function useTransaction() {
  const [{ loading, complete, error }, dispatch] = useReducer(reducer, initialState);
  return {
    begin: () => dispatch({ type: 'begin' }),
    end: (err) => {
      dispatch({ type: err ? 'error' : 'complete', payload: err });
    },
    reset: () => dispatch({ type: 'reset' }),
    loading,
    complete,
    error
  };
}
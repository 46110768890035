import { useEffect, useReducer, useMemo } from 'react';
import useSWR from 'swr';
import * as API from '../../lib/api';
import { useInterval, useAsyncFn } from 'react-use';
import useLastCheckInSWR from 'hooks/useLastCheckInSWR';
import { mixpanelTrack } from '../../integrations/mixpanel';

export const STAGES = {
  INITIALIZATION: 'initialization',
  SITE_SELECTOR: 'site_selector',
  SITE_ACTIVITY_SELECTOR: 'site_activity_selector',
  ACTIVITY_CONFIGURATION: 'activity_configuration',
  COMPLETE: 'complete',
  BLOCKED: 'blocked',
};

const initialState = {
  stage: STAGES.INITIALIZATION,
  site: null,
  activity_type: null,
  options: null,
  configured: false,
  blocked: null,
  ticket: null,
  error: null,
};

function init(state) {
  return {
    ...initialState,
    ...state,
  };
}

function reducer(state, { type, payload, ...args }) {
  if (Object.keys(args).length > 0) {
    throw new Error(`Only type and payload are allowed. Received: ${Object.keys(args).join(', ')}`);
  }

  mixpanelTrack(`checkin.action.${type}`, payload);

  switch (type) {
    case 'stage':
      return { ...state, stage: payload };
    case 'reset':
      return { ...initialState, ...payload };
    case 'site':
      return { ...state, site: payload, options: null };
    // case 'user':
    //   return { ...state, user: payload };
    case 'site_activity':
      return { ...state, site_activity: payload, options: null };
    case 'options':
      return { ...state, options: payload };
    case 'complete':
      return { ...initialState, stage: STAGES.COMPLETE, checkIn: payload };
    case 'blocked':
      return { ...state, blocked: payload };
    case 'error':
      return { ...state, error: payload };
    default:
      throw new Error(`Invalid action type: ${type}`);
    // return state;
  }
}

function isCheckedIn(checkIn, date) {
  if (!checkIn) {
    return false;
  }
  date = date ? date : new Date();
  date = date.getTime();
  const activeStart = new Date(checkIn.active_start).getTime();
  const activeEnd = new Date(checkIn.active_end).getTime();
  return date >= activeStart && date <= activeEnd;
}

export default function useLogic(inputInitialState) {
  const [state, dispatch] = useReducer(reducer, inputInitialState, init);

  const lastCheckInSWR = useLastCheckInSWR();

  // function checkIfCheckedIn(checkIn) {
  //   if (checkIn) {
  //     if (!isCheckedIn(checkIn)) {
  //       // @ts-ignore
  //       dispatch({ type: 'reset' });
  //     }
  //   }
  // }

  const onReset = () => {
    dispatch({ type: 'reset' });
  };

  const setStage = (stage) => {
    dispatch({ type: 'stage', payload: stage });
  };

  useEffect(() => {
    /** Reset if checkin is canceled by admin */
    if (state.stage === STAGES.COMPLETE) {
      if (!lastCheckInSWR.data || !isCheckedIn(lastCheckInSWR.data)) {
        onReset();
      }
    }
  }, [lastCheckInSWR.data, state.stage]);

  useInterval(() => {
    const checkIn = lastCheckInSWR.data;
    if (checkIn) {
      if (!isCheckedIn(checkIn)) {
        onReset();
      }
    }
  }, state.stage === STAGES.COMPLETE ? 5000 : null);

  const sitesSWR = useSWR('/sites?user=me', () => API.listSites({ user: 'me' }));

  useEffect(() => {
    if (state.stage === STAGES.INITIALIZATION) {
      if (!lastCheckInSWR.data && lastCheckInSWR.isValidating) {
        /** Dont continue unless the last successful checkin is known */
        return;
      }

      if (isCheckedIn(lastCheckInSWR.data)) {
        setStage(STAGES.COMPLETE);
        return;
      }

      if (sitesSWR.data) {
        setStage(STAGES.SITE_SELECTOR);
        return;
      }
    }
  }, [state.stage, sitesSWR.data, lastCheckInSWR.data, lastCheckInSWR.isValidating]);

  const siteActivitiesSWR = useSWR(state.site ? `/siteactivities?site=${state.site.uid}&user=me` : null, async () => {
    return await API.listSiteActivities({ site: state.site.uid, user: 'me' });
  });

  useEffect(() => {
    if (state.stage === STAGES.SITE_SELECTOR && state.site && !state.site_activity && siteActivitiesSWR.data) {
      if (siteActivitiesSWR.data.length === 1) {
        // @ts-ignore
        dispatch({ type: 'site_activity', payload: siteActivitiesSWR.data[0] });
      } else if (siteActivitiesSWR.data.length > 1) {
        setStage(STAGES.SITE_ACTIVITY_SELECTOR);
      } else {
        // @ts-ignore
        dispatch({ type: 'error', payload: new Error('No activities available at site') });
      }
    }
  }, [siteActivitiesSWR.data, state.site, state.site_activity, state.stage]);

  useEffect(() => {
    if (state.stage !== STAGES.ACTIVITY_CONFIGURATION && state.site_activity && !state.options) {
      setStage(STAGES.ACTIVITY_CONFIGURATION);
    }
  }, [state.options, state.site_activity, state.stage]);

  const previewKey = useMemo(() => {
    if (state.site_activity?.uid && state.options) {
      const params = Object.keys(state.options || {}).sort().map((key) => `${key}=${state.options[key]}`).join('&');
      return `/checkin/golf?site_activity=${state.site_activity.uid}&${params}`;
    }
    return null;
  }, [state.site_activity, state.options]);

  const previewSWR = useSWR(previewKey, async () => {
    return await API.createCheckIn({
      preview: true,
      site_activity: state.site_activity.uid,
      options: state.options,
    });
  });

  const [createCheckInState, onCreateCheckIn] = useAsyncFn(async () => {
    const res = await API.createCheckIn({
      preview: false,
      site_activity: state.site_activity.uid,
      options: state.options,
    });
    lastCheckInSWR.mutate(res);
    // @ts-ignore
    dispatch({ type: 'complete', payload: res });
  }, [state?.site_activity?.uid, state.options]);

  return {
    state,
    stage: state.stage,
    sitesSWR,
    siteActivitiesSWR,
    previewSWR,
    // checkIn: lastCheckInSWR.data,
    lastCheckInSWR,
    createCheckInState,
    onReset,
    onSelectSite: (data) => dispatch({ type: 'site', payload: data }),
    onSelectSiteActivity: (data) => dispatch({ type: 'site_activity', payload: data }),
    onSetOptions: (data) => dispatch({ type: 'options', payload: data }),
    onCreateCheckIn,
  };
}

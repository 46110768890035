import * as API from '../lib/api';
import useSWR from 'swr';
import useAuth0 from './useAuth0';

export default function useLatestCheckIns() {
  const { isAuthenticated } = useAuth0();
  return useSWR(
    isAuthenticated ? '/checkins?user=me' : null,
    () => API.listCheckIns({ user: 'me' }),
  );
}

import useSWR from 'swr';
import * as API from '../lib/api';
import { useDispatch } from 'react-redux';
import * as AccountActions from '../actions/account';
import useAuth0 from './useAuth0';
import { mixpanel } from '../integrations/mixpanel';
import { Sentry } from '../integrations/sentry';

export default function useMeSWR() {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth0();
  return useSWR(isAuthenticated ? '/users/me' : null, async () => {
    const user = await API.readUser();
    try {
      mixpanel.identify(user.uid);
    } catch (err) {
      Sentry.captureException(err);
    }
    return user;
  }, {
    onSuccess: (data) => {
      dispatch(AccountActions.userInfoResult(data));
    }
  });
}

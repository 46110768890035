
import { useDispatch } from 'react-redux';
import useSWR from 'swr';
import BaseSelector from './BaseSelector';
import PickerOptionItem from '../../components/PickerOptionItem';
import * as API from '../../lib/api';
import * as SubscribeActions from '../../actions/subscribe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';


export default function NetworkSelector({ state }) {
  const dispatch = useDispatch();

  const { data, error } = useSWR('/networks', API.listNetworks);

  return (
    <BaseSelector
      title="Networks"
      data={data}
      error={error}
      onSubmit={(entity) => dispatch(SubscribeActions.setNetwork(entity))}
      renderOption={({ name, site_count }) => (
        <PickerOptionItem
          title={name}
          description={`${site_count && site_count > 0 ? `${site_count} ${site_count === 1 ? 'location' : 'locations'}` : ''}`}
          icon={<FontAwesomeIcon icon={faChevronRight} className="mr-2" />}
        />
      )}
    />
  );
}
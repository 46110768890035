import { useState, useEffect } from 'react';
import Modal from './Modal';
import UserImage from './UserImage';


export default function ImagePreview({ title, file, open, onSubmit, submitTitle = 'Save', onCancel, loading, error }) {
  const [src, setSrc] = useState();

  useEffect(() => {
    if (file) {
      const objectURL = URL.createObjectURL(file);
      setSrc(objectURL);
      return () => {
        URL.revokeObjectURL(objectURL);
      };
    }
  }, [file]);

  return (
    <Modal
      onRequestClose={onCancel}
      isOpen={open}
    >
      <div
        className='grid gap-4 justify-items-center p-5'
        style={{ minWidth: '200px' }}
      >
        {title && (
          <div className='text-xl py-0 select-none font-semibold'>
            {title}
          </div>
        )}
        <UserImage src={src} transform={false} size={148} />
        <button
          className={loading ? 'btn-disabled w-full' : 'btn-contained-primary w-full'}
          onClick={onSubmit}
          disabled={loading}
        >
          {loading ? 'Uploading' : submitTitle}
        </button>
        <button
          className='btn-outlined-primary w-full'
          onClick={onCancel}
        >
          Cancel
        </button>
        {error && (
          <div className='text-red-500 text-sm font-bold'>
            {error.message || error || 'Unexpected error'}
          </div>
        )}
      </div>
    </Modal>
  );
}

import React, { useMemo } from 'react';
import { Link  } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import Branding from '../components/Branding';
import MainLayout from '../components/MainLayout';
import ProgressBar from '../components/ProgressBar';
import { validateEmail, validatePassword } from '../lib/validation';
import { APP_URLS } from '../constants';
import TextInput from '../components/Inputs/Text';
import PasswordInput from '../components/Inputs/Password';
import useSignup from 'hooks/useSignup';

function OnSubmitButton({ control, children, loading }) {
  const { email, password } = useWatch({ control });

  const valid = useMemo(() => {
    return validateEmail(email) && validatePassword(password);
  }, [email, password]);

  const disabled = !valid || loading;

  return (
    <button
      type="submit"
      className={disabled ? "btn-disabled" : "btn-contained-primary"}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export default function Signup() {
  const signup = useSignup();

  const { handleSubmit, control, register } = useForm();

  return (
    <MainLayout
      Header={{ className: "justify-center" }}
      card
      header={(
        <Branding logo={true} name={true} />
      )}
      title={(
        <>
          <div className="text-3xl font-extrabold mb-2">Create Account</div>
          <ProgressBar progress={0.25} />
        </>
      )}
      content={(
        <form
          className="grid gap-4"
          onSubmit={handleSubmit(signup.onSubmit)}
          noValidate
        >
          <div>
            <label>Email</label>
            <TextInput
              {...register("email")}
              type="email"
              autoFocus={true}
              placeholder="your@email.com"
            // disabled={updating}
            />
          </div>

          <div>
            <label>Password</label>
            <PasswordInput
              {...register("password")}
              placeholder="password"
            // disabled={updating}
            />
          </div>
          <OnSubmitButton control={control} loading={signup.loading}>
            Create Account
          </OnSubmitButton>
          {signup.error && (
            <div className="error">{signup.error.message}</div>
          )}
          <div className="whitespace-pre flex justify-center">
            {'Already have an account? '}
            <Link
              className="text-gray-600 hover:text-gray-700"
              to={APP_URLS.LOGIN}
            >
              Login
            </Link>
          </div>
          <div className="flex justify-center text-sm whitespace-pre">
            {'By continuing you agree to the Chipper '}
            <a
              className="text-gray-600 hover:text-gray-700"
              href="https://chippergolf.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </div>
        </form>
      )}
    />
  );
}
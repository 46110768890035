import React, { useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Routes, Route, useLocation, Navigate, useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import * as AppActions from '../actions/app';

import Login from './Login';
import Settings from './Settings';
import Signup from './Signup';
import ResetPassword from './ResetPassword';

import WaitList from './WaitList';
import ErrorPage from './ErrorPage';
import Support from './Support';
import Resubscribe from './Resubscribe';
import InstallApp from './InstallApp';
import ManageMyPlan from './ManageMyPlan';
import AddUnlimitedCart from './AddUnlimitedCart';
import SnackbarNotification from './SnackbarNotification';
import MembershipPictureRequirement from './MembershipPictureRequirement';
import CheckIn from './CheckIn';
import ReferralProgram from './ReferralProgram';
import MyAccount from './MyAccount';
import LearnPause from './LearnPauseIFrame';
import Reactivation from './Reactivation';
import MySites from './MySites';
import MySeason from './MySeason';
import EndSeason from './EndSeason';
// import FOWUpdate from './IFrameFOWUpdate';
import ManageSubscription from './Subscriptions/Manage';

import Header from './Header';
import BottomNavigation from '../components/BottomNavigation';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faGift, faCircleUser, faLocationDot } from '@fortawesome/free-solid-svg-icons';

import CancelationSurvey from './CancelationSurvey';
import Branding from '../components/Branding';
import Logo from '../components/Logo';

import Subscribe from './Subscribe';
import Onboarding from './Onboarding';

import { APP_URLS } from '../constants';
import useAuth0 from '../hooks/useAuth0';
import usePreloads from 'hooks/usePreloads';
import useMeSWR from 'hooks/useMeSWR';
import useOnboardingStage from 'hooks/useOnboardingStage';

const ROUTES = [
  {
    title: 'My Courses',
    mobileTitle: <Branding className="pr-6" logo={true} name={true} />,
    header: true,
    tabTitle: 'Courses',
    href: APP_URLS.COURSES,
    component: <MySites />,
    icon: <FontAwesomeIcon icon={faLocationDot} size="lg" />
  },
  {
    title: 'My Season',
    header: true,
    tabTitle: 'Season',
    href: APP_URLS.MY_SEASON,
    component: <MySeason />,
    icon: <FontAwesomeIcon icon={faCircleUser} size="lg" />
  },
  // {
  //   title: 'Golf Simulators',
  //   href: APP_URLS.GOLF_SIMULATOR_PROMOTION,
  //   component: React.lazy(() => import('./IFrameGolfSimulatorPromotion')),
  //   tabTitle: 'New!',
  //   icon: <NewReleasesIcon />,
  //   header: ({ user }) => {
  //     if (!user || !user.subscriptions) return false;
  //     for (const subscription of user.subscriptions) {
  //       if (subscription.activity_type.ref === ActivityTypeRefs.GolfSimulator) {
  //         return false;
  //       }
  //     }
  //     return true;
  //   }
  // },
  {
    title: 'Check In & Golf Now',
    menuTitle: 'Check In & Golf',
    header: true,
    tabTitle: 'Golf Now',
    href: APP_URLS.CHECKIN,
    // component: CheckIn,
    component: <CheckIn />,
    icon: <Logo size={24} />
  },
  {
    title: 'Share & Save $10',
    menuTitle: 'Share & Save $10',
    header: true,
    tabTitle: 'Save $10',
    href: APP_URLS.USER_REFERRAL_INFO,
    // component: ReferralProgram,
    component: <ReferralProgram />,
    icon: <FontAwesomeIcon icon={faGift} size="lg" />
  },
  {
    title: 'My Account',
    menuTitle: 'My Account',
    header: true,
    tabTitle: 'Account',
    href: APP_URLS.ACCOUNT,
    component: <MyAccount />,
    icon: <FontAwesomeIcon icon={faGear} size="lg" />
  },
  {
    title: 'My Account Info',
    href: APP_URLS.ACCOUNT_SETTINGS,
    component: <Settings />,
    header: false
  },
  {
    title: 'Support',
    href: APP_URLS.SUPPORT,
    component: <Support />
  },
  {
    title: 'Reactivate',
    href: APP_URLS.RESUBSCRIBE,
    component: <Resubscribe />
  },
  {
    title: 'Install Webapp',
    href: APP_URLS.INSTALL_APP,
    component: <InstallApp />
  },
  {
    title: 'Manage My Plan',
    href: APP_URLS.MANAGE_PLAN,
    component: <ManageMyPlan />
  },
  {
    title: 'Add Unlimited Cart',
    href: APP_URLS.ADD_UNLIMITED_CART,
    component: <AddUnlimitedCart />
  },
  {
    title: 'Cancel Membership',
    href: APP_URLS.CANCELATION_SURVEY,
    component: <CancelationSurvey />
  },
  {
    title: 'Pause Account',
    href: APP_URLS.PAUSE_SUBSCRIPTION_LEARNING,
    component: <LearnPause />,
  },
  {
    title: 'Subscribe',
    href: APP_URLS.SUBSCRIBE,
    component: <Subscribe />
  },
  {
    title: 'Membership Picture',
    href: APP_URLS.MEMBERSHIP_PICTURE_REQUIREMENT,
    component: <MembershipPictureRequirement />
  },
  {
    // title: 'Reactivation',
    href: APP_URLS.REACTIVATION,
    component: <Reactivation />,
  },
  {
    href: APP_URLS.END_SEASON,
    component: <EndSeason />
  },
  {
    href: '/subscriptions/:uid',
    component: <ManageSubscription />
  },
];


export default function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const meSWR = useMeSWR();
  const auth0 = useAuth0();

  usePreloads();

  useEffectOnce(() => {
    dispatch(AppActions.initialize());
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (meSWR.error && auth0.isAuthenticated && [401, 404].indexOf(meSWR.error.code) >= 0) {
      auth0.logout();
      navigate(APP_URLS.SIGNUP, { replace: true });
    }
  }, [auth0, meSWR.error, navigate]);

  const tabs = useMemo(() => {
    return ROUTES.filter((tab) => {
      switch (typeof tab.header) {
        case 'boolean':
          return tab.header === true;
        case 'function':
          return tab.header({ user: meSWR.data });
        default:
          return false;
      }
    });
  }, [meSWR.data]);

  const onboardingStage = useOnboardingStage();

  if (!auth0.isAuthenticated) {
    return (
      <Routes>
        <Route path={APP_URLS.SIGNUP} element={<Signup />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path={APP_URLS.LOGIN} element={<Login />} />

        <Route path='*' element={
          <Navigate replace to={{
            pathname: APP_URLS.LOGIN,
            search: `?return_to=${location.pathname}${location.search}${location.hash}`,
            state: { location: location }
          }} />
        }/>
      </Routes>
    );
  }

  if (meSWR.error) {
    /** This must occur AFTER the unauthenticated pages (but it really shouldn't, it may be a timing issue with isAuthenticated) */
    return (
      <ErrorPage error={meSWR.error} />
    );
  }

  if (meSWR.data?.wait_list === true) {
    return (
      <Routes>
        <Route path="/wait-list" element={<WaitList />} />
        <Route path="*" element={
          <Navigate replace to="/wait-list" />
        } />
      </Routes>
    );
  }

  if (onboardingStage) {
    return (
      <Onboarding />
    );
  }

  return (
    <section className="flex flex-col flex-grow flex-shrink-0 overflow-hidden min-h-full select-none">
      <Header tabs={tabs} routes={ROUTES} logoHref={APP_URLS.COURSES} />
      <main className="flex flex-col flex-grow flex-shrink-0 overflow-hidden">
        <React.Suspense fallback={<div></div>}>
          <Routes>
            {ROUTES.map((route, idx) => (
              <Route key={idx} path={`${route.href}/*`} element={route.component} />
            ))}
            <Route path="*" element={<Navigate replace to={APP_URLS.COURSES} />} />
          </Routes>
        </React.Suspense>
      </main>
      <BottomNavigation tabs={tabs} />
      <SnackbarNotification />
    </section>
  );
}

import { useMemo } from 'react';
import useMeSWR from './useMeSWR';
import useMySubscriptionsSWR from './useMySubscriptionsSWR';

export default function useNeedsUpdatedPaymentMethod() {
  const meSWR = useMeSWR();
  const mySubscriptionsSWR = useMySubscriptionsSWR();

  const paymentMethodCreated = meSWR.data?.payment_method?.created;

  const needsUpdatedPaymentMethod = useMemo(() => {
    let hasFailedPayment = false;
    if (mySubscriptionsSWR.data && paymentMethodCreated) {
      for (const subscription of mySubscriptionsSWR.data) {
        if (['unpaid', 'past_due'].indexOf(subscription.billing_status) >= 0) {
          hasFailedPayment = true;
          break;
        }
      }

      if (hasFailedPayment) {
        const created = new Date(paymentMethodCreated);
        if (!Number.isNaN(created) && Date.now() - created.getTime() < 60000) {
          hasFailedPayment = false;
        }
      }
    }

    return hasFailedPayment;
  }, [mySubscriptionsSWR.data, paymentMethodCreated]);

  return needsUpdatedPaymentMethod;
}
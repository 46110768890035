import * as SubscribeActions from '../actions/subscribe';

const initialState = {
  configuration: {},
  subsription: null
};

export default function subscribe(state = initialState, action) {
  switch (action.type) {
    case SubscribeActions.RESET:
      return initialState;
    case SubscribeActions.SET_SUBSCRIBED:
      return {
        ...state,
        subscribed: action.payload
      };
    case SubscribeActions.SET_NETWORK:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          network: action.payload,
          activity: null,
          access: null,
          addons: null,
          promotion: null,
          confirmed: false
        }
      };
    case SubscribeActions.SET_ACTIVITY:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          activity: action.payload,
          access: null,
          addons: null,
          promotion: null,
          confirmed: false
        }
      };
    case SubscribeActions.SET_ACCESS:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          network: !action.payload ? null : action.payload.Activity.Network,
          activity: !action.payload ? null : action.payload.Activity,
          // activity: !action.payload ? null : {
          //   ...action.payload.Activity,
          //   network: action.payload.network
          // },
          access: action.payload,
          addons: null,
          promotion: null,
          confirmed: false
        }
      };
    case SubscribeActions.SET_PROMOTION_CODE:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          promotion: action.payload,
          confirmed: false
        }
      };
    case SubscribeActions.SET_ADDONS:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          addons: action.payload
        }
      };
    case SubscribeActions.SET_CONFIRMED:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          confirmed: action.payload
        }
      };
    default:
      return state;
  }
}

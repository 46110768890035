import styled from 'styled-components';

export const BORDER_RADIUS = '6px';

const Base = styled.div`
  display: flex;
  user-select: none;
  margin: 1px;
  border-radius: ${props => props.borderRadius || BORDER_RADIUS};
  & > * {
    transition: color .15s, box-shadow .15s;
    margin: 1px;
    cursor: pointer;
    overflow: hidden;
    flex: 1;
  }
  & > .disabled {
    color: #CDCDCD;
    cursor: not-allowed;
  }
  & > .disabled > * {
    pointer-events: none;
  }
  & > .selected {
    color: var(--color-golf-green);
  }
`;


const VerticalContainer = styled(Base)`
  flex-direction: column;
  & > *:first-child {
    border-top-left-radius: ${props => props.borderRadius || BORDER_RADIUS};
    border-top-right-radius: ${props => props.borderRadius || BORDER_RADIUS};
  }
  & > *:last-child {
    border-bottom-left-radius: ${props => props.borderRadius || BORDER_RADIUS};
    border-bottom-right-radius: ${props => props.borderRadius || BORDER_RADIUS};
  }
`;


const HorizontalContainer = styled(Base)`
  & > *:first-child {
    border-top-left-radius: ${props => props.borderRadius || BORDER_RADIUS};
    border-bottom-left-radius: ${props => props.borderRadius || BORDER_RADIUS};
  }
  & > *:last-child {
    border-top-right-radius: ${props => props.borderRadius || BORDER_RADIUS};
    border-bottom-right-radius: ${props => props.borderRadius || BORDER_RADIUS};
  }
`;


export function OptionGroup({ vertical, children, borderRadius, ...props }) {
  const Container = vertical ? VerticalContainer : HorizontalContainer;

  return (
    <Container borderRadius={borderRadius} {...props}>
      {children}
    </Container>
  );
}


const MATRIX_BORDER_RADIUS = '6px';

export const OptionGroupMatrix = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.columns}, 1fr);
  grid-template-rows: repeat(${props => props.rows}, 1fr);
  gap: ${props => props.spacing || '2px'};
  padding: 1px;
  border-radius: ${props => props.borderRadius || MATRIX_BORDER_RADIUS};
  
  & > * {
    transition: color .25s, box-shadow .25s;
    cursor: pointer;
    flex: 1;
  }

  & > *:first-child {
    border-top-left-radius: ${props => props.borderRadius || MATRIX_BORDER_RADIUS};
  }
  & > *:first-child > * {
    border-top-left-radius: ${props => props.borderRadius || MATRIX_BORDER_RADIUS};
  }

  & > *:last-child {
    border-bottom-right-radius: ${props => props.borderRadius || MATRIX_BORDER_RADIUS};
  }
  & > *:last-child > * {
    border-bottom-right-radius: ${props => props.borderRadius || MATRIX_BORDER_RADIUS};
  }

  & > *:nth-child(${props => props.columns}) {
    border-top-right-radius: ${props => props.borderRadius || MATRIX_BORDER_RADIUS};
  }
  & > *:nth-child(${props => props.columns}) > * {
    border-top-right-radius: ${props => props.borderRadius || MATRIX_BORDER_RADIUS};
  }

  & > *:nth-child(${props => props.columns * (props.rows - 1) + 1}) {
    border-bottom-left-radius: ${props => props.borderRadius || MATRIX_BORDER_RADIUS};
  }
  & > *:nth-child(${props => props.columns * (props.rows - 1) + 1}) > * {
    border-bottom-left-radius: ${props => props.borderRadius || MATRIX_BORDER_RADIUS};
  }

  & > .disabled {
    color: #CDCDCD;
    cursor: not-allowed;
  }
  & > .disabled > * {
    pointer-events: none;
  }
  & > .selected {
    color: var(--color-golf-green);
  }
`;


export function OptionGroupItem({ selected, onClick, disabled, children, ...props }) {
  return (
    <div
      className={'text-center ' + (disabled ? 'disabled ring-2 ring-gray-200 z-0' : selected ? 'selected ring-4 ring-green-400 z-20' : 'ring-2 ring-gray-300 z-10')}
      onClick={!disabled && !selected ? onClick : undefined}
      {...props}
    >
      {children}
    </div>
  );
}
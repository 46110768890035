import InfoLabel from './common/UserInfoLabel';
import InfoValue from './common/UserInfoValue';
import Page from '../../components/Page';
import ContentContainer from './common/ContentContainer';
import Content from './common/Content';


export default function Blocked({ info, onReset }) {
  return (
    <Page>
      <ContentContainer>
        <Content>
          <div className="text-center">
            <div className="text-2xl font-extrabold select-none">Check In Blocked</div>
          </div>

          <div>
            <InfoLabel>Reason</InfoLabel>
            <InfoValue>{info.reason}</InfoValue>
          </div>
          <button
            className="btn btn-contained-primary"
            onClick={onReset}
          >
            Done
          </button>
        </Content>
      </ContentContainer>
    </Page>
  );
}
import React, { useState, useCallback, useMemo, useRef } from 'react';
import { useForm, useWatch, Controller } from 'react-hook-form';
import MainLayout from '../components/MainLayout';
import Branding from '../components/Branding';
import TextInput from '../components/Inputs/Text';
import PhoneNumberInput, { PhoneNumberInputTransform } from '../components/Inputs/PhoneNumber';
import PinInput from '../components/Inputs/Pin';
import LogoutButton from "../components/LogoutButton";
import * as API from '../lib/api';
import MembershipPictureRequirement from './MembershipPictureRequirement';
import useMeSWR from 'hooks/useMeSWR';
import useTransaction from 'hooks/useTransaction';

import {
  validatePhoneNumber,
  validatePin
} from '../lib/validation';

function OnSubmitButton({ control, children, loading }) {
  const { first_name, last_name, phone_number, pin } = useWatch({ control });

  const disabled = useMemo(() => {
    return loading || !first_name || !last_name || !validatePin(pin) || !validatePhoneNumber(PhoneNumberInputTransform(phone_number));
  }, [loading, first_name, last_name, pin, phone_number]);

  return (
    <button
      type="submit"
      className={disabled ? "btn-disabled" : "btn-contained-primary"}
      disabled={disabled}
    >
      {children}
    </button>
  );
}


export default function OneLastThing() {
  const meSWR = useMeSWR();

  const phoneInputRef = useRef();
  const pinInputRef = useRef();

  const [showPictureRequirement, setShowPictureRequirement] = useState(meSWR.data?.membership_picture_deadline != null);

  const trx = useTransaction();

  const onSubmit = useCallback(async (values) => {
    trx.begin();

    try {
      const updatedUser = await API.updateUser({
        ...values,
        phone_number: PhoneNumberInputTransform(values.phone_number)
      });
      meSWR.mutate(updatedUser);
      trx.end();
    } catch (err) {
      trx.end(err);
    }
  }, [trx, meSWR]);

  const { handleSubmit, control, register } = useForm();

  return (
    <>
      <MainLayout
        Header={{ className: "justify-center" }}
        card
        header={(
          <Branding logo={true} name={true} />
        )}
        title={(
          <>
            <div className="text-2xl font-extrabold">{showPictureRequirement ? 'Membership Picture' : 'One Last Thing'}</div>
            <div className="text-gray-500">Create your profile for checking in</div>
          </>
        )}
        content={(
          <>
            {showPictureRequirement && (
              <MembershipPictureRequirement
                onDismiss={() => setShowPictureRequirement(false)}
                forceAllowSkip={true}
              />
            )}
            {!showPictureRequirement && (
              <form
                className="grid gap-4"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="grid gap-2 grid-cols-none sm:grid-cols-2">
                  <div>
                    <label>First Name</label>
                    <TextInput {...register("first_name")} placeholder="First Name" disabled={trx.loading} autoFocus={true} />
                  </div>
                  <div>
                    <label>Last Name</label>
                    <TextInput {...register("last_name")} placeholder="Last Name" disabled={trx.loading} />
                  </div>
                </div>
                <div className="p-2 rounded-md border-gray-200 border-2">
                  <div className="mb-1">Make it memorable</div>
                  <div className="text-gray-500">Your phone number and pin will be used as a back up to check in when golfing.</div>
                </div>
                <div className="grid gap-2 grid-cols-none sm:grid-cols-2">
                  <div>
                    <label>Phone Number</label>
                    <Controller
                      name="phone_number"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <PhoneNumberInput
                          {...field}
                          disabled={trx.loading}
                          ref={phoneInputRef}
                          onInput={(ev) => {
                            if (pinInputRef.current) {
                              const value = PhoneNumberInputTransform(ev.target.value || '');
                              if (value && value.length >= 10 && phoneInputRef.current.selectionStart >= phoneInputRef.current.value.length - 1) {
                                pinInputRef.current.focus();
                                pinInputRef.current.selectionStart = 0;
                                pinInputRef.current.selectionEnd = 0;
                              }
                            }
                          }}
                        />
                      )}
                    />
                  </div>
                  <div>
                    <label>Pin (4-digits)</label>
                    <Controller
                      name="pin"
                      control={control}
                      render={({ field }) => (
                        <PinInput {...field} disabled={trx.loading} ref={pinInputRef} />
                      )}
                    />
                  </div>
                </div>

                <OnSubmitButton control={control} loading={trx.loading}>
                  {trx.loading ? 'Saving' : 'Continue'}
                </OnSubmitButton>

                {trx.error && (
                  <div className="error">{trx.error.message}</div>
                )}

                <LogoutButton className="btn-link-primary" />
              </form>
            )}
          </>
        )}
      />
    </>
  );
}


export default function GolfingWithChipper() {
  return (
    <div>
      <div className="text-xl text-gray-500 font-medium">
        Golfing With Chipper
      </div>
      <div className="h-0.5 bg-gray-200 my-2"></div>
      <div className="grid gap-2">
        <div>
          <div className="font-bold">Booking Tee Times</div>
          <div>Call your courses to book tee times</div>
        </div>
        <div>
          <div className="font-bold">Golfing at the Course</div>
          <div>Check in with Chipper Golf before your round</div>
        </div>
        <div>
          <div className="font-bold">Using a Golf Cart?</div>
          <div>Add a cart to your round while checking in, then ask the course for your keys</div>
        </div>
      </div>
    </div>
  );
}
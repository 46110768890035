import useAuth0 from './useAuth0';
import useSWR from 'swr';
import * as API from '../lib/api';

export default function useLastCheckInSWR() {
  const { isAuthenticated } = useAuth0();

  return useSWR(isAuthenticated ? '/checkins?user=me&success=true&limit=1' : null, async () => {
    const res = await API.listCheckIns({
      user: 'me',
      success: true,
      limit: 1,
    });
    return res[0];
    // const start = Date.now();
    // const res = await API.listCheckIns({
    //   user: 'me',
    //   success: true,
    //   limit: 1,
    // });
    // console.log('BENCHMARK', Date.now() - start);
    // return res[0];
  });
}

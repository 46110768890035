import React, { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MyPlan from '../MyPlan';
import Modal from '../../components/Modal';
import useConfirmation from '../../hooks/useConfirmation';
import { APP_URLS } from '../../constants';

import {
  ConfirmAddonCancel,
  ConfirmAddonUncancel
} from '../Confirmations';


export default function Subscriptions({ user, subscriptions }) {
  const navigate = useNavigate();
  const confirmation = useConfirmation();

  const onManageSubscriptionAddon = useCallback((data) => {
    confirmation.open(data.subscriptionAddon.cancel_at_period_end ? 'uncancel_addon' : 'cancel_addon', data);
  }, [confirmation]);

  const hasSubscriptions = subscriptions?.length > 0;

  return (
    <div>
      <div className="flex justify-between items-end mb-2">
        <div className="text-lg font-bold">{`Your Plan${subscriptions?.length > 1 ? 's' : ''}`}</div>
        <Link
          className="btn btn-contained-primary"
          to={APP_URLS.RESUBSCRIBE}
        >
          Add Subscription
        </Link>
      </div>

      <div className="grid gap-3">
        {hasSubscriptions && subscriptions.map((subscription) => (
          <MyPlan
            key={subscription.uid}
            subscription={subscription}
            user={user}
            manageSubscriptionHref={`/subscriptions/${subscription.uid}`}
            onChangeSubscription={() => navigate(APP_URLS.MANAGE_PLAN)}
            onOpenSubscriptionAddon={onManageSubscriptionAddon}
          />
        ))}
        {subscriptions && !hasSubscriptions && (
          <div className="p-3 rounded-md border-2 border-gray-200">
            <div className="flex justify-between items-start">
              <div className="font-semibold">
                <div className="ml-4">No active subscriptions.</div>
                <div className="mt-2">
                  <Link
                    className="btn btn-link-primary"
                    to={APP_URLS.RESUBSCRIBE}
                  >
                    Reactivate your account
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal
        isOpen={confirmation.active}
        onRequestClose={confirmation.hide}
      >
        <>
          {confirmation.type === 'cancel_addon' && (
            <ConfirmAddonCancel
              subscription={confirmation.data.subscription}
              subscriptionAddon={confirmation.data.subscriptionAddon}
              onDismiss={confirmation.hide}
            />
          )}
          {confirmation.type === 'uncancel_addon' && (
            <ConfirmAddonUncancel
              subscription={confirmation.data.subscription}
              subscriptionAddon={confirmation.data.subscriptionAddon}
              onDismiss={confirmation.hide}
            />
          )}
        </>
      </Modal>
    </div>
  );
}
import { useState, useMemo, useCallback } from 'react';
import { OptionGroup, OptionGroupItem } from '../../../../components/OptionGroup';
import ActivityTypeOptionItem from '../../ActivityTypeOptionItem';
import GolfCartIcon from '../../../../components/Icons/GolfCart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonWalking } from '@fortawesome/free-solid-svg-icons';
import { formatCurrency } from '../../../../lib/formatting';

const InitialOptionsState = {
  golf_cart: undefined,
  holes: undefined,
};

export default function Configurator({ state, previewSWR, onSetOptions }) {
  const [options, setOptions] = useState(InitialOptionsState);

  const onSelectOption = useCallback((option) => {
    const nextOptions = { ...options, ...option };
    setOptions(nextOptions);
    if (nextOptions.holes != null && nextOptions.golf_cart != null) {
      onSetOptions(nextOptions);
    }
  }, [options, onSetOptions]);

  // if (!data && !error) return null;

  const { site_activity } = state;

  const {
    golfCartFee,
    walkingFee
  } = useMemo(() => {
    let golfCartFeeDescription, walkingFeeDescription;

    if (site_activity.config.allows_walking) {
      walkingFeeDescription = 'Free';
    } else {
      walkingFeeDescription = 'Golf Cart Required';
    }

    if (!site_activity.config.allows_golf_cart) {
      golfCartFeeDescription = 'Walking Only';
    } else {
      if (previewSWR.data?.invoice) {
        if (options.golf_cart) {
          const golfCartFeeLine = previewSWR.data.invoice.items.find((item) => item.ref === 'golf_cart');

          if (golfCartFeeLine) {
            golfCartFeeDescription = formatCurrency(
              golfCartFeeLine.amount / 100,
              golfCartFeeLine.currency
            );
          } else {
            golfCartFeeDescription = 'Free';
          }
        }
      }
    }

    return {
      golfCartFee: golfCartFeeDescription,
      walkingFee: walkingFeeDescription
    };
  }, [previewSWR, site_activity, options]);

  return (
    <div className="grid gap-4">
      <div>
        <div className="text-xl font-medium">
          How many holes?
        </div>
        <OptionGroup className="shadow-md bg-white">
          <OptionGroupItem
            selected={options.holes === 9}
            onClick={() => onSelectOption({ holes: 9 })}
          >
            <ActivityTypeOptionItem
              title="9-holes"
              description="Playing up to 9"
            />
          </OptionGroupItem>

          <OptionGroupItem
            selected={options.holes === 18}
            onClick={() => onSelectOption({ holes: 18 })}
          >
            <ActivityTypeOptionItem
              title="18-holes"
              description="Playing 9-18"
            />
          </OptionGroupItem>
        </OptionGroup>
      </div>

      <div>
        <div className="text-xl font-medium">
          Walking or using a Golf Cart?
        </div>
        <OptionGroup className="shadow-md bg-white">
          <OptionGroupItem
            selected={options.golf_cart === false}
            onClick={() => onSelectOption({ golf_cart: false })}
            disabled={site_activity.config.allows_walking === false}
          >
            <ActivityTypeOptionItem
              title="I'll Walk"
              description={walkingFee}
              // description={walkingDescription}
              icon={(
                <div className="ml-2 flex items-center">
                  <FontAwesomeIcon icon={faPersonWalking} size="xl" />
                </div>
              )}
            />
          </OptionGroupItem>

          <OptionGroupItem
            selected={options.golf_cart === true}
            onClick={() => onSelectOption({ golf_cart: true })}
            disabled={site_activity.config.allows_golf_cart === false}
          >
            <ActivityTypeOptionItem
              title="Golf Cart"
              description={golfCartFee}
              // description={golfCartDescription}
              icon={(
                <div className="ml-2 flex items-center">
                  <GolfCartIcon />
                </div>
              )}
            />
          </OptionGroupItem>
        </OptionGroup>
      </div>
    </div>
  );
}
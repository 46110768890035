import { useDispatch } from 'react-redux';
import useSWR from 'swr';
import BaseSelector from './BaseSelector';
import * as API from '../../lib/api';
import * as SubscribeActions from '../../actions/subscribe';
import PickerOptionItem from '../../components/PickerOptionItem';
import { formatPriceDescription } from '../../lib/formatting';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

export default function AccessSelector({ state }) {
  const dispatch = useDispatch();

  const activity = state.configuration.activity.uid;

  const { data, error } = useSWR(['/accesses', activity], async () => {
    const result = await API.listAccesses({ activity });
    return result.sort((a, b) => a.level - b.level);
  });

  return (
    <BaseSelector
      title="Plans"
      data={data}
      error={error}
      onSubmit={(entity) => dispatch(SubscribeActions.setAccess(entity))}
      renderOption={(access) => {
        const price = formatPriceDescription(access);

        return (
          <PickerOptionItem
            title={access.name}
            description={`${price} - ${access.Stats.site_count} ${access.Stats.site_count === 1 ? 'location' : 'locations'}`}
            icon={<FontAwesomeIcon icon={faChevronRight} className="mr-2" />}
          />
        )
      }}
    />
  );
}
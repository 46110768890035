import useSWR from 'swr';
import { useDispatch } from 'react-redux';
import BaseSelector from './BaseSelector';
import PickerOptionItem from '../../components/PickerOptionItem';
import * as API from '../../lib/api';
import * as SubscribeActions from '../../actions/subscribe';
import useMySubscriptionsSWR from 'hooks/useMySubscriptionsSWR';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

export default function ActivitySelector({ state }) {
  const dispatch = useDispatch();

  const network = state.configuration.network.uid;
  const subscriptionsSWR = useMySubscriptionsSWR();

  const { data, error } = useSWR(subscriptionsSWR.data ? `/activities?network=${network}` : null, async () => {
    const activities = await API.listActivities({ network });

    if (subscriptionsSWR.data) {
      const filterActivities = subscriptionsSWR.data.map((sub) => sub.Access.Activity.uid);
      return activities.filter((activity) => filterActivities.indexOf(activity.uid) < 0);
    }
    return activities;
  });

  return (
    <BaseSelector
      title="Activities"
      data={data}
      error={error}
      onSubmit={(entity) => dispatch(SubscribeActions.setActivity(entity))}
      renderOption={(entity) => (
        <PickerOptionItem
          disabled={false}
          title={entity.name}
          icon={<FontAwesomeIcon icon={faChevronRight} className="mr-2" />}
        />
      )}
    />
  );
}

import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import { useForm, useWatch, Controller } from 'react-hook-form';
import * as SubscribeActions from '../../actions/subscribe';
import BaseContainer from './BaseContainer';
import SubscriptionDueToday from './CheckoutDueToday';
import ZipCodeInput from '../../components/Inputs/ZipCode';
import PaymentMethodInput from '../../components/Inputs/Payment';
import PaymentMethod from '../../components/PaymentMethod';
import { APP_URLS, STORAGE_KEY_SHARE_PROMOTION } from '../../constants';
import * as API from '../../lib/api';
import { validateZipCode } from '../../lib/validation';
import useMySubscriptionsSWR from 'hooks/useMySubscriptionsSWR';
import useMeSWR from 'hooks/useMeSWR';
import useOnboardingStage from 'hooks/useOnboardingStage';
import useTransaction from 'hooks/useTransaction';
import { delay } from '../../lib/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';


function OnSubmitButton({ control, children, loading, user }) {
  const { payment, zip_code } = useWatch({ control });

  const disabled = loading || !validateZipCode(zip_code) || (!user.payment_method && !payment);

  return (
    <button
      className={disabled ? "btn-disabled" + (loading ? " animate-pulse" : "") : "btn-contained-primary"}
      disabled={disabled}
      type="submit"
    >
      {children}
    </button>
  );
}

export default function Checkout({ state, onReset }) {
  const {
    access,
    promotion,
    addons
  } = state.configuration;

  const selectedAddons = useMemo(() => {
    return Object.keys(addons || {}).filter((addon) => addons[addon]);
  }, [addons]);

  const meSWR = useMeSWR();
  const onboardingStage = useOnboardingStage();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cache } = useSWRConfig();

  const mySubscriptionsSWR = useMySubscriptionsSWR();

  const trx = useTransaction();

  const onSubmit = useCallback(async (values) => {
    trx.begin();

    const changes = [];

    try {
      if (values.payment) {
        const sourceResult = values.payment;
        if (sourceResult.error) {
          throw new Error(sourceResult.error.message || 'Unable to update payment method');
        } else {
          changes.push({ payment_source: sourceResult.source.id });
        }
      }

      if (values.zip_code !== meSWR.data.zip_code) {
        changes.push({ zip_code: values.zip_code });
      }

      if (changes.length > 0) {
        const updatedUser = await API.updateUser(Object.assign({}, ...changes));
        meSWR.mutate(updatedUser);
      }

      const subscription = await API.createSubscription({
        access: access.uid,
        addons: selectedAddons,
        promotion_code: promotion?.uid,
        preview: false
      });

      trx.end();

      new delay(400);
      
      /** Must update user incase fields are updated after creating subscription */
      // await Promise.all([
      //   meSWR.mutate(),
      //   new delay(400),
      // ]);

      /** Must update user incase fields are updated after creating subscription */
      await meSWR.mutate();

      mySubscriptionsSWR.mutate((currentSubscriptions) => [subscription, ...currentSubscriptions]);

      localStorage.removeItem(STORAGE_KEY_SHARE_PROMOTION);
      cache.clear();
      meSWR.mutate();
      dispatch(SubscribeActions.setSubscribed(true));

      if (!onboardingStage) {
        toast.success('Subscribed!');
        navigate(APP_URLS.ACCOUNT, { replace: true });
      }
    } catch (err) {
      trx.end(err);
    }
  }, [trx, meSWR, access.uid, selectedAddons, promotion?.uid, mySubscriptionsSWR, cache, dispatch, onboardingStage, navigate]);

  const { handleSubmit, control } = useForm({
    defaultValues: {
      zip_code: meSWR.data?.zip_code
    }
  });

  if (!meSWR.data) {
    return null;
  }

  return (
    <BaseContainer title="My Plan">
      <div>
        <SubscriptionDueToday
          access={access}
          addons={selectedAddons}
          promotion={promotion}
          cancelTitle="Change Plan"
          onCancel={onReset}
          disabled={trx.loading || trx.complete}
        />
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid gap-4"
      >
        <div>
          <label>Billing Zip Code</label>
          <Controller
            name="zip_code"
            control={control}
            render={({ field }) => (
              <ZipCodeInput {...field} disabled={trx.loading || trx.complete} />
            )}
          />
        </div>

        <div>
          <label>Payment Method</label>
          <PaymentMethod payment={meSWR.data.payment_method} className="pb-1" />
          <Controller
            name="payment"
            control={control}
            render={({ field }) => {
              return (
                <PaymentMethodInput
                  hidePostalCode={true}
                  current={meSWR.data.payment_method} // keep this here for now, when this changes, the input field clears
                  disabled={trx.loading || trx.complete}
                  {...field}
                  onChange={async (ev) => {
                    const source = await (ev.getSource ? ev.getSource() : undefined);
                    field.onChange(source);
                  }}
                />
              );
            }}
          />
        </div>
        
        {!trx.complete && (
          <>
            <OnSubmitButton control={control} loading={trx.loading} user={meSWR.data}>
              {trx.loading ? 'Subscribing' : 'Subscribe'}
            </OnSubmitButton>
            {trx.error && (
              <div className="text-red-500 text-sm font-bold">{trx.error.message}</div>
            )}
            <div className="text-center text-sm select-none text-gray-500">
              By continuing you authorize Chipper Golf to charge your payment method for the membership described above.
            </div>
          </>
        )}

        {trx.complete && (
          <div className="flex justify-center">
            <FontAwesomeIcon icon={faCheckCircle} size="xl" color="var(--color-golf-green)" />
          </div>
        )}
      </form>
    </BaseContainer>
  );
}
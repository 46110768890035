import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useMeSWR from 'hooks/useMeSWR';
import { APP_URLS, DESKTOP_BREAKPOINT } from '../constants';
import useNeedsUpdatedPaymentMethod from 'hooks/useNeedsUpdatedPaymentMethod';


const useSnackbarStyles = makeStyles({
  anchorOriginBottomCenter: {
    bottom: 'calc(60px + env(safe-area-inset-bottom))',
    [`@media${DESKTOP_BREAKPOINT}`]: {
      bottom: '8px'
    }
  }
});


function getMembershipPictureRequired(user) {
  return !!(
    user.membership_picture_deadline != null
    && Date.now() > new Date(user.membership_picture_deadline).getTime()
  );
}


function Notification({ paymentRequired, membershipPictureRequired }) {
  const navigate = useNavigate();

  const {
    title,
    description,
    actionTitle,
    action
  } = useMemo(() => {
    if (paymentRequired) {
      return {
        title: 'Update Payment Method',
        description: 'Please update your payment method now to keep using Chipper Golf.',
        actionTitle: 'Click here to update.',
        action: () => navigate(APP_URLS.ACCOUNT_SETTINGS)
      };
    } else if (membershipPictureRequired) {
      return {
        title: 'Membership Picture Required',
        description: 'Please add a membership picture now to keep using Chipper Golf.',
        actionTitle: 'Click here to update.',
        action: () => navigate(APP_URLS.MEMBERSHIP_PICTURE_REQUIREMENT)
      };
    } else {
      return {
        title: '',
        description: '',
        action: ''
      };
    }
  }, [navigate, paymentRequired, membershipPictureRequired]);

  return (
    <div className="rounded shadow select-none text-white p-3 bg-gray-800">
      <div className="font-bold">
        {title}
      </div>
      <div className="mr-2">
        {description + ' '}
        <button
          className="btn-link-primary p-0 font-bold"
          onClick={action}
        >
          {actionTitle}
        </button>
      </div>
    </div>
  );
}


export default function SnackbarNotification() {
  const meSWR = useMeSWR();
  const location = useLocation();
  const snackbarClasses = useSnackbarStyles();

  const needsUpdatedPaymentMethod = useNeedsUpdatedPaymentMethod();

  const requirements = useMemo(() => {
    if (!meSWR.data) {
      return [];
    }
    return [
      needsUpdatedPaymentMethod,
      getMembershipPictureRequired(meSWR.data)
    ];
  }, [meSWR.data, needsUpdatedPaymentMethod]);

  const open = useMemo(() => {
    return (
      requirements.indexOf(true) >= 0
      && [
        APP_URLS.ACCOUNT_SETTINGS,
        APP_URLS.CANCELATION_SURVEY,
        APP_URLS.MEMBERSHIP_PICTURE_REQUIREMENT,
        APP_URLS.CHECKIN,
        APP_URLS.SUPPORT
      ].indexOf(location.pathname) < 0
    );
  }, [requirements, location.pathname]);

  if (!meSWR.data) {
    return null;
  }

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      classes={snackbarClasses}
    >
      <Notification
        paymentRequired={requirements[0]}
        membershipPictureRequired={requirements[1]}
      />
    </Snackbar>
  );
}
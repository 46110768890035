import { useCallback } from 'react';
import { toast } from 'react-hot-toast';
import * as Auth from '../lib/auth';
import { useNavigate } from 'react-router-dom';
import { APP_URLS } from '../constants';

export default function useResetPassword() {
  const navigate = useNavigate();
  const resetPassword = useCallback(async ({ email }) => {
    try {
      const result = await Auth.resetPassword({ email });
      toast.success(result);
      navigate(APP_URLS.LOGIN, { replace: true });
    } catch (err) {
      toast.error(err.message);
    }
  }, [navigate]);
  return resetPassword;
}
import { useState, useCallback } from 'react';

export default function useConfirmation() {
  const [confirmation, setConfirmation] = useState({ active: false, type: '', data: null });

  const open = useCallback((type, data) => {
    setConfirmation({ active: true, type, data });
  }, []);

  const hide = useCallback(() => {
    setConfirmation(previous => ({ ...previous, active: false }));
  }, []);

  const update = useCallback(data => {
    setConfirmation(previous => ({ ...previous, data }));
  }, []);

  return {
    open,
    hide,
    update,
    active: confirmation.active,
    type: confirmation.type,
    data: confirmation.data,
  };
}

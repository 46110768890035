import { useMemo } from 'react';
import styled from 'styled-components';
import { getIKSmartSizeImageURL } from '../lib/helpers';

const Container = styled.div`
  box-shadow: 0 3px 9px 0 #AAB7C4;
  border-radius: 50%;
  overflow: hidden;
  border-width: 6px;
  border-color: #FAFAFA;
  border-style: solid;
  box-sizing: border-box;
  background-color: #FFFFFF;
`;

export default function UserImage({ src, size, transform = true, ...props }) {
  const transformedSrc = useMemo(() => {
    if (src && transform) {
      return getIKSmartSizeImageURL(src, size, size);
    }
    return src;
  }, [src, size, transform]);

  return (
    <Container key={transformedSrc} {...props}>
      {!transformedSrc && (
        <div style={{ width: `${size}px`, height: `${size}px` }}></div>
      )}
      {transformedSrc && (
        <img
          className="object-cover whitespace-nowrap overflow-hidden"
          style={{ textIndent: '100%', width: `${size}px`, height: `${size}px` }}
          src={transformedSrc}
          alt="Profile"
        />
      )}
    </Container>
  );
}
import useLogout from 'hooks/useLogout';


export default function LogoutButton(props) {
  const logout = useLogout();

  return (
    <button
      className="btn-outlined-primary"
      onClick={logout}
      {...props}
    >
      Log Out
    </button>
  );
}
import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { getIKSmartSizeImageURL } from '../lib/helpers';

const SIZE = 92;

export default function UserProfileImage({ src }) {
  const smartSrc = useMemo(() => {
    if (src) {
      return getIKSmartSizeImageURL(src, SIZE, SIZE);
    }
  }, [src]);

  return (
    <div className="relative w-[92px] h-[92px]">
      {!smartSrc && (
        <div className="w-full h-full bg-[#fafafa] border-4 rounded-2xl flex items-center justify-center">
          <FontAwesomeIcon icon={faUser} size="3x" color="#ACACAC" />
        </div>
      )}
      {smartSrc && (
        <img
          className="w-full h-full object-cover indent-[100%] whitespace-nowrap overflow-hidden rounded-2xl"
          src={smartSrc}
          alt="Profile"
        />
      )}
    </div>
  );
}

import { useMemo, useCallback } from 'react';
import clsx from 'clsx';
import * as Confirmation from '../../components/Confirmation';
import { formatPriceDescription } from '../../lib/formatting';
import * as API from '../../lib/api';
import { delay } from '../../lib/helpers';
import useMySubscriptionsSWR from 'hooks/useMySubscriptionsSWR';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import useTransaction from '../../hooks/useTransaction';
import { mixpanelTrack } from '../../integrations/mixpanel';

export default function ConfirmAddonUncancel({ subscription, subscriptionAddon, onDismiss }) {
  const trx = useTransaction();
  const mySubscriptionsSWR = useMySubscriptionsSWR();

  const onSubmit = useCallback(async () => {
    try {
      trx.begin();
      const [uncanceledSubscriptionAddon] = await Promise.all([
        API.uncancelSubscriptionAddon(subscriptionAddon.uid),
        delay(400),
      ]);

      mixpanelTrack('subscription_addon_uncancel', {
        subscription,
        subscriptionAddon: uncanceledSubscriptionAddon,
      });

      trx.end();
    } catch (err) {
      console.log(err);
      trx.end(err);
      return;
    }

    try {
      await Promise.all([
        mySubscriptionsSWR.mutate(),
        delay(1000),
      ]);
    } catch (err) {
      mySubscriptionsSWR.mutate();
    }
    onDismiss();
  }, [onDismiss, trx, subscriptionAddon.uid, subscription, mySubscriptionsSWR]);

  const amount = useMemo(() => {
    return formatPriceDescription(subscriptionAddon.Addon);
  }, [subscriptionAddon]);

  const addonTitle = subscriptionAddon.Addon.title;

  return (
    <Confirmation.Container>
      <Confirmation.Header>{`Reactivate ${addonTitle}`}</Confirmation.Header>
      <div>
        {`Your ${addonTitle.toLowerCase()} access is scheduled to cancel at the end of your billing period. Reactivate ${addonTitle.toLowerCase()} access to keep ${addonTitle.toLowerCase()} access for future months. You’ll continue to be charged ${amount} plus tax.`}
      </div>

      {!trx.complete && (
        <>
          <button
            className={clsx("btn", trx.loading ? "btn-disabled" : "btn-contained-primary")}
            onClick={onSubmit}
            disabled={trx.loading}
          >
            {trx.loading ? `Reactivating ${addonTitle}` : `Reactivate ${addonTitle}`}
          </button>
          <button
            className={clsx("btn", trx.loading ? "btn-disabled" : "btn-outlined-primary")}
            onClick={onDismiss}
            disabled={trx.loading}
          >
            Close
          </button>
        </>
      )}

      {trx.complete && (
        <>
          <div className="flex justify-center">
            <div>
              Successfully reactivated addon
            </div>
          </div>
          <div className="flex justify-center">
            <FontAwesomeIcon icon={faCheckCircle} size="xl" color="var(--color-golf-green)" />
          </div>
        </>
      )}
    </Confirmation.Container>
  );
}

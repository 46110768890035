import { useMemo, useCallback } from 'react';
import * as API from '../../lib/api';
import { formatSubscriptionInvoice } from '../../lib/formatting';
import useSWR, { useSWRConfig } from 'swr';
import clsx from 'clsx';
import { BasicList } from '../../components/BasicList';
import InfoListItem from '../../components/InfoListItem';
import useTransaction from '../../hooks/useTransaction';
import useConfirmation from '../../hooks/useConfirmation';
import Modal from '../../components/Modal';
import { delay } from '../../lib/helpers';
import { Link } from 'react-router-dom';
import { APP_URLS } from '../../constants';
import useMeSWR from 'hooks/useMeSWR';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { mixpanelTrack } from '../../integrations/mixpanel';

import {
  ConfirmAddonCancel
} from '../Confirmations';


export default function Reactivate({ user, subscription, onDismiss }) {
  const meSWR = useMeSWR();

  const siteActivitiesState = useSWR(
    `/siteactivites?subscription=${subscription.uid}`,
    () => API.listSiteActivities({
      subscription: subscription.uid,
      limit: 100
    }),
  );

  const sites = useMemo(() => {
    const sites = [];
    if (siteActivitiesState.data) {
      const existing = {};
      for (const siteActivity of siteActivitiesState.data) {
        if (!existing[siteActivity.Site.uid]) {
          sites.push(siteActivity.Site);
          existing[siteActivity.Site.uid] = true;
        }
      }
      return sites;
    }
    return null;
  }, [siteActivitiesState.data]);

  const invoice = useMemo(() => {
    return formatSubscriptionInvoice({ subscription, tax_rate: user.TaxRate.combined_rate });
  }, [subscription, user.TaxRate.combined_rate]);

  const trx = useTransaction();

  const { cache } = useSWRConfig();

  const onSubmit = useCallback(async () => {
    trx.begin();
    try {
      const unpausedSubscription = await API.unpauseSubscription(subscription.uid);
      mixpanelTrack('subscription.unpause', {
        subscription: unpausedSubscription,
      });
      trx.end();
    } catch (err) {
      trx.end(err);
      return;
    }

    // TODO: why revalidate meSWR and then clearing the cache?
    try {
      await meSWR.mutate();
    } catch (err) {
      console.log(err);
    }

    cache.clear();
    await delay(400);
    onDismiss();
  }, [trx, cache, onDismiss, subscription.uid, meSWR]);


  const confirmation = useConfirmation();

  const onManageSubscriptionAddon = useCallback((data) => {
    confirmation.open(data.subscriptionAddon.cancel_at_period_end ? 'uncancel_addon' : 'cancel_addon', data);
  }, [confirmation]);

  const carryoverDays = useMemo(() => {
    const carryover = subscription.pause_carryover_time;
    if (carryover > 0) {
      return Math.round((subscription.pause_carryover_time || 0) / (1000 * 60 * 60 * 24));
    }
    return 0;
  }, [subscription]);

  if (!sites) return null;

  return (
    <div className="grid gap-2 max-w-xl mx-auto">
      <div className="font-bold text-xl">Your Plan</div>
      
      <BasicList>
        <InfoListItem title={subscription.Access.name}>
          <div>{`${invoice.access.priceDescription} - ${sites.length} Courses`}</div>
        </InfoListItem>
        {(subscription.SubscriptionAddon || []).map((subscriptionAddon, idx) => (
          <InfoListItem
            key={subscriptionAddon.uid}
            title={subscriptionAddon.Addon.title}
            option={{
              // type: 'checkbox',
              // value: selectedAddons[addon.uid],
              // onChange: () => setSelectedAddons(addons => ({ ...addons, [addon.uid]: !addons[addon.uid] }))
              type: 'button',
              onClick: () => onManageSubscriptionAddon({ subscription, subscriptionAddon }),
              value: 'Remove'
            }}
          >
            <div>{invoice.SubscriptionAddon[idx].priceDescription}</div>
          </InfoListItem>
        ))}
        {carryoverDays > 0 && (
          <InfoListItem
            title={`${carryoverDays} Carryover Day${carryoverDays !== 1 ? 's' : ''} - Free`}
          >
            Free days leftover from last year that extend your first month of the season. First month billed at reactivation.
          </InfoListItem>
        )}
        <InfoListItem
          title="Monthly Total"
          option={{
            type: "text",
            value: "Includes Taxes"
          }}
        >
          <div>{invoice.total}</div>
        </InfoListItem>
      </BasicList>
      
      <div>
        <div className="flex justify-center">
          {!trx.complete && (
            <button
              disabled={trx.loading}
              className={clsx("btn", trx.loading ? "btn-disabled" : "btn-contained-primary")}
              onClick={onSubmit}
            >
              {trx.loading ? "Reactivating" : "Reactivate Now"}
            </button>
          )}
          {trx.complete && (
            <FontAwesomeIcon icon={faCheckCircle} size="xl" color="var(--color-golf-green)" />
          )}
        </div>
      </div>
      {trx.error && (
        <div className="grid gap-3 justify-items-center">
          <div className="text-red-500 text-sm font-bold">{trx.error.message || 'Something went wrong.'}</div>
          <div>
            <Link
              className="btn btn-outlined-primary"
              to={APP_URLS.ACCOUNT_SETTINGS}
            >
              Update Payment Method
            </Link>
          </div>
        </div>
      )}

      <div className="font-bold text-lg mt-5">More Details</div>

      <div>
        <div className="font-bold">Courses</div>
        <div className="grid grid-cols-2 gap-1">
          {sites.map(site => (
            <div key={site.uid}>
              {site.name}
            </div>
          ))}
        </div>
      </div>

      <div className="h-0.5 bg-gray-200"></div>
      
      <div>
        <div className="font-bold">Course Access</div>
        <div>
          {`Enjoy unlimited rounds at ${sites.length} course${sites.length !== 1 ? 's' : ''} in ${subscription.Access.Activity.Network.name}.${subscription.Access.details ? ` ${subscription.Access.details}` : ''}`}
        </div>
      </div>
      
      <Modal
        isOpen={confirmation.active}
        onRequestClose={confirmation.hide}
      >
        <>
          {confirmation.type === 'cancel_addon' && (
            <ConfirmAddonCancel
              subscription={confirmation.data.subscription}
              subscriptionAddon={confirmation.data.subscriptionAddon}
              onDismiss={confirmation.hide}
            />
          )}
          {/* {confirmation.type === 'uncancel_addon' && (
            <ConfirmAddonUncancel
              loading={loading}
              subscription={confirmation.data.subscription}
              addon={confirmation.data.subscriptionAddon}
              onClose={confirmation.hide}
              onSubmit={() => onUncancelAddonRequest(confirmation.data)}
            />
          )} */}
        </>
      </Modal>
    </div>
  );
}
import {
  getPriceAmount,
  getPriceInterval,
  getPriceIntervalCount,
} from './helpers';

const Formatters = {
  Currency: {
    'usd': new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    })
  }
};

export function formatCurrency(amount, currency = 'usd') {
  let formatter = Formatters.Currency[currency];
  if (formatter) {
    return formatter.format(amount);
  }

  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency.toUpperCase(),
  });

  if (formatter) {
    Formatters.Currency[currency] = formatter;
    return formatter.format(amount);
  }

  return '';
}


export function formatPhoneNumber(phoneNumberString) {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return '';
}


export function formatPlanIntervalFraction(interval, interval_count, short) {
  const multi = interval_count > 1;

  switch (interval) {
    case 'day':
      return multi ? `${interval_count} days` : 'day';
    case 'week':
      return multi ? `${interval_count} weeks` : short ? 'wk' : 'week';
    case 'month':
      return multi ? `${interval_count} months` : short ? 'mo' : 'month';
    case 'year':
      return multi ? `${interval_count} years` : short ? 'yr' : 'year';
    default:
      break;
  }
}

export function formatPriceDescription({ price_config }) {
  return `${formatCurrency(getPriceAmount({ price_config }) / 100)}/${formatPlanIntervalFraction(getPriceInterval({ price_config }), getPriceIntervalCount({ price_config }), true)}`;
}

export function formatSubscriptionInvoice({ subscription, tax_rate }) {
  const accessPriceAmount = getPriceAmount(subscription.Access)
  let subtotal = accessPriceAmount;

  const formattedAddons = (subscription.SubscriptionAddon || []).map((subscriptionAddon) => {
    const addonPriceAmount = getPriceAmount(subscriptionAddon.Addon);
    subtotal += addonPriceAmount;
    return {
      uid: subscriptionAddon.uid,
      priceDescription: formatPriceDescription(subscriptionAddon.Addon),
    };
  });

  return {
    subtotal: formatCurrency(subtotal / 100),
    access: {
      priceDescription: formatPriceDescription(subscription.Access),
    },
    SubscriptionAddon: formattedAddons,
    total: formatCurrency((1 + tax_rate) * subtotal / 100),
  };
}


export function formatCoupon({
  amount_off, percent_off, duration, duration_in_months, currency
} = {}) {
  let beginning;
  if (amount_off) {
    beginning = `${formatCurrency(amount_off / 100, currency)} off`;
  } else if (percent_off) {
    beginning = `${percent_off} off`;
  } else {
    return '';
  }

  switch (duration) {
    case 'once':
      return `${beginning} first month`;
    case 'repeating':
      return `${beginning} first ${duration_in_months} months`;
    case 'forever':
      return `${beginning} forever`;
    default:
      return beginning;
  }
}

import { useMemo } from 'react';
import useSWR from 'swr';
import LoadingBar from '../../components/LoadingBar';
import { formatPriceDescription } from '../../lib/formatting';
import * as API from '../../lib/api';
import * as Confirmation from '../../components/Confirmation';


function ConfirmAddonCreate({ subscription, addon, preview, onSubmit, onClose, loading }) {
  const addonTitle = addon.title;

  const amount = useMemo(() => {
    return formatPriceDescription(addon);
  }, [addon]);

  return (
    <Confirmation.Container>
      <Confirmation.Header>{`${addonTitle} Access`}</Confirmation.Header>
      <div>
        {`Are you sure you want to add ${addonTitle.toLowerCase()}?`}
      </div>
      {subscription.paused && (
        <div>
          {`You will be charged an additional ${amount} plus tax when your subscription unpauses.`}
        </div>
      )}
      {!loading.show && (
        <>
          <button
            className="btn-contained-primary"
            onClick={onSubmit}
          >
            Add to Subscription
          </button>
          <button
            className="btn-outlined-primary"
            onClick={onClose}
          >
            Dismiss
          </button>
        </>
      )}
      <Confirmation.Loading loading={loading} />
    </Confirmation.Container>
  );
}


export default function ConfirmAddonCreateWrapper({ subscription, addon, ...props }) {
  const previewSWR = useSWR(`${subscription.uid}/${addon.uid}`, () => {
    return API.createSubscriptionAddon({
      subscription: subscription.uid,
      addon: addon.uid,
      preview: true
    });
  });

  let errorMessage = previewSWR.error?.message;

  if (errorMessage) {
    return (
      <div>{errorMessage}</div>
    );
  }

  if (!previewSWR.data) {
    return (
      <div className="pt-5">
        <LoadingBar />
      </div>
    );
  }

  return (
    <ConfirmAddonCreate
      {...props}
      subscription={subscription}
      addon={addon}
      preview={previewSWR.data}
    />
  );
}

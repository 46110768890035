import useOnboardingStage, { ONBOARDING_STAGES } from 'hooks/useOnboardingStage';
import Subscribe from './Subscribe';
import OneLastThing from './OneLastThing';

export default function Onboarding() {
  const onboardingStage = useOnboardingStage();

  if (!onboardingStage) {
    return null;
  }

  switch (onboardingStage) {
    case ONBOARDING_STAGES.SUBSCRIBE:
      return (
        <Subscribe />
      );
    case ONBOARDING_STAGES.ONE_LAST_THING:
      return (
        <OneLastThing />
      )
    default:
      return null;
  }
}

import React, { useMemo, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useUnmount, useMount } from 'react-use';
import * as Selectors from '../../reducers/selectors';
import * as SubscribeActions from '../../actions/subscribe';
import { useNavigate, useLocation } from 'react-router-dom';
import { APP_URLS } from '../../constants';

import NetworkSelector from './NetworkSelector';
import ActivitySelector from './ActivitySelector';
import AccessSelector from './AccessSelector';
import PlanConfigurator from './PlanConfigurator';
import Checkout from './Checkout';

import ProgressBar from '../../components/ProgressBar';
import MainLayout from '../../components/MainLayout';
import Branding from '../../components/Branding';
import Title from '../../components/Title';
import LogoutButton from '../../components/LogoutButton';
import useQueryParams from '../../hooks/useQueryParams';
import { objectEquals } from '../../lib/helpers';
import * as API from '../../lib/api';
import useMeSWR from 'hooks/useMeSWR';
import useOnboardingStage from 'hooks/useOnboardingStage';

const ONBOARDING_ACCESS = 'onboarding_access';

const SUBSTAGE_NETWORKS = 'networks';
const SUBSTAGE_ACTIVITIES = 'activities';
const SUBSTAGE_ACCESSES = 'accesses';
const SUBSTAGE_CONFIGURE = 'configure';
const SUBSTAGE_CHECKOUT = 'payment';

const StyledTitle = styled(Title)`
  margin-bottom: 8px;
`;

const STAGES = {
  [SUBSTAGE_NETWORKS]: {
    name: SUBSTAGE_NETWORKS,
    title: 'Choose Your Network',
    progress: 0.3
  },
  [SUBSTAGE_ACTIVITIES]: {
    name: SUBSTAGE_ACTIVITIES,
    title: 'Choose Your Activity',
    progress: 0.4
  },
  [SUBSTAGE_ACCESSES]: {
    name: SUBSTAGE_ACCESSES,
    title: 'Choose Your Plan',
    progress: 0.5
  },
  [SUBSTAGE_CONFIGURE]: {
    name: SUBSTAGE_CONFIGURE,
    title: 'My Plan',
    progress: 0.6
  },
  [SUBSTAGE_CHECKOUT]: {
    name: SUBSTAGE_CHECKOUT,
    title: 'Billing Info',
    // progress: state.subscription ? 1 : 0.75
    progress: 0.75
  }
};

function getStage(configuration) {
  const {
    network,
    activity,
    access,
    confirmed
  } = configuration;

  if (!network) {
    return STAGES[SUBSTAGE_NETWORKS];
  } else if (!activity) {
    return STAGES[SUBSTAGE_ACTIVITIES];
  } else if (!access) {
    return STAGES[SUBSTAGE_ACCESSES];
  } else if (!confirmed) {
    return STAGES[SUBSTAGE_CONFIGURE]
  } else {
    return STAGES[SUBSTAGE_CHECKOUT];
  }
}


function removeOnboardingAccess(navigate, search) {
  const params = new URLSearchParams(search);
  if (params.has(ONBOARDING_ACCESS)) {
    params.delete(ONBOARDING_ACCESS);
    navigate({
      search: params.toString()
    }, {
      replace: true,
    });
  }
}


export default function Subscribe() {
  const state = useSelector(Selectors.getSubscribeState);
  const meSWR = useMeSWR();
  const onboardingStage = useOnboardingStage();

  const isOnboarding = !!onboardingStage;

  const substage = useMemo(() => {
    return getStage(state.configuration);
  }, [state.configuration]);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const onReset = useCallback(() => {
    removeOnboardingAccess(navigate, location.search);
    if (isOnboarding) {
      dispatch(SubscribeActions.reset());
    } else {
      navigate(APP_URLS.RESUBSCRIBE, { replace: true });
    }
  }, [navigate, location.search, isOnboarding, dispatch]);

  useUnmount(() => {
    removeOnboardingAccess(navigate, location.search);
    dispatch(SubscribeActions.reset());
  });

  const queryParams = useQueryParams();

  useMount(async () => {
    const access = queryParams.onboarding_access;
    if (access) {
      dispatch(SubscribeActions.preload({ access }));
    }
  });

  const currentPriorityPlanRef = useRef(meSWR.data?.priority_plan);

  useEffect(() => {
    if (isOnboarding && state.configuration?.access?.uid && state.configuration?.addons) {
      if (
        !currentPriorityPlanRef.current
        || currentPriorityPlanRef.current.access?.uid !== state.configuration.access.uid
        || !objectEquals(currentPriorityPlanRef.current.addons, state.configuration.addons)
      ) {
        currentPriorityPlanRef.current = state.configuration;
        (async () => {
          const updatedUser = await API.updateUser({
            priority_plan: {
              access: state.configuration.access,
              activity: state.configuration.activity,
              network: state.configuration.network,
              addons: state.configuration.addons || {}
            }
          });
          meSWR.mutate(updatedUser);
        })();
      }
    }
  }, [isOnboarding, state, meSWR]);

  return (
    <MainLayout
      Header={{ className: "justify-center" }}
      TitleContainer={{ className: "pt-7" }}
      card
      header={!isOnboarding ? null : (
        <Branding logo={true} name={true} />
      )}
      title={(
        <>
          <StyledTitle>{substage.title}</StyledTitle>
          <ProgressBar progress={substage.progress} />
        </>
      )}
      content={(
        <div className="grid gap-4">
          {substage.name === SUBSTAGE_NETWORKS && (
            <NetworkSelector state={state} />
          )}
          {substage.name === SUBSTAGE_ACTIVITIES && (
            <ActivitySelector state={state} />
          )}
          {substage.name === SUBSTAGE_ACCESSES && (
            <AccessSelector state={state} />
          )}
          {substage.name === SUBSTAGE_CONFIGURE && (
            <PlanConfigurator state={state} onReset={onReset} />
          )}
          {substage.name === SUBSTAGE_CHECKOUT && (
            <Checkout state={state} onReset={onReset} />
          )}
          {isOnboarding && (
            <LogoutButton className="btn-link-primary" />
          )}
        </div>
      )}
    />
  );
}
import Checkbox from './Checkbox';
import { Link } from 'react-router-dom';

/** option.type: button, checkbox, text, link */

export default function InfoListItem({ title, children, icon, option, disabled }) {
  option = option || {};

  return (
    <div className="p-2 bg-white select-none">
      <div className="flex justify-between">
        <div>
          {icon && (
            <div className="mt-1 mr-1.5">{icon}</div>
          )}
          <div className="text-lg font-extrabold">{title}</div>
          <div>{children}</div>
        </div>

        <div className="flex flex-col ml-1">
          {option.type === 'link' && (
            <Link
              className={"text-sm p-1 btn " + (disabled ? "btn-link-disabled" : "btn-link-primary")}
              to={option.href}
            >
              {option.value}
            </Link>
          )}
          {option.type === 'button' && (
            <button
              className={"text-sm p-1 btn " + (disabled ? "btn-link-disabled" : "btn-link-primary")}
              onClick={option.onClick}
              disabled={disabled}
            >
              {option.value}
            </button>
          )}
          {option.type === 'checkbox' && (
            <div className="flex flex-col justify-center flex-1">
              <label>
                <Checkbox
                  checked={option.value}
                  onChange={option.onChange}
                  disabled={disabled}
                />
              </label>
            </div>
          )}
          {option.type === 'text' && (
            <div className="text-sm pt-1">{option.value}</div>
          )}
        </div>
      </div>
    </div>
  );
}

// export default function InfoListItem({ title, children, icon, option, disabled }) {
//   option = option || {};

//   return (
//     <div className="p-2 bg-white select-none">
//       <div className="flex justify-between">
//         <div>
//           {icon && (
//             <div className="mt-1 mr-1.5">
//               {icon}
//             </div>
//           )}
//           <div className="text-lg font-extrabold">{title}</div>
//         </div>

//         <div className="flex flex-col ml-1">
//           {option.type ==='link' && (
//             <Link
//               className={"text-sm p-1 " + (disabled ? "btn-link-disabled" : "btn btn-link-primary")}
//               to={option.href}
//             >
//               {option.value}
//             </Link>
//           )}
//           {option.type === 'button' && (
//             <button
//               className={"text-sm p-1 " + (disabled ? "btn-link-disabled" : "btn btn-link-primary")}
//               onClick={option.onClick}
//               disabled={disabled}
//             >
//               {option.value}
//             </button>
//           )}
//           {option.type === 'checkbox' && (
//             <div className="flex flex-col justify-center flex-1">
//               <label>
//                 <Checkbox
//                   checked={option.value}
//                   onChange={option.onChange}
//                   disabled={disabled}
//                 />
//               </label>
//             </div>
//           )}
//           {option.type === 'text' && (
//             <div className="flex flex-col justify-center flex-1">
//               <div className="text-sm pr-1">{option.value}</div>
//             </div>
//           )}
//         </div>
//       </div>
//       <div>{children}</div>
//     </div>
//   );
// }
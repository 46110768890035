import { useCallback } from 'react';
import useMeSWR from './useMeSWR';
import useTransaction from './useTransaction';
import * as API from '../lib/api';
import * as AuthServices from '../lib/auth';
import { useSWRConfig } from 'swr';
import { Sentry } from '../integrations/sentry';
import { mixpanelTrack, mixpanel } from '../integrations/mixpanel';

export default function useSignup() {
  const meSWR = useMeSWR();
  const trx = useTransaction();
  const swrConfig = useSWRConfig();

  const onSubmit = useCallback(async (values) => {
    trx.begin();

    let createError;
    let loginError;

    try {
      /** Must create user first */
      const user = await API.createUser(values);

      try {
        mixpanel.identify(user.uid);
      } catch (err) {
        Sentry.captureException(err);
      }
      
      mixpanelTrack('user.create');
      swrConfig.cache.clear();
      meSWR.mutate(user);
    } catch (err) {
      createError = err;
    }

    try {
      /** Then login after creating user */
      await AuthServices.login(values);
      try {
        mixpanel.track('login');
      } catch (err) {
        Sentry.captureException(err);
      }
    } catch (err) {
      loginError = err;
    }

    if (loginError) {
      if (createError) {
        trx.end(createError);
      } else {
        trx.end(loginError);
      }
    } else {
      /** If no login error then logged in on signup page */
      trx.end();
    }
  }, [meSWR, swrConfig.cache, trx]);

  return {
    loading: trx.loading,
    error: trx.error,
    complete: trx.complete,
    onSubmit,
  };
}

import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useEvent } from 'react-use';
import FullPageIFrame from '../components/FullPageIFrame';
import * as qs from '../lib/qs';
import * as SubscribeActions from '../actions/subscribe';
import useMeSWR from 'hooks/useMeSWR';

export default function Resubscribe({ showGoBackButton = true }) {
  const meSWR = useMeSWR();
  const dispatch = useDispatch();

  useEvent('message', function (ev) {
    if (ev.origin === 'https://www.chippergolf.com' && ev.data && ev.data.type === 'subscribe') {
      // console.log('EVENT', ev, ev.data);
      dispatch(SubscribeActions.preload(ev.data.payload));
    }
  }, window);
  
  const src = useMemo(() => {
    const query = qs.toQueryString({
      SQF_PASSED_EMAIL: meSWR.data?.email,
      SQF_PASSED_PHONE: meSWR.data?.phone_number,
      SQF_PASSED_NAME: meSWR.data?.full_name || `${meSWR.data?.first_name} ${meSWR.data?.last_name}`
    });
    return 'https://www.chippergolf.com/resubscribe-self-service' + query;
  }, [meSWR.data]);

  if (!meSWR.data) {
    return null;
  }

  return (
    <FullPageIFrame
      title="Resubscribe"
      src={src}
      showGoBackButton={showGoBackButton}
    />
  );
}
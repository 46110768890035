import Logo from './Logo';
import BrandName from './BrandName';

export default function Branding({ logo = false, name = false, logoOnClick, logoProps = {}, nameProps = {}, className = "", ...props }) {
  return (
    <span className={"flex items-center " + className} {...props}>
      {logo && (
        <Logo onClick={logoOnClick} {...logoProps} />
      )}
      {name && (
        <div className={logo ? "ml-5" : ""}>
          <BrandName {...nameProps} />
        </div>
      )}
    </span>
  );
}